import {Injectable, Optional} from '@angular/core'
import {MatDialog} from '@angular/material/dialog'
import {BehaviorSubject, Observable} from 'rxjs'
import {
  OptionDialogComponent
} from '../../cabinet/option-dialog/option-dialog.component'
import {indexesToList} from '../../counter-top/service/counter-top.service'
import {I18nService} from '../../i18n/service/i18n.service'
import {CabinetOption, TOptionSelectName} from '../../model/cabinet-option'
import {ProdboardCabinet} from '../../model/cabinet/prodboard-cabinet'
import {CoverSide} from '../../model/cover-side'
import {Door} from '../../model/door'
import {DrawerDoor} from '../../model/drawer-door'
import {Filler} from '../../model/filler'
import {FrameWidth} from '../../model/frame-width/frame-width'
import {Hanging} from '../../model/hanging'
import {Hinges} from '../../model/hinges'
import {
  CLASSIC_HINGES,
  DOOR_NO_DOOR_SELECTED,
  DOOR_STANDARD_DOOR,
  MODERN_HINGES,
  MODERN_HINGES_TAP
} from '../../model/model-types'
import {Scribings} from '../../model/scribings'
import {SpiceRack} from '../../model/spice-rack'
import {IProject} from '../../services/project-types'
import {IWarningDetail, Warning} from '../model/warning'
import {
  CabinetItem,
  IWarningCoverSide,
  IWarningFillerFrame,
  IWarningHingeRecess,
  IWarningItem,
  IWarningOpening,
  IWarningRack,
  IWarningTextReplacer
} from './warning-types'

@Injectable({
  providedIn: 'root'
})
export class WarningService {

  /**
   * The public warnings for anyone to list.
   */
  public warnings$: Observable<Warning[]>

  // We use this when analyzing the project to check for differences in paint process
  private paintProcess = 0

  private pWarnings$: BehaviorSubject<Warning[]> = new BehaviorSubject<Warning[]>(null)

  /**
   * Internal list of warnings
   * @private
   */
  private warnings: Warning[] = []

  /**
   * Internal list of project warnings
   * @private
   */
  private projectWarnings: Warning[] = []

  private cabinetWarnings: Warning[] = []

  /**
   * These are for summarizing when we need to
   * know the sum of options for generate a warning.
   * @private
   */
  private brassTagCount = 0

  private scribingsCount = 0

  private combinedCount = 0

  private paintedSideCount = 0

  private hasDishwasher = false

  /**
   * Her we have some sets, meaning that we can only have one of each.
   * These are used to see if they are more than one, if they are more
   * than one, a warning is generated of the type "you have different options"
   * selected of type X
   * @private
   */
  private skirtings = new Set<string>()

  private hinges = new Set<string>()

  private doors = new Set<string>()

  private lighting = new Set<string>()

  /**
   * We dump all cabinets onto this so that we can find them
   * later. We shall convert this to a Map for direct access
   * since we only find in it.
   * @private
   */
  private cabinetItems: CabinetItem[] = []

  private price: IWarningDetail[] = []

  /**
   * The below arrays can have multiple items. This is
   * because they get populated for each cabinet and we
   * will list one warning for each cabinet that has the warning.
   * @private
   */
  private hingesRecess: IWarningHingeRecess[] = []

  private scribingsCornerCabinets: IWarningItem[] = []

  private scribingsCoverSide: IWarningCoverSide[] = []

  private scribingsCoverSideWall: IWarningCoverSide[] = []

  private drawerNarrowOpening: IWarningOpening[] = []

  private shelveNarrowOpening: IWarningOpening[] = []

  private spiceRackNoDoor: IWarningRack[] = []

  private fillerFrame: IWarningFillerFrame[] = []

  private recess: IWarningFillerFrame[] = []

  private hangingVariationsMap = new Map([
    ['Vänster', [1]],
    ['Höger', [2]],
    ['Vänster-Höger', [1, 2]],
    ['Vänster-Vänster', [1]],
    ['Höger-Höger', [2]],
    ['Vänster-Vänster-Höger', [1, 2]],
    ['Vänster-Höger-Höger', [1, 2]],
    ['Upptill', []],
    ['Nertill', []],
    ['Vänster-Höger x2', [1, 2]],
    ['Vänster-Vänster-Höger x2', [1, 2]],
    ['Vänster-Höger-Höger x2', [1, 2]],
    ['Vänster-Höger-Höger x2', [1, 2]]
  ])

  constructor(
    @Optional() private dialog: MatDialog,
    @Optional() private i18nService: I18nService
  ) {
    this.warnings$ = this.pWarnings$.asObservable()
  }

  get hasWarnings(): boolean {
    return this.warnings.length > 0
  }

  /**
   * Returns the *first* option of the cabinet that matches the name!
   * @param cabinet
   * @param name
   */
  public static getOption<K extends CabinetOption>(
    cabinet: ProdboardCabinet, name: TOptionSelectName): K | undefined {
    return cabinet.options.find((option: CabinetOption) => option.optionSelectName === name) as K
  }

  public reset(): void {
    this.warnings.length = 0
    this.projectWarnings.length = 0
    this.cabinetWarnings.length = 0
    this.brassTagCount = 0
    this.scribingsCount = 0
    this.combinedCount = 0
    this.paintedSideCount = 0
    this.hasDishwasher = false
    this.cabinetItems.length = 0
    this.hingesRecess.length = 0
    this.recess.length = 0
    this.scribingsCornerCabinets.length = 0
    this.scribingsCoverSide.length = 0
    this.scribingsCoverSideWall.length = 0
    this.drawerNarrowOpening.length = 0
    this.shelveNarrowOpening.length = 0
    this.spiceRackNoDoor.length = 0
    this.fillerFrame.length = 0
    this.pWarnings$.next(null)
    this.skirtings.clear()
    this.hinges.clear()
    this.doors.clear()
    this.lighting.clear()
  }

  public analyzeProject(project: IProject): void {

    if ((project.form.paintProcess === 4 || project.form.paintProcess === 5) && this.paintedSideCount > 0) {
      //'Skåpet kan inte ha målade sidor'
      const warning = new Warning('PAINT_SIDE')
      //'Eftersom skåpen inte ska målas kan inte sidorna vara målade'
      warning.setDetail()
      warning.addDetails(this.getDetails('PaintSide'))
      this.projectWarnings.push(warning)
    }

    if (project.form.paintProcess !== this.paintProcess) {
      // 'Målningsprocessen skiljer sig mellan Mill och Prodboard.',
      const warning = new Warning('PAINT_PROCESS')
      warning.setDetail('paintProcess')
      this.projectWarnings.push(warning)
    }

    if (!project.form.paintProcess && !this.paintProcess) {
      // 'Bestäm målningsprocess',
      const warning = new Warning('PAINT_PROCESS_SELECT')
      warning.setDetail('paintProcess')
      this.projectWarnings.push(warning)
    }

    if (!project.form.color && project.form.paintProcess === 1) {
      // 'Bestäm kulör',
      const warning = new Warning('COLOR')
      warning.setDetail('color')
      this.projectWarnings.push(warning)
    }

    if (this.hasDishwasher && this.paintedSideCount < 1 && project.form.paintProcess < 3) {
      //'Skåpen på respektive sida om diskmaskinen ska ha målade sidor'
      //'Eftersom skåpsidorna som står bredvid diskmaskinen' +
      //'syns när diskmaskinsluckan är öppen, så ska dessa sidor målas.'
      const warning = new Warning('PAINT_DW')
      //'Lägg till "Paint side because of DW" i ritprogrammet' +
      //'(du måste vara inloggad som admin) på skåpen som vetter mot diskmaskin. Spara och ladda upp ny JSON-fil.'
      warning.setDetail()
      this.projectWarnings.push(warning)
    }

    if (!project.form.lc) {
      // 'Skriv in kundens land',
      const warning = new Warning('COUNTRY_SELECT')
      warning.setDetail('lc')
      this.projectWarnings.push(warning)
    }

    if (!project.form.approxDeliveryDate) {
      const warning = new Warning('APPROXIMATE_DELIVERY')
      warning.setDetail('approxDeliveryDate')
      this.projectWarnings.push(warning)
    }
  }

  public analyzeCabinet(cabinet: ProdboardCabinet): void {
    this.cabinetItems.push({
      index: cabinet.index,
      type: cabinet.cat,
      options: cabinet.options, cabinet
    })
    this.paintProcess = cabinet.paintProcess

    this.checkMaxHeight(cabinet)
    this.checkOven(cabinet)
    this.checkSettingsDiff(cabinet)
    this.checkDrawerNarrowOpening(cabinet)
    this.checkShelvesNarrowOpening(cabinet)
    this.checkSpiceRack(cabinet)
    /**
     * This one sets the "kitchen" to has dishwasher,
     * to check if the cabinet is a dishwasher cabinet, use another method.
     */
    this.hasDishwasher = cabinet.isDishwasherCabinet || this.hasDishwasher

    cabinet.options
      .filter((option: CabinetOption) => option.active)
      .forEach((option: CabinetOption) => {
        // Check all options that do not depend on cabinet
        this.checkOption(option)

        // Check options that are based on cabinet settings.
        if (option.optionSelectName === 'Hinges' && !cabinet.isDishwasherCabinet) {
          this.hinges.add(option.createOptionsSummary())
        }
        // Hinges are not checked on base corner cabinets.
        if (!cabinet.isBaseCornerCabinet) {
          if (option.optionSelectName === 'Hinges') {
            this.checkHingeRecess(cabinet)
          }
        }

        if (option.optionSelectName === 'Scribings') {
          this.checkScribings(cabinet, option as Scribings)
        }
      })

    this.checkRecess(cabinet)
    this.checkFillerFrame(cabinet)
    this.generateCabinetWarnings()
  }

  public generateWarnings(): void {
    this.warnings.length = 0
    if (this.skirtings.size > 1) {
      // 'Det finns olika sorters sockel'
      // 'Generell regel: Ett kök har i regel inskjuten sockel' +
      // 'eller utanpåliggande på hela köket, men undantag förekommer.'
      const warning = new Warning('SOCELS_DIFF')
      // 'Ska det verkligen vara både inskjuten och utanpåliggande sockel? ' +
      // 'Ändra i ritprogrammet, spara och ladda upp ny JSON.'
      warning.setDetail()
      warning.addDetails(this.getDetails('Skirting'))
      this.warnings.push(warning)
    }

    if (this.brassTagCount < 2) {
      // `Ett, eller inget, skåp har mässingsskyltar med Kulladalslogo`,
      // 'Generell regel för logoskylten: Varje kök bör ha 2 st'+
      // '(ett väldigt stort kök kan ha fler, ett väldigt litet kök kan ha färre).'
      const warning = new Warning('BRASS_PLATE')
      // 'Lägg till "Brass tags" i ritprogrammet (du måste vara inloggad som admin), spara, och ladda upp ny JSON-fil.'
      warning.setDetail()
      this.warnings.push(warning)
    }

    if (this.scribingsCount < 1) {
      // `Inget skåp är överdimensionerat`,
      // 'Generell regel för överdimensionering: När ett skåp möter en vägg lägger vi oftast till ' +
      // 'överdimensionering (för att ha marginal för felmätning eller om väggarna är sneda).'
      const warning = new Warning('OVER_SIZE')
      // 'Lägg till "Överdimensionering" i ritprogrammet (du måste vara' +
      // ' inloggad som admin), spara och ladda upp ny JSON-fil.'
      warning.setDetail()
      this.warnings.push(warning)
    }

    if (this.combinedCount < 1) {
      // `Inget skåp är kombinerat med ett annat`
      //'Generell regel för kombinerade skåp: När två mindre skåp, med samma djup, är bredvid ' +
      //'varandra så kan de kombineras. De bör inte bli för tunga (inte bredare än 150 cm om det är bänkskåp). '
      const warning = new Warning('COMBINED_UNIT')

      // 'Lägg till "Combined unit" i ritprogrammet (du måste vara inloggad som admin),'+
      // 'spara och ladda upp ny JSON-fil.'
      warning.setDetail()
      this.warnings.push(warning)
    }

    if (this.hinges.size > 1) {
      //  'Det är olika gångjärn på skåpen'
      // 'Generell regel: Ett kök har i regel samma gångjärnstyp på alla skåp, men undantag förekommer.'
      const warning = new Warning('HINGES')
      // 'Ska det verkligen vara olika sorters gångjärn? ' +
      //'Ändra i ritprogrammet, spara och ladda upp ny JSON.'
      warning.setDetail()
      warning.addDetails(this.getDetails('Hinges'))
      this.warnings.push(warning)
    }
    this.doors.delete('')
    this.doors.delete(null)

    if (this.doors.size > 1) {
      // `Det är olika dörrtyper`
      // 'Generell regel: Ett kök har i regel samma lucktyp på alla skåp, men undantag förekommer.'
      const warning = new Warning('DOOR_TYPE')
      // 'Ska det verkligen vara olika sorters luckor?' +
      // ' Ändra i ritprogrammet, spara och ladda upp ny JSON.'
      warning.setDetail()
      warning.addDetails(this.getDetails('DoorType'))
      this.warnings.push(warning)
    }

    if (this.lighting.size > 1) {
      // `Det är olika typer av belysning under skåpen - spots och strips.`
      // 'Generell regel: Köket bör ha samma typ - spots eller strips - undertill på alla skåp.'
      const warning = new Warning('LIGHTING')
      // 'Ändra i ritprogrammet, spara och ladda upp ny JSON.'
      warning.setDetail()
      warning.addDetails(this.getDetails('Lighting'))
      this.warnings.push(warning)
    }

    this.hingesRecess.forEach((data) => {
      /**
       * If there is recess on this cabinet, we don't
       * generate a warning for hinges and recess.
       */
        this.generateHingesRecessWarning(data.index, data.cat, data.width, data.recess, data.hinge, data.sideText)
    })

    this.scribingsCoverSide.forEach(data => {
      this.generateScribingsCoverSideWarning(data.index, data.cat, data.side)
    })

    this.scribingsCoverSideWall.forEach(data => {
      this.generateScribingsCoverSideWarningWall(data.index, data.cat, data.side)
    })

    this.scribingsCornerCabinets.forEach(data => {
      this.generateScribingsCornerCabinetWarning(data.index, data.cat)
    })

    this.recess.forEach((data: IWarningFillerFrame) => {
      this.generateRecessWarning(data.side, data.index, data.cat, data.filler, data.frame)
    })

    this.drawerNarrowOpening.forEach((data: IWarningOpening) => {
      this.generateDrawersNarrowOpening(data.index, data.cat, data.opening)
    })

    this.shelveNarrowOpening.forEach(data => {
      this.generateShelvesNarrowOpening(data.index, data.cat, data.opening)
    })

    this.spiceRackNoDoor.forEach(data => {
      this.generateSpiceRackNoDoor(data.index, data.cat, data.door)
    })

    this.fillerFrame.forEach(data => {
      this.generateFrameFillerWarning(data.side, data.index, data.cat, data.filler, data.frame)
    })

    this.generateProjectWarnings()

    this.generateCabinetWarnings()

    if (this.price.length > 0) {
      //  'Fel pris'
      const warning = new Warning('PRICE')
      this.price.forEach((price: IWarningDetail) => {
        const detail = warning.setOptionsDetail(+price.index, price.option)
        detail.text = detail.text
          .replace('TEXT', price.text)
          .replace('INDEX', price.index + '')
      })
      this.warnings.push(warning)
    }
    this.pWarnings$.next(this.warnings)
  }

  /**
   *
   */
  public editCabinet(detail: IWarningDetail): void {
    // Not all details are clickable.
    if (!detail.index || !detail.option) {
      return
    }
    const cabinetItem = this.cabinetItems.find((ci: CabinetItem) => ci.index === detail.index)
    const cabinet = cabinetItem.cabinet
    const option = cabinet.options.find((co: CabinetOption) => co.name === detail.option)
    this.dialog.open(OptionDialogComponent, {
      data: {option},
      disableClose: true
    })
  }

  /**
   * Only active options should be passed here!
   * @param option
   * @private
   */
  private checkOption(option: CabinetOption): void {
    // Heads up! Potential bugg if the option has negative, or undefined price
    if (option.shouldHavePrice === true && (option.price + option.labor + option.material) === 0) {
      this.price.push({
        text: this.i18nService.translate(option.title),
        option: option.name,
        index: option.cabinetIndex
      })
    }

    if (option.optionSelectName === 'Skirting') {
      this.skirtings.add(option.getCustomerListing('c')[0])
    }
    if (option.optionSelectName === 'BrassPlate') {
      const count = option.viewOptions[0].selection === 'Båda' ? 2 : 1
      this.brassTagCount += count
    }

    if (option.optionSelectName === 'Scribings') {
      this.scribingsCount++
    }

    if (option.optionSelectName === 'CombinedUnit') {
      this.combinedCount++
    }
    if (option.optionSelectName === 'PaintSide') {
      this.paintedSideCount++
    }

    if (option.optionSelectName === 'DoorType') {
      this.doors.add(option.getCustomerListing('c')[0])
    }

    this.checkLighting(option)
  }

  private checkFillerFrame(cabinet: ProdboardCabinet): void {
    if (!cabinet.isDishwasherCabinet && cabinet.cabinetType && cabinet.cat.indexOf('ONd') === -1) {
      const filler = WarningService.getOption<Filler>(cabinet, 'Filler')
      const frame = WarningService.getOption<FrameWidth>(cabinet, 'FrameWidth')
      if (frame && filler) {
        const sides = ['left', 'right', 'top']
        if (frame.top < 20) {
          sides.pop()
        }
        sides.forEach((side: string) => {
          if (frame[side] - filler[side] < 20) {
            this.fillerFrame.push({
              side,
              index: cabinet.index,
              cat: cabinet.cat,
              filler,
              frame
            })
          }
        })
      }
    }
  }

  private checkDrawerNarrowOpening(cabinet: ProdboardCabinet): void {
    if (cabinet.numberOfDrawers > 0) {
      const frameWidth = WarningService.getOption<FrameWidth>(cabinet, 'FrameWidth')
      if (frameWidth) {
        const openingWidth = cabinet.visibleWidth -
          frameWidth.right - frameWidth.left
        if (openingWidth < 170) {
          this.drawerNarrowOpening.push({
            index: cabinet.index,
            cat: cabinet.cat,
            opening: openingWidth
          })
        }
      }
    }
  }

  private checkShelvesNarrowOpening(cabinet: ProdboardCabinet): void {
    if (cabinet.cat === 'BCP') {
      const frameWidth = WarningService.getOption<FrameWidth>(cabinet, 'FrameWidth')
      if (frameWidth) {
        const openingWidth = cabinet.visibleWidth - frameWidth.right - frameWidth.left
        if (openingWidth < 361) {
          this.shelveNarrowOpening.push({
            index: cabinet.index,
            cat: cabinet.cat,
            opening: openingWidth
          })
        }
      }
    }
  }

  private checkScribings(cabinet: ProdboardCabinet, scribings: Scribings): void {
    /**
     * Corner cabinet can't have scribings
     */
    if (cabinet.isBaseCornerCabinet) {
      this.scribingsCornerCabinets.push({
        index: cabinet.index,
        cat: cabinet.cat
      })
    }

    const coverSide = WarningService.getOption<CoverSide>(cabinet, 'CoverSide')
    if (!coverSide?.active || cabinet.isDishwasherCabinet) {
      return
    }
    /**
     * Checks if both cover side and scribing, Dish washers excluded.
     */
    if (cabinet.cabinetType.indexOf('wall') === -1) {
      if (scribings.checkIllegal(coverSide)) {
        this.scribingsCoverSide.push({
          index: cabinet.index,
          cat: cabinet.cat,
          side: coverSide.valueMap().side as string
        })
      }
    } else if (!coverSide?.hasConsole) {
      if (scribings.checkIllegal(coverSide)) {
        this.scribingsCoverSideWall.push({
          index: cabinet.index,
          cat: cabinet.cat,
          side: coverSide.valueMap().side as string
        })
      }
    }
  }

  private checkHingeRecess(cabinet: ProdboardCabinet): void {
    const hinges = WarningService.getOption<Hinges>(cabinet, 'Hinges')
    const fWidth = WarningService.getOption<FrameWidth>(cabinet, 'FrameWidth')
    const filler = WarningService.getOption<Filler>(cabinet, 'Filler')
    const hanging = WarningService.getOption<Hanging>(cabinet, 'Hanging')
    const door = WarningService.getOption<Door>(cabinet, 'Door')

    let sides = []
    if (door?.typeOfDoor() !== DOOR_NO_DOOR_SELECTED) {
      sides = [1, 2]
      if (hanging) {
        sides = this.hangingVariationsMap.get(hanging.viewOptions[0].selection) || [1, 2]
      }
    }

    if (filler && fWidth && hinges?.active) {
      sides.forEach((side: number) => {
        const recess = filler.viewOptions[side].selection + ''
        const width = fWidth.viewOptions[side].selection + ''
        const hinge: string = hinges.createOptionsSummary()
        const sideText = side === 1 ? 'vänster' : 'höger'

        if ((hinge === MODERN_HINGES || hinge === MODERN_HINGES_TAP) && (+recess < +width - 20)) {
          this.hingesRecess.push({
            index: cabinet.index,
            cat: cabinet.cat,
            width,
            recess,
            hinge,
            sideText
          })
        }
      })
    }
  }

  private checkLighting(option: CabinetOption): void {
    if (option.optionSelectName === 'Lighting' && option.active) {
      let selection = option.getCustomerListing('c')[0]
      selection = selection || ''
      selection = selection.toLowerCase()
      // Heads up! If this changes in Lightning.ts then we are in trouble
      if (selection !== 'led-strips inuti skåpet') {
        this.lighting.add(selection.indexOf('spotlight') !== -1 ? 'spot' : 'led')
      }
    }
  }

  private checkRecess(cabinet: ProdboardCabinet): void {
    if (!cabinet.isBaseCornerCabinet && cabinet.drawersOnly) {
      const filler = WarningService.getOption<Filler>(cabinet, 'Filler')
      const frame = WarningService.getOption<FrameWidth>(cabinet, 'FrameWidth')
      if (frame && filler) {
        const leftRight = ['left', 'right']
        leftRight.forEach((side: string) => {
          if (frame[side] - filler[side] !== 20) {
            this.recess.push({
              side,
              index: cabinet.index,
              cat: cabinet.cat,
              filler,
              frame
            })
          }
        })
      }
    }
  }

  private generateFrameFillerWarning(side: string, cabinetIndex: number, cabinetCat: string, filler: Filler, frame: FrameWidth): void {

    //`Skåp ${cabinetIndex} -  ${cabinetCat} - har fel indrag på ${sideText[side]} sidan. ` +
    //`Bör vara max ${frame[side] - 20} istället för ${filler[side]}.`
    // 'Generell regel för indrag: Indraget får vara max 20 mm mindre än ramens bredd.'
    const warning = new Warning('FRAME_FILLER')
    const replacers: IWarningTextReplacer[] = [
      {key: 'CABINET_INDEX', value: `${cabinetIndex}`},
      {key: 'CABINET_CAT', value: cabinetCat},
      {key: 'SIDE', value: side},
      {key: 'FRAME_SIDE', value: `${frame[side] - 20}`},
      {key: 'FILLER_SIDE', value: `${filler[side]}`}
    ]
    warning.replace(replacers)
    warning.setOptionsDetail(cabinetIndex, filler.name)
    this.warnings.push(warning)
  }

  private generateDrawersNarrowOpening(cabinetIndex: number, cabinetCat: string, opening: number): void {
    // `Skåp ${cabinetIndex} -  ${cabinetCat} - är för smalt för lådor med ordinarie lådskenor.`
    const warning = new Warning('DRAWER_NARROW')
    this.replaceCabinet(warning, cabinetIndex, cabinetCat)
    const detail = warning.setDetail()
    detail.text = detail.text.replace('WIDTH', (210 - opening) + '')
    this.warnings.push(warning)
  }

  private generateShelvesNarrowOpening(cabinetIndex: number, cabinetCat: string, opening: number): void {
    // `Skåp ${cabinetIndex} -  ${cabinetCat} - är för smalt för hyllorna.`
    const warning = new Warning('SHELVES_NARROW')
    this.replaceCabinet(warning, cabinetIndex, cabinetCat)
    const detail = warning.setDetail()
    detail.text = detail.text.replace('WIDTH', (361 - opening) + '')
    this.warnings.push(warning)
  }

  private generateSpiceRackNoDoor(cabinetIndex: number, cabinetCat: string, doorType: string): void {
    const warning = new Warning('SPICE_RACK_NO_DOOR')
    this.replaceCabinet(warning, cabinetIndex, cabinetCat)
    const detail = warning.setDetail()
    detail.text = detail.text.replace('DOOR', doorType)
    this.warnings.push(warning)
  }

  private generateScribingsCornerCabinetWarning(cabinetIndex: number, cabinetCat: string): void {
    // `Skåp ${cabinetIndex} - ${cabinetCat} - är överdimensionerad. ` +
    // Hörnbänkskåp ska aldrig överdimensioneras - det har redan inbyggd marginal.`
    // 'Generell regel är att hörnskåp inte ska överdimensioneras. De har inbyggd marginal'
    const warning = new Warning('SCRIBINGS_CORNER_CABINET')
    this.replaceCabinet(warning, cabinetIndex, cabinetCat)
    //`Ta bort överdimensioneringen i ritprogrammet. Spara och ladda sedan upp ny JSON-fil.`,
    warning.setDetail()
    this.warnings.push(warning)
  }

  private generateScribingsCoverSideWarning(cabinetIndex: number, cabinetCat: string, side: string): void {

    //`Skåp ${cabinetIndex} -  ${cabinetCat} - har en täcksida och är överdimensionerad på ${sideText} sida. ` +
    //`Skåpet kan inte både överdimensioneras och ha täcksida på samma sida. Ta bort ett av alternativen.`)
    //'Generell regel är att skåp inte kan överdimensioneras på en sida med täcksida'
    const warning = new Warning('SCRIBINGS_COVER_SIDE')
    const replacers: IWarningTextReplacer[] = [
      {key: 'CABINET_INDEX', value: `${cabinetIndex}`},
      {key: 'CABINET_CAT', value: cabinetCat},
      {key: 'SIDE', value: side}
    ]
    warning.replace(replacers)
    // `Ta bort överdimensioneringen i ritprogrammet. Spara och ladda sedan upp ny JSON-fil.`,
    warning.setDetail()
    this.warnings.push(warning)
  }

  private generateScribingsCoverSideWarningWall(cabinetIndex: number, cabinetCat: string, side: string): void {
    const warning = new Warning('SCRIBINGS_COVER_SIDE_WALL')
    const replacers: IWarningTextReplacer[] = [
      {key: 'CABINET_INDEX', value: `${cabinetIndex}`},
      {key: 'CABINET_CAT', value: cabinetCat},
      {key: 'SIDE', value: side}
    ]
    warning.replace(replacers)
    // `Ta bort överdimensioneringen i ritprogrammet. Spara och ladda sedan upp ny JSON-fil.`,
    warning.setDetail()
    this.warnings.push(warning)
  }

  private generateRecessWarning(side: string, cabinetIndex: number, cabinetCat: string, filler: Filler, frame: FrameWidth): void {
    // `Skåp ${cabinetIndex} -  ${cabinetCat} - har fel indrag på ${sideText} sida. ` +
    // `Bör vara minst ${frame[side] - 20} istället för ${filler[side]}.`
    // 'Generell regel för indrag: Ramens bredd minus 20 mm.'
    const warning = new Warning('RECESS')
    const replacers: IWarningTextReplacer[] = [
      {key: 'CABINET_INDEX', value: `${cabinetIndex}`},
      {key: 'CABINET_CAT', value: cabinetCat},
      {key: 'SIDE', value: side},
      {key: 'FRAME', value: `${frame[side] - 20}`},
      {key: 'FILLER', value: `${filler[side]}`}
    ]
    warning.replace(replacers)
    // `Ändra indraget i ritprogrammet. Spara och ladda sedan upp ny JSON-fil.`,
    warning.setOptionsDetail(cabinetIndex, filler.name)
    this.warnings.push(warning)
  }

  private generateHingesRecessWarning(
    index: number,
    cat: string,
    width: string,
    recess: string,
    hinge: string,
    side: string
  ): void {
    //`Skåp ${cabinetIndex} -  ${cabinetCat} - har för litet indrag på ${side} sida för dessa gångjärn (${hinge}).
    // Ramens bredd är ${width} mm och indraget är ${recess} mm men borde vara ${+width - 20}`
    const warning = new Warning('HINGE_RECESS')
    const replacers: IWarningTextReplacer[] = [
      {key: 'CABINET_INDEX', value: `${index}`},
      {key: 'CABINET_CAT', value: cat},
      {key: 'SIDE', value: side},
      {key: 'HINGE', value: hinge},
      {key: 'WIDTH', value: width},
      {key: 'RECESS', value: recess},
      {key: 'SHOULD_BE', value: `${+width - 20}`}
    ]
    warning.replace(replacers)
    // 'Generell regel för denna typ av gångjärn: Indraget får inte vara mindre än rambredden - 20 mm.'
    warning.setDetail()
    this.warnings.push(warning)
  }

  private generateProjectWarnings(): void {
    this.warnings = this.warnings.concat(this.projectWarnings)
    this.pWarnings$.next(this.warnings)
  }

  private generateCabinetWarnings(): void {
    this.warnings = this.warnings.concat(this.cabinetWarnings)
    this.pWarnings$.next(this.warnings)
  }

  private getDetails(optionSelectName: TOptionSelectName): IWarningDetail[] {
    const items = this.cabinetItems
      .map((ci: CabinetItem) => {
        let optionName: string
        const selections = ci.options
          .filter((o: CabinetOption) => o.optionSelectName === optionSelectName && o.active)
          .map((o: CabinetOption) => {
            optionName = o.name
            return o.viewOptions[0].selection
          })
        if (selections[0]) {
          return {
            text: `Cabinet ${ci.index} have ${selections[0]}`,
            index: ci.index,
            option: optionName,
            hideShow: {hide: '', show: ''}
          }
        }
        return null
      })
    return items.filter((v: IWarningDetail) => v)
  }

  /**
   * We do this several times so just a simple helper to
   * save some typing
   */
  private replaceCabinet(warning: Warning, cabinetIndex: number, cabinetCat: string): void {
    const replacers: IWarningTextReplacer[] = [
      {key: 'CABINET_INDEX', value: `${cabinetIndex}`},
      {key: 'CABINET_CAT', value: cabinetCat}
    ]
    warning.replace(replacers)
  }

  private checkMaxHeight(cabinet: ProdboardCabinet): void {
    const max = cabinet.maxHeight - cabinet.highestPoint
    const types = ['wall', 'wall-exc', 'tall', 'tall-exc']
    if (types.indexOf(cabinet.cabinetType) !== -1
      && max < 9 || (max > 14 && max < 60)
    ) {
      const warning: Warning = new Warning('CABINET_TO_HIGH')
      this.replaceCabinet(warning, cabinet.index, cabinet.cat)
      const detail: IWarningDetail = warning.setDetail()
      detail.text = detail.text.replace('MAX_HEIGHT', cabinet.maxHeight - cabinet.highestPoint + ' mm')
      this.cabinetWarnings.push(warning)
    }
  }

  private checkOven(cabinet: ProdboardCabinet): void {
    if (cabinet.isOven) {
      const hinges = WarningService.getOption<Hinges>(cabinet, 'Hinges')
      const drawer = WarningService.getOption<DrawerDoor>(cabinet, 'DrawerDoor')
      //const hinges = cabinet.options.find((option: CabinetOption) => option.optionSelectName === 'Hinges')
      if (hinges) {
        if (hinges.createOptionsSummary() !== CLASSIC_HINGES && drawer && drawer.viewOptions[0].selection !== 'Låda') {
          // `Ugnsskåpet kan inte ha lucka, måste ha låda`
          // 'De "moderna" gångjärnstyperna får inte plats i det trånga utrymmet under ugnen - ' +
          //'om denna gångjärnstyp är vald måste skåpet få "låda" istället för "lucka" under ugnen.'
          const warning = new Warning('OVEN_DOOR')
          // `Pga vald gångjärnstyp måste skåpet ha låda istället för lucka (utrymmet under ugnen)`
          warning.setOptionsDetail(cabinet.index, hinges.name)
          this.cabinetWarnings.push(warning)
        }
      }
    }
  }

  private checkSettingsDiff(cabinet: ProdboardCabinet): void {
    const settingsDiff: string[] = cabinet.checkSettingsDiff()
    if (settingsDiff.length > 0) {
      const diffs = indexesToList(settingsDiff, 'sv')
      //`Inställningar skiljer sig mellan Mill och Prodboard`
      const warning = new Warning('SETTINGS_DIFF')
      //  `Skåp ${cabinet.index} -  ${cabinet.cat} - har följande inställningar som skiljer sig ` +
      //  `mellan Mill och Prodboard: ${diffs}. Kontrollera att det är korrekt`
      const detail = warning.setDetail()
      detail.text = detail.text
        .replace('CABINET_INDEX', cabinet.index + '')
        .replace('CABINET_CAT', cabinet.cat)
        .replace('DIFFS', diffs)
      this.cabinetWarnings.push(warning)
    }
  }

  private checkSpiceRack(cabinet: ProdboardCabinet): void {
    const spiceRack = WarningService.getOption<SpiceRack>(cabinet, 'SpiceRack')
    const door = WarningService.getOption<Door>(cabinet, 'Door')
    if (spiceRack && door) {
      if (spiceRack.hasSpiceJarRack() && door.typeOfDoor() !== DOOR_STANDARD_DOOR) {
        this.spiceRackNoDoor.push({
          index: cabinet.index,
          cat: cabinet.cat,
          door: door.typeOfDoor()
        })
      }
    }
  }
}
