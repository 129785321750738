export const environment = {
  production: false,
  // Cognito config,
  clientId: '78ao2k70ca9eapc89285951na8',
  userPoolId: 'eu-west-1_LrUY36OWg',
  // productUrl: 'https://product-api-andreas.kulladal.me/api',
  productUrl: '/api',
  prodboardApiUrl: 'https://api-prodboard-test.kulladal.me/prodboard',
  showLights: true
}
