import {Injectable} from '@angular/core'
import {BehaviorSubject, of, Subject, timer} from 'rxjs'
import {
  debounceTime,
  filter,
  first,
  map,
  switchMap,
  takeUntil,
  tap
} from 'rxjs/operators'
import {AUTO_SAVE_TIMEOUT} from '../common/interface/helpers'
import {
  IOpenProjectChange,
  OpenProjectService
} from './open-project.service'

@Injectable({
  providedIn: 'root'
})
export class AutoSaveService {

  public saving$ = new BehaviorSubject<boolean>(false)

  public timer$ = new BehaviorSubject<number>(0)

  public changed$ = new BehaviorSubject<boolean>(false)

  public pTimer = -1

  private currentChanges: IOpenProjectChange = {}
  private cancelTimer: Subject<any> = new Subject()

  constructor(
    private openProjectService: OpenProjectService
  ) {
    this.openProjectService.openProjectChanges$
      .pipe(
        map((changes: IOpenProjectChange) => {
          // Cancel potential ongoing saves. Reset timer. And send new timer.
          this.cancelTimer.next(null)
          this.pTimer = 0
          this.timer$.next(this.pTimer)

          // Save current changes, merging them to previous ones,
          // and get if there was a change for real
          this.currentChanges = {...this.currentChanges, ...changes}
          const wasThereAChange = this.wasThereAChange(changes)

          // Send new event in subject for components to listen
          this.changed$.next(wasThereAChange)

          // And if there was a change, start countdown
          if (wasThereAChange) {
            this.countDown()
          }

          return [wasThereAChange, changes]
        }),
        filter((result: [boolean, IOpenProjectChange]) => result[0]),
        debounceTime(AUTO_SAVE_TIMEOUT)
      )
      .subscribe({
        next: () => {
          // Once saving timer is off, send cancellation event and save changes
          this.cancelTimer.next(null)
          this.saveChanges()
        }
      })
  }

  /**
   * Function that is called either manually by user clicking "Save" button in
   * header, or automatically once auto-save-countdown is finished.
   * Either way, it will get last saved "currentChanges" variable and send it
   * to service to save all changes.
   *
   * We don't care about the output of this saving event. We just do it.
   */
  public saveChanges(): void {
    of(this.currentChanges)
      .pipe(
        first(),
        // Reset current changes
        tap(() => {
          this.currentChanges = {}
        }),
        // Only perform changes if there was actually a change
        filter(this.wasThereAChange),
        // Set saving flag to true. Activate saving loader
        tap(() => {
          this.saving$.next(true)
        }),
        // Save all changes
        switchMap((changes) =>
          this.openProjectService.saveOpenProjectBasedOnChanges(changes))
      )
      .subscribe(() => {
        // Reset saving flag to false. Deactivate saving loader.
        this.saving$.next(false)
      })
  }

  /**
   * This is just for setting the clock.
   */
  private countDown(): void {
    timer(0, 1000).pipe(
      takeUntil(this.cancelTimer),
      tap(() => {
        this.pTimer++
        this.timer$.next(this.pTimer)
      })
    ).subscribe()
  }

  private wasThereAChange(changes: IOpenProjectChange): boolean {
    return Object.keys(changes).some(key => changes[key] === true)
  }
}
