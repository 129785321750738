import {TLanguageCode} from '../application/i18n.provider'
import {TSettingOptionUseCase} from '../services/settings-item.service'
import {
  CabinetOption,
  TOptionSelectName,
  ViewOptionType
} from './cabinet-option'
import {IProduct} from '../common/interface/product-types'
import {ProdboardCabinetOption} from '../services/prodboard-types'

export class Cornice extends CabinetOption {
  public selectionOptions = {
    true: 'Ja',
    false: 'Nej',
  }

  constructor(option: ProdboardCabinetOption, product: IProduct, cabinetId: number) {
    super(option, product, cabinetId)
    this.priority = 10
    this.viewOptions = [
      {
        type: ViewOptionType.binary,
        title: 'Krönlist',
        name: 'cornice',
        values: ['Ja', 'Nej'],
        selection: '',
        disabled: false
      }
    ]
    this.description = 'Cornice could be on or off'
    this.title = 'optCornice'

    const sOptions = Cornice.sanitize(option)
    this.setValuesFromProdboardData(sOptions)
  }

  get optionSelectName(): TOptionSelectName {
    return 'Cornice'
  }

  public getCustomCustomerListing(useCase: TSettingOptionUseCase, lc: TLanguageCode): string[] {
    // 'Ja' is the only value for Cornice
    return [this.settingOption.getI18n('Ja', useCase, lc)]
  }

  private static sanitize(option: ProdboardCabinetOption): ProdboardCabinetOption {
    option.value = option.value || {} as any
    option.value.options = option.value.options || {} as any
    option.value.options.cornice = option.value.options.cornice || {mode: false}
    return option
  }

  public update(data: any) {
    super.update(data)
    this.resetPrices()
    this.setFromProperties(data)
    this.setYesNoActive(data)
    this.active = this.viewOptions[0].selection === 'Ja'
  }

  private setValuesFromProdboardData(option: ProdboardCabinetOption): void {
    this.viewOptions[0].selection = this.selectionOptions[option.value.options.cornice.mode + '']
    this.active = option.value.options.cornice.mode
  }
}
