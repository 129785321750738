import {TLanguageCode} from '../application/i18n.provider'
import {TSettingOptionUseCase} from '../services/settings-item.service'
import {
  CabinetOption,
  TOptionSelectName,
  ViewOption,
  ViewOptionType
} from './cabinet-option'
import {IProduct} from '../common/interface/product-types'
import {ProdboardCabinetOption} from '../services/prodboard-types'
import {FactoryData} from './factory/factory-data'

export class CombinedUnit extends CabinetOption {
  /**
   * The option keys that can come from Prodboard
   */
  private opts = ['left', 'right', 'top', 'bot']

  constructor(option: ProdboardCabinetOption, product: IProduct, cabinetId: number) {
    super(option, product, cabinetId)

    this.priority = 30
    this.viewOptions = [
      {
        type: ViewOptionType.select,
        title: 'Kombinerat med skåp till vänster',
        name: 'left',
        values: ['Ja', 'Nej'],
        selection: 'Nej',
        disabled: false
      },
      {
        type: ViewOptionType.select,
        title: 'Kombinerat med skåp till höger',
        name: 'right',
        values: ['Ja', 'Nej'],
        selection: 'Nej',
        disabled: false
      },
      {
        type: ViewOptionType.select,
        title: 'Kombinerat med skåp över',
        name: 'top',
        values: ['Ja', 'Nej'],
        selection: 'Nej',
        disabled: false
      },
      {
        type: ViewOptionType.select,
        title: 'Kombinerat med skåp under ',
        name: 'bot',
        values: ['Ja', 'Nej'],
        selection: 'Nej',
        disabled: false
      }
    ]
    this.active = false
    this.description = 'Some cabinets are combined into a larger unit'
    this.title = 'optCombinedUnit'
    this.sanitize(option)
    this.setValuesFromProdboardData(option)
    this.setActive()
  }

  get optionSelectName(): TOptionSelectName {
    return 'CombinedUnit'
  }

  public update(data: any) {
    super.update(data)
    this.setFromProperties(data)
    this.setActive()
  }

  public getFactoryData(): FactoryData {
    return {
      combined: {
        // Be careful here! Order matters
        left: this.viewOptions[0].selection === 'Ja',
        right: this.viewOptions[1].selection === 'Ja',
        top: this.viewOptions[2].selection === 'Ja',
        bottom: this.viewOptions[3].selection === 'Ja'
      }
    }
  }

  public getCustomCustomerListing(
    useCase: TSettingOptionUseCase,
    lc: TLanguageCode
  ): string[] {
    return this.viewOptions
      .filter((opt: ViewOption) => opt.selection === 'Ja')
      .map((opt: ViewOption) =>
        this.settingOption.getI18n(opt.name, useCase, lc))
  }

  private sanitize(option: ProdboardCabinetOption): void {
    option.value = option.value || {} as any
    option.value.options = option.value.options || {} as any
    option.value.options.combi_new = option.value.options.combi_new || {} as any
    this.opts.forEach((key: string) => {
      option.value.options.combi_new[key] = option.value.options.combi_new[key] || false
    })
  }

  private setValuesFromProdboardData(option: ProdboardCabinetOption): void {
    this.viewOptions
      .forEach((opt: ViewOption) => opt.selection = option.value.options.combi_new[opt.name] ? 'Ja' : 'Nej')
  }

  private setActive(): void {
    this.active = this.viewOptions.some((opt: ViewOption) => opt.selection === 'Ja')
  }
}
