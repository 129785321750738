import {DefaultMap} from '../../application/helpers'
import {
  Comment,
  CommentHomeEntity,
  Comments
} from '../../comments/model/comment'
import {
  IUnitPrice,
  TWhiteGoodsTypeKey
} from '../../common/interface/product-types'
import {
  LayoutCalculator
} from '../../edit-project/layout/calculator/layout-calculator'
import {
  ICoordinate,
  IProdboardPosition,
  ProdboardCabinetOption,
  ProdboardItem,
  ProdboardSelectionItem
} from '../../services/prodboard-types'
import {IProdboardComment} from '../../services/prodboard.service'
import {ProductCategory} from '../../services/product-static.service'
import {IProject, TPaintProcessValue} from '../../services/project-types'
import {SettingsItemService} from '../../services/settings-item.service'
import {BackPanel} from '../back-panel'
import {BrassPlate} from '../brass-plate'
import {CabinetOption, TOptionSelectName} from '../cabinet-option'
import {CabinetSettings} from '../cabinet-settings/cabinet-setting'
import {CarpenterJoy} from '../carpenter-joy'
import {CenterPost} from '../center-post'
import {CombinedUnit} from '../combined-unit'
import {Cornice} from '../cornice'
import {CoverSide} from '../cover-side'
import {CuttingBoard} from '../cutting-board'
import {Door} from '../door'
import {DoorAttachment} from '../door-attachment'
import {DoorType} from '../door-type'
import {DrawerDoor} from '../drawer-door'
import {DrawerFront} from '../drawer-front'
import {DrawerInsert} from '../drawer-insert'
import {FanAdoption} from '../fan-adoption'
import {FanExtractorPanel} from '../fan-extractor-panel'
import {Filler} from '../filler'
import {FrameWidth} from '../frame-width/frame-width'
import {FrameWidthHelper} from '../frame-width/frame-width-helper'
import {HandleDoor} from '../handle-door'
import {HandleDrawer} from '../handle-drawer'
import {Hanging} from '../hanging'
import {HiddenDrawer} from '../hidden-drawer'
import {HiddenDrawerSink} from '../hidden-drawer-sink'
import {HiddenVisibleWidth} from '../hidden-visible-width'
import {Hinges} from '../hinges'
import {Legs} from '../legs'
import {Lighting} from '../lighting'
import {
  DOOR_NO_DOOR_SELECTED,
  DOOR_ON_DOOR,
  DRAWER_DOOR_DOOR
} from '../model-types'
import {PaintProcess} from '../paint-process'
import {PaintSide} from '../paint-side'
import {Plinth} from '../plinth'
import {Scribings} from '../scribings'
import {Shelves} from '../shelves'
import {ShelvesAdjustable} from '../shelves-adjustable'
import {Skirting} from '../skirting'
import {SpiceRack} from '../spice-rack'
import {UnknownOption} from '../unknown-option'
import {CabinetMaxHeight} from './cabinet-max-height'

interface SpecialOption extends IUnitPrice {
  id: 'higherThanStandard' | 'deeperThanStandard'
}

export class ProdboardCabinet implements Comments {

  /**
   * The UID received from Prodboard
   */
  public uid = ''

  public index: number

  public code: string

  public readonly cat: string

  public description: string

  public dimensions: ICoordinate = {x: 0, y: 0, z: 0}

  /**
   * Our "room" is just the dimensions
   */
  public room: ICoordinate = {x: 0, y: 0, z: 0}

  /**
   * This is accentual the room height including beams if any.
   * We use this to generate warnings if a cabinet is more than 10mm from the celling or a beam
   */
  public maxHeight = 2 * 1000 * 1000

  /**
   * Base price of cabinet, can be set in settings
   * defaults to product prices
   */
  public basePrice: number

  public baseLabor: number

  public baseMaterial: number

  /**
   * Description of what? In Swedish?
   */
  public deSwLaSw = ''

  /**
   * Eventually I will make this private :)
   */
  public options: CabinetOption[] = []

  public specialOptions: SpecialOption[] = []

  public comments: Comment[] = []

  public prodboardComment: IProdboardComment | undefined

  public hasSocel = false

  public isDishwasherCabinet = false

  public isBaseCornerCabinet = false

  public productComments = {
    sv: '',
    en: ''
  }


  public position: IProdboardPosition = {
    direction: {
      x: 0,
      y: 0,
      z: 0
    },
    center: {
      x: 0,
      y: 0,
      z: 0
    }
  }

  public drawers: number[] = []

  /**
   * These two variables a used when we override the prodboard data
   * in forms
   */
  public hWidth: number
  public vWidth: number

  /**
   * Let us expose our paint process so that the project
   * can select it. We convert the 'text' value to a number
   * that is suitable for the project
   */
  public paintProcess: TPaintProcessValue = 1

  /**
   * Visible and Hidden width are applicable only for "Corner" cabinets.
   * Hidden is used as marker, if > 0 we have both. Visible is normally
   * set to the width (x) of the cabinet.
   */
  public hiddenVisibleWidth: HiddenVisibleWidth | undefined

  public commentHome: CommentHomeEntity = {type: 'CABINET', id: this.uid}

  /**
   * If this cabinet is adapted for white goods.
   */
  public whiteGoodsAdaptation: TWhiteGoodsTypeKey | undefined
  /**
   * This can be read but not set by outsiders.
   */
  public readonly layout: LayoutCalculator

  private pActualHeight = 0

  private readonly defaultProduct: any = {
    cat: 'EMPTY',
    pc: 'EMPTY',
    pr: {
      price: 0,
      labor: 0
    },
    exPrHiSt: {
      price: 0,
      labor: 0
    },
    exPrDeSt: {
      labor: 0,
      price: 0
    },
    shIdPr: {
      labor: 0,
      price: 0
    },
    frontFrame: {
      faLeFr: 0,
      faRiFr: 0
    },
    shDe: 0,
    scSe: 'default',
    dhs: [],
    nuDo: 0,
    nuDr: 0
  }

  private readonly ignoredTypes: Set<TOptionSelectName> = new Set(['UnknownOption', 'HandleDrawer'])

  private project: IProject

  private input: ProdboardItem = {
    code: '',
    dimensions: this.dimensions,
    index: 0,
    items: [],
    options: [],
    position: this.position,
    room: {
      dimensions: {x: 0, y: 5000, z: 0},
      beams: []
    },
    type: '',
    uid: ''
  }

  /**
   * The SettingItemService that sets option names.
   * We will only use one method "updateCabinetOption" for it.
   */
  private settingsItemService: SettingsItemService

  private readonly product: ProductCategory

  private settings: CabinetSettings = new CabinetSettings()

  /**
   * Three sensible defaults for handling the skirtings dependency
   * to fillers and frame
   */
  private skirting: Skirting = new Skirting({} as any, {} as any, 0)

  private frame: FrameWidth = new FrameWidth({} as any, {} as any, {
    index: 1,
    dimensions: {x: 450}
  } as any, new FrameWidthHelper())

  private fillers: Filler = new Filler({} as any, {} as any, 0)

  private currentSkirting = ''

  private baseSettings: CabinetSettings = new CabinetSettings()

  /**
   * We maintain a map of all options for quick access, do not access
   * this directly, use the accessor methods, getActiveOption etc.
   */
  private optionsMap = new DefaultMap<TOptionSelectName, CabinetOption[], CabinetOption[]>(
    [], []
  )

  /**
   * A very complex recalculation of a Prodboard cabinet combined with
   * the project data
   *
   * @param settingsItemService - Used to add new labels to options via "updateCabinetOption()".
   * @param product - The product from the product database.
   * @param input - The Prodboard Cabinet item, containing the options and items.
   * @param project - The project data for the current project
   */
  constructor(
    settingsItemService: SettingsItemService = {} as any,
    product: ProductCategory = {} as any,
    input: ProdboardItem = {} as any,
    project: IProject = {form: {}} as any
  ) {
    this.settingsItemService = settingsItemService
    this.project = project

    // At this stage we have defaults for all inputs.
    this.input = {...this.input, ...input}
    this.room = this.input.room.dimensions

    const cabinetMaxHeight = new CabinetMaxHeight()
    this.maxHeight = cabinetMaxHeight.calculateCabinetMaxHeight(this.input)

    // We do not want to fail if no prices. Or if the frames are not in place.
    this.product = {...this.defaultProduct, ...product}

    this.cat = product.cat
    this.code = product.pc
    this.productComments.sv = product.coSe
    this.productComments.en = product.co

    this.position = this.input.position

    this.basePrice = ProdboardCabinet.fixPrice(this.product.pr.price)
    this.baseLabor = ProdboardCabinet.fixPrice(this.product.pr.labor)
    this.baseMaterial = ProdboardCabinet.fixPrice(this.product.pr.material)

    /**
     * Guess if we have socel so that we can display that?
     * If no default socel, then no socel. And if 0 as default?
     */
    this.hasSocel = !!this.product.shDe

    this.description = product.tiLaSw
    this.deSwLaSw = product.deSwLaSw
    this.isDishwasherCabinet = this.product.idc

    this.isBaseCornerCabinet = this.product.ibcc
    this.whiteGoodsAdaptation = this.product.iwg
    this.product.dhs.forEach((height: number) => this.drawers.push(height))
    // From the prodboard file.
    this.index = input.index
    this.uid = input.uid

    this.commentHome.id = this.uid
    this.prodboardComment = this.input.prodboardComment || {} as any

    this.dimensions = {...this.dimensions, ...this.input.dimensions}
    // Adjust depth for dishwashers, i.e. they are actually on 25 mm deep,
    // Also height is fixed to 777
    if (this.isDishwasherCabinet) {
      this.dimensions.z = 25
      this.dimensions.y = 777

      // Also prevent the following options
      this.ignoredTypes.add('Hinges')
      this.ignoredTypes.add('Hanging')
      this.ignoredTypes.add('HandleDoor')
      this.ignoredTypes.add('HandleDrawer')
    }

    this.layout = new LayoutCalculator(this)

    this.baseSettings.fromCabinet(this)

    // When we come here, we have a "product" from the product database
    // And an "item" from prodboard, we can now set interesting properties
    // based on the input.
    this.analyzeItems(this.input.items)
    this.analyzeOptions(this.input.options)

    /**
     * If this cabinet has the shDe we add this
     * as an additional option.
     */
    if (this.product.shDe) {
      const plinth = new Plinth({value: {name: 'Plinth'}} as any, this.product, this.index)
      this.addOption(plinth)
    }

    this.options.forEach(o => {
      // Update texts
      this.settingsItemService.updateCabinetOption(o)
      // Needed to make sure all options have a proper id.
      o.setCommentHomeId(this.uid)
    })

    this.updateOptions()
    this.calculateCabinetPrice()

    this.options = CabinetOption.sortOptions(this.options)
  }

  get hasStoppers(): boolean {
    return this.product.scSe === 'default'
  }

  get hiddenWidth(): number {
    if (this.hiddenVisibleWidth) {
      return this.hWidth || this.hiddenVisibleWidth.hiddenWidth
    }
    return 0
  }

  get hasDrawerInserts(): CabinetOption | undefined {
    return this.getActiveOption('DrawerInsert')
  }

  /**
   * If not a corner cabinet, the visible width is the same as width (x)
   */
  get visibleWidth(): number {
    if (this.hiddenVisibleWidth) {
      return this.vWidth || this.hiddenVisibleWidth.visibleWidth
    }
    return this.dimensions.x
  }

  /**
   * Returns true if this is considered an Oven cabinet.
   */
  get isOven(): boolean {
    return this.product.ioc
  }

  /**
   * Tells if this cabinet has only drawers and more
   * than one drawer. This is used to generate warnings
   * if frame - recess !== 20, but not if it is a sink-out
   */
  get drawersOnly(): boolean {
    return this.product.nuDr > 1 && this.product.nuDo < 1 && this.product.rct.indexOf('sink') === -1
  }

  get actualHeight(): number {
    return this.figureOutActualHeight()
  }

  /**
   * Return cabinet FRONT area in m2
   */
  get area(): number {
    return this.dimensions.x * this.dimensions.y / 1000 / 1000
  }

  /**
   * Return cabinet volume in m3
   */
  get volume(): number {
    return this.dimensions.x * this.dimensions.y * this.dimensions.z / 1000 / 1000 / 1000
  }

  get numberOfShelves(): number {
    return this.product.nuShSt
  }

  get numberOfDoors(): number {
    return this.product.nuDo
  }

  get numberOfDrawers(): number {
    return this.product.nuDr
  }

  get sockelHeight(): number {
    return this.product.shDe
  }

  get highestPoint(): number {
    if (this.position?.center) {
      return this.position.center.y + (this.dimensions.y / 2)
    }
    return 10000
  }

  get counterTopId(): string {
    return this.settings.counterTop
  }

  set counterTopId(id: string) {
    this.settings.counterTop = id
  }

  /**
   * Cabinet type is a shortcut for recess cabinet type (rct)
   * e.g. wall-exec, base, tall-exec etc.
   */
  get cabinetType(): string {
    return this.product.rct
  }

  set cabinetType(type: string) {
    this.product.rct = type
  }

  get hasLights(): boolean {
    return !!this.getActiveOption<Lighting>('Lighting')
  }

  private static fixPrice(value: any): number {
    if (isNaN(+value)) {
      return 0
    }
    return +value
  }

  public getScribings(): string {
    return this.getActiveOptions<Scribings>('Scribings')
      .map((option: Scribings) => option.viewOptions[0].selection)[0] || ''
  }

  /**
   * This will get you an option if we have one and it is active.
   * If we have several, it will return the first it finds.
   * @param name - The optionSelectName
   */
  public getActiveOption<T extends CabinetOption>(name: TOptionSelectName): T | undefined {
    return this.optionsMap.get(name).find((o: T) => o.active)
  }

  /**
   * This will return all options of name, active or not or
   * an empty array that you must not fiddle with.
   * @param name - The optionSelectName
   */
  public getOption<T extends CabinetOption>(name: TOptionSelectName): Readonly<T[]> {
    return this.optionsMap.get(name)
  }

  /**
   * Get a whole bunch of items, but only the active ones. This is
   * for the special cases like Jar rack etc.
   * @param name
   */
  public getActiveOptions<T extends CabinetOption>(name: TOptionSelectName): Readonly<T[]> {
    return this.optionsMap.get(name).filter((o: T) => o.active)
  }

  /**
   * Check if this should have an inside paint note, only run on request.
   */
  public checkIfPaintedInsideNote(): string {
    let paintedInsideNote = ''
    this.getOption<Door>('Door')
      .forEach((door: Door) => paintedInsideNote = door.paintedInsideNote)
    return paintedInsideNote
  }

  public checkSettingsDiff(): string[] {
    /**
     * A list of translation for the warning if values differ from Prodboard and Mill
     */
    const translation = {
      title: 'description',
      titleEn: 'description to carpentry',
      height: 'height',
      width: 'width',
      depth: 'depth',
      price: 'price',
      labor: 'carpentry cost',
      material: 'material cost',
      productCommentEn: 'comment to carpentry',
      productCommentSv: 'comment to customer',
      drawers: 'drawers',
      visibleWidth: 'visible width',
      hiddenWidth: 'hidden width'
    }
    // Some options are not relevant for some cabinets
    // If not a base corner cabinet, remove the hidden/visible
    if (!this.isBaseCornerCabinet) {
      delete translation.hiddenWidth
      delete translation.visibleWidth
    }

    // If no drawers we should not warn for that.
    if (this.numberOfDrawers === 0) {
      delete translation.drawers
    }
    const diffs = []
    Object.keys(translation).forEach((key: string) => {
      const setting = this.getSettingsValue(this.settings[key])
      const base = this.getSettingsValue(this.baseSettings[key])
      if (setting && setting !== base) {
        diffs.push(`${translation[key]} (${setting} <> ${base})`)
      }
    })
    return diffs
  }

  public getSettingsValue(value: any): string {
    if (!value) {
      return value
    }
    if (Array.isArray(value)) {
      return `[${value.join(',')}]`
    }
    return value + ''
  }

  /**
   * This is called to update cabinets with project data, this is called at two
   * occasions, 1, when we fetch a project from server, and 2) when we update
   * an option, the project data gets updated, and this is then called. So
   * it is guaranteed to be called when an option has changed.
   */
  public update(cabinetOptions?: Record<string, CabinetOption | Comment[]>): void {
    if (cabinetOptions) {
      this.comments = cabinetOptions.comments as Comment[] || []
      // Take all keys, but remove the 'comments' just in case.
      const keys = Object.keys(cabinetOptions).filter(k => k !== 'comments')
      // Filter out all options that are in the list of keys.
      this.options
        .filter((o: CabinetOption) => keys.indexOf(o.name) !== -1)
        .forEach((o: CabinetOption) => {
          const data = cabinetOptions[o.name] as CabinetOption
          o.update(data)
          // Special case that deactivates options that otherwise will activate themselves
          if (data.hasOwnProperty('active')) {
            o.active = data.active
          }
        })
    }
    this.updateOptions() //
    this.calculateCabinetPrice()
  }

  public getSettings(): CabinetSettings {
    return this.settings
  }

  /**
   * Override settings that take precedence over prodboard values (except index)
   *
   * @param settings
   */
  public setSettings(settings: CabinetSettings = new CabinetSettings()): void {
    Object.assign(this.settings, settings)

    /**
     * We have to move the center point when we change the size of a cabinet
     * otherwise we won't be abel to calculate the maximum height
     */
    if (settings.width) {
      this.dimensions.x = settings.width
      this.position.center.x = settings.width / 2
    }
    /**
     * Woot! Note that we try to override the "actual height", that
     * So if we have a height here the dimensions has to be
     * set as the difference between _current_ actual and y
     */
    if (settings.height) {
      this.dimensions.y = settings.height + (this.dimensions.y - this.pActualHeight)
      this.position.center.y = this.dimensions.y / 2
    }
    if (settings.depth) {
      this.dimensions.z = settings.depth
      this.position.center.z = settings.depth / 2
    }
    this.description = settings.titleEn || this.description
    this.basePrice = settings.price || this.basePrice
    this.baseLabor = settings.labor || this.baseLabor
    this.baseMaterial = settings.material || this.baseMaterial
    this.hWidth = settings.hiddenWidth || this.hiddenWidth
    this.vWidth = settings.visibleWidth || this.visibleWidth

    this.deSwLaSw = settings.title || this.deSwLaSw

    this.productComments.en = settings.productCommentEn || this.product.co
    this.productComments.sv = settings.productCommentSv || this.product.coSe
    /**
     *
     */
    this.drawers = settings.drawers || this.drawers
    if (this.hiddenVisibleWidth) {
      this.hiddenVisibleWidth.update({
        hiddenWidth: this.hiddenWidth,
        visibleWidth: this.visibleWidth
      })
      this.dimensions.x = this.hiddenWidth + this.visibleWidth
    }
    this.calculateCabinetPrice()
  }

  public resetSettings(): void {
    this.settings = new CabinetSettings()
    this.setSettings(this.baseSettings)
  }

  private calculateCabinetPrice(): void {
    this.specialOptions.length = 0
    const actualHeight = this.figureOutActualHeight()
    if (actualHeight > this.product.hiSt) {
      this.specialOptions.push({
        id: 'higherThanStandard',
        price: ProdboardCabinet.fixPrice(this.product.exPrHiSt.price),
        labor: ProdboardCabinet.fixPrice(this.product.exPrHiSt.labor)
      })
    }

    if (this.dimensions.z > this.product.deSt) {
      this.specialOptions.push({
        id: 'deeperThanStandard',
        price: ProdboardCabinet.fixPrice(this.product.exPrDeSt.price),
        labor: ProdboardCabinet.fixPrice(this.product.exPrDeSt.labor)
      })
    }


    this.options
      .filter(o => o.active)
      .forEach((option: CabinetOption) => {
        option.setOptionValues(this)
      })
  }

  private analyzeItems(items: ProdboardSelectionItem[]): void {
    const hiddenVisibleWidth: ProdboardSelectionItem[] = []
    items.forEach((item: ProdboardSelectionItem) => {
      switch (item.code) {
        case 'frames/json':
          const frame = new FrameWidth(item as any, this.product, this, new FrameWidthHelper())
          this.frame = frame
          this.addOption(frame)
          break
        case 'fillers/json':
          const filler = new Filler(item as any, this.product, this.index)
          this.fillers = filler
          this.addOption(filler)
          break
        case 'legs/json':
          this.addOption(new Legs(item as any, this.product, this.index))
          break
        case 'hidden width':
        case 'visible width':
          hiddenVisibleWidth.push(item)
          break
        case 'handle_door':
          this.addOption(new HandleDoor(item, this.product, this.index))
          break
        default:
        // Do nothing on purpose
      }
    })

    if (hiddenVisibleWidth.length > 0) {
      this.hiddenVisibleWidth = new HiddenVisibleWidth({} as any, this.product, this.index, hiddenVisibleWidth)
      this.addOption(this.hiddenVisibleWidth)
      this.dimensions.x = this.hiddenVisibleWidth.hiddenWidth + this.hiddenVisibleWidth.visibleWidth
      this.dimensions.z = this.dimensions.z - 50
    }
    if (hiddenVisibleWidth.length === 0) {
      this.correctDepth()
    }
  }

  /**
   * All prodboard options get have a corresponding class to handle
   * them individually.
   *
   * @param options - Options as found on the prodboard file
   * @private
   */
  private analyzeOptions(options: ProdboardCabinetOption[]) {
    /**
     * The same option can occur several times. We need to number them.
     * For that reason we create a map of: "prodboard_option_name" -> counter
     * Also, we will include special counters for options with different
     * name that end up creating the same type of CabinetOption.
     * Basically, if more than one switch-case is together, we need a special
     * counter.
     */
    const counters = {
      // Initialise the special counters here.
      shelve: 0,
      backPanel: 0,
      drawerInsert: 0,
      coverSide: 0,
      fanExtractor: 0
    }
    // Initialise all generic counters
    options.forEach((o: ProdboardCabinetOption) => (counters[o.name] = 0))
    const drawerInsertsList: ProdboardCabinetOption[] = []
    const hiddenDrawersList: ProdboardCabinetOption[] = []
    options.forEach((option: ProdboardCabinetOption) => {
      let opt: CabinetOption
      // Set option count, generic case, and update counter after.
      // Do not worry, even though we do "counters[option.name]++", the "++"
      // will modify the value after being set in "option.count".
      option.count = counters[option.name]++
      switch (option.name.toLowerCase()) {
        case 'anpassat för fläkt':
          opt = new FanAdoption(option, this.product, this.index)
          break
        case 'brass tag':
          opt = new BrassPlate(option, this.product, this.index)
          break
        case 'combined unit':
          opt = new CombinedUnit(option, this.product, this.index)
          break
        case 'doors':
          opt = new DoorType(option, this.product, this.index)
          break
        case 'gångjärn':
          opt = new Hinges(option, this.product, this.index)
          break
        case 'handtag, låda':
          opt = new HandleDrawer(option, this.product, this.index)
          break
        case 'hyllor':
        case 'hyllor/lådor':
          option.count = counters.shelve++
          opt = new Shelves(option, this.product, this.index)
          break
        case 'hyllor på luckans insida':
          opt = new SpiceRack(option, this.product, this.index)
          break
        case 'hängning':
          opt = new Hanging(option, this.product, this.index)
          break
        case 'inbyggd belysning':
          opt = new Lighting(option, this.product, this.index)
          break
        case 'krönlist':
          opt = new Cornice(option, this.product, this.index)
          break
        case 'mittstolpe':
          opt = new CenterPost(option, this.product, this.index)
          break
        case 'målning':
          const pp = new PaintProcess(option, this.product, this.index)
          opt = pp
          /**
           * We set the paint process 'index', any cabinet that has this > 0
           * will indicate the paint process. Last cabinet will win. We should
           * in the future generate a warning if different or something.
           */
          this.paintProcess = pp.paintProcess()
          break
        case 'släta lådfronter':
          opt = new DrawerFront(option, this.product, this.index)
          break
        case 'snickarglädje':
          opt = new CarpenterJoy(option, this.product, this.index)
          break
        case 'sockel':
          opt = new Skirting(option, this.product, this.index)
          this.skirting = opt as Skirting
          break
        case 'ställbara hyllplan':
          opt = new ShelvesAdjustable(option, this.product, this.index)
          break
        case 'typ av lucka':
          opt = new Door(option, this.product, this.index)
          break
        case 'luckinfästning':
          opt = new DoorAttachment(option, this.product, this.index)
          break
        case 'ramens bredd (admin only)':
          opt = new Scribings(option, this.product, this.index)
          break
        case 'täcksida, höger':
        case 'täcksida, vänster':
          option.count = counters.coverSide++
          opt = new CoverSide(
            option,
            this.product,
            this.index,
            option.name.includes('höger') ? 'right' : 'left'
          )
          break
        case 'täcksidor':
        case 'täcksida, baksida':
          option.count = counters.backPanel++
          opt = new BackPanel(option, this.product, this.index)
          break
        case 'utdragslådor':
          // BD1o and BD1o+PC has the hidden drawer set but is not relevant
          if (this.product.ioc === true) {
            opt = new UnknownOption(option, this.product, this.index, option.name)
            break
          }
          opt = new HiddenDrawerSink(option, this.product, this.index)
          break
        case 'utdragbar skärbräda':
          opt = new CuttingBoard(option, this.product, this.index)
          break
        case 'drawer/door':
          opt = new DrawerDoor(option, this.product, this.index)
          break
        case 'paint side because of dw':
          opt = new PaintSide(option, this.product, this.index)
          break
        case 'sida, front':
        case 'sida, vänster':
        case 'sida, höger':
          option.count = counters.fanExtractor++
          opt = new FanExtractorPanel(option, this.product, this.index)
          break
        case 'template':
          opt = new UnknownOption(option, this.product, this.index, 'template')
          break
        default:
          opt = new UnknownOption(option, this.product, this.index, option.name)
          if (RegExp(/LÅDINSATS/i).exec(option.name) !== null) {
            drawerInsertsList.push(option)
          }
          this.setHiddenDrawer(hiddenDrawersList, option)
      }

      if (!this.ignoredTypes.has(opt.optionSelectName)) {
        this.addOption(opt)
      }
    })

    if (drawerInsertsList.length > 0) {
      this.addOption(new DrawerInsert({} as any, this.product, this.index, drawerInsertsList))
    }

    if (hiddenDrawersList.length > 0) {
      this.addOption(new HiddenDrawer({count: 0} as any, this.product, this.index, hiddenDrawersList))
    }
  }

  private addOption(option: CabinetOption): void {
    const options = [...this.optionsMap.get(option.optionSelectName)]
    options.push(option)
    this.optionsMap.set(option.optionSelectName, options)
    this.options.push(option)
  }

  /**
   * Do some updates based on our options. This is called
   * also when we get updates from outside, not just when rendering
   */
  private updateOptions(): void {
    /**
     * Adjust frame and fillers based on skirting.
     */
    this.setFillersAndFrames()

    /**
     * If no door, then no hanging
     */
    this.removeHanging()

    /**
     * Remove Hinges, HandleDoor and HandleDrawer if DoorAttachment is equal to "Door on door".
     * This is because that means it's a refrigerator or freezer and shouldn't have these options
     */
    this.removeIfDoorOnDoor()
  }

  private setHiddenDrawer(hiddenDrawersList: ProdboardCabinetOption[], option: ProdboardCabinetOption): void {
    const parts = [
      'översta delen', 'andra delen', 'tredje delen', 'fjärde delen',
      'femte delen', 'sjätte delen', 'sjunde delen', 'åttonde delen']
    if (parts.indexOf(option.name.toLowerCase()) !== -1) {
      hiddenDrawersList.push(option)
    }
  }

  /**
   * Deactivates the hanging option if there is "NO DOOR" on this cabinet. You cannot
   * mix doors?
   */
  private removeHanging(): void {
    this.getOption<Door>('Door')
      .filter((o: CabinetOption) => o.viewOptions[0].selection === DOOR_NO_DOOR_SELECTED)
      .forEach(() => {
        this.getActiveOptions<Hanging>('Hanging')
          .forEach((o) => o.active = false)
      })

    this.getOption<DrawerDoor>('DrawerDoor')
      // If låda then no hanging / hinges
      .forEach((drawerDoor) => {
        this.getOption<Hinges>('Hinges')
          .forEach((o) =>
            o.active = drawerDoor.viewOptions[0].selection === DRAWER_DOOR_DOOR)
      })
  }

  /**
   * If we have Door attachment, and the attachment is Door-on-Door
   * then remove hinges and handles
   * @private
   */
  private removeIfDoorOnDoor(): void {
    this.getOption<DoorAttachment>('DoorAttachment')
      // Only doorAttachment can have "Door on Door"
      .filter((option: CabinetOption) => option.viewOptions[0].selection === DOOR_ON_DOOR)
      .forEach(() => {
        // There is normally just one so ...
        [
          this.getOption('Hinges'),
          this.getOption('HandleDoor'),
          this.getOption('HandleDrawer')]
          .flat()
          .forEach((option: CabinetOption) => option.active = false)
      })
  }

  /**
   * A very special case where recess (fillers) and frame needs
   * updating based on skirting.
   */
  private setFillersAndFrames(): void {
    // We should only update the values if the skirting has _changed_
    if (this.currentSkirting !== this.skirting.viewOptions[0].selection) {
      this.currentSkirting = this.skirting.viewOptions[0].selection
      this.fillers.setTopAndBottom(this.skirting, this.frame)
      this.frame.updateBasedOnSkirting(this.skirting)
    }
  }

  /**
   *
   */
  private figureOutActualHeight(): number {
    if (this.project.form.socelHeight === 0) {
      this.pActualHeight = this.dimensions.y
      return this.pActualHeight
    }

    this.pActualHeight = this.dimensions.y - (this.product.shDe || 0)
    return this.pActualHeight
  }

  /**
   * Corner cabinets BCP, BC, BCD2, should have a depth correction
   * of 50 mm.
   */
  private correctDepth(): void {
    if (this.product.ibcc === true) {
      this.dimensions.z = this.dimensions.z - 50
    }
  }
}
