import {TLanguageCode} from '../../application/i18n.provider'
import {TSettingOptionUseCase} from '../../services/settings-item.service'
import {
  CabinetOption,
  TOptionSelectName,
  ViewOptionType
} from '../cabinet-option'
import {IProduct} from '../../common/interface/product-types'
import {FactoryData, IFDFrame} from '../factory/factory-data'
import {FrameWidthHelper} from './frame-width-helper'
import {Skirting} from '../skirting'
import {ProdboardCabinetOption} from '../../services/prodboard-types'
import {ProdboardCabinet} from '../cabinet/prodboard-cabinet'

export const FRAME_BOTTOM_POS = 3
export const FRAME_LEFT_POS = 1
export const FRAME_RIGHT_POS = 2
export const FRAME_TOP_POS = 0

/**
 * FrameWidth option keys
 */
export const FRAME_WIDTH_OPTION_KEY_LEFT = 'left'
export const FRAME_WIDTH_OPTION_KEY_RIGHT = 'right'

export type TFrameWidthUpdateProperties = 'top' | 'left' | 'right' | 'bottom'
export type TFrameWidthUpdateData = Record<TFrameWidthUpdateProperties, string>

export class FrameWidth extends CabinetOption {
  private frameWidthHelper: FrameWidthHelper

  /**
   * Set differently if scribings on cabinet
   */
  private measureLeft = ' mm'
  private measureRight = ' mm'

  constructor(option: ProdboardCabinetOption, product: IProduct, private cabinet: ProdboardCabinet, frameWidthHelper: FrameWidthHelper) {
    super(option, product, cabinet.index)
    this.frameWidthHelper = frameWidthHelper
    this.priority = 5
    this.viewOptions = [
      {
        type: ViewOptionType.text,
        title: 'Top (mm)',
        name: 'top',
        values: [],
        selection: '',
        disabled: false
      },
      {
        type: ViewOptionType.text,
        title: 'Left (mm)',
        name: 'left',
        values: [],
        selection: '',
        disabled: false
      },
      {
        type: ViewOptionType.text,
        title: 'Right (mm)',
        name: 'right',
        values: [],
        selection: '',
        disabled: false
      },
      {
        type: ViewOptionType.text,
        title: 'Bottom (mm)',
        name: 'bottom',
        values: [],
        selection: '',
        disabled: false
      }
    ]
    this.active = true

    this.description = 'The facade frame - around the doors - can have different widths'
    this.title = 'optFrameWidth'

    FrameWidth.sanitize(option)
    this.setValuesFromProdboardData(this.option)
  }

  get optionSelectName(): TOptionSelectName {
    return 'FrameWidth'
  }

  get bottom(): number {
    return +this.viewOptions[FRAME_BOTTOM_POS].selection
  }

  get top(): number {
    return +this.viewOptions[FRAME_TOP_POS].selection
  }

  get right(): number {
    return +this.viewOptions[FRAME_RIGHT_POS].selection
  }

  get left(): number {
    return +this.viewOptions[FRAME_LEFT_POS].selection
  }

  private static sanitize(option: ProdboardCabinetOption): void {
    option.value = option.value || {bottom: 0, left: 0, right: 0, top: 0} as any
  }

  public addScribings(scribings: string): void {
    if (scribings === 'Vänster' || scribings.indexOf('Både') !== -1) {
      this.measureLeft = ' + 40* mm'
    }
    if (scribings === 'Höger' || scribings.indexOf('Både') !== -1) {
      this.measureRight = ' + 40* mm'
    }
  }

  public getFactoryData(): FactoryData {
    const frame: IFDFrame = {
      bottom: 0,
      left: 0,
      right: 0,
      top: 0
    }

    if (this.active) {
      frame.bottom = this.bottom
      frame.left = this.left
      frame.right = this.right
      frame.top = this.top
    }
    return {frame}
  }

  public update(data: Partial<TFrameWidthUpdateData>): void {
    super.update(data)
    this.setFromProperties(data)
    this.resetPrices()
  }

  /**
   * When we know the skirting type, we set the top and bottom
   * frame based on the skirting type.
   */
  public updateBasedOnSkirting(skirting: Skirting): void {
    const defaults = this.getDefaultRecess(this.product, skirting, this)
    const bf = defaults.bf >= 0 ? defaults.bf : this.bottom
    const tf = defaults.tf >= 0 ? defaults.tf : this.top
    const data = {bottom: bf + '', top: tf + ''}
    this.update(data)
  }

  public getCustomCustomerListing(useCase: TSettingOptionUseCase, lc: TLanguageCode): string[] {
    const uniqueCabinets: string = this.frameWidthHelper
      .getString(this.viewOptions, this.product, lc, this.cabinet)
    if (uniqueCabinets !== '') {
      return [uniqueCabinets]
    }

    // The factory does not want this in the listings, neither if
    // both left and right are zero
    if (useCase === 'f' || (this.left + this.right) === 0) {
      return []
    }

    // We return both option values that FrameWidth has: 'left' and 'right'.
    // They can be checked in override.defaults.ts.
    return [
      this.settingOption.getI18n(FRAME_WIDTH_OPTION_KEY_LEFT, useCase, lc)
      + this.left + this.measureLeft,
      this.settingOption.getI18n(FRAME_WIDTH_OPTION_KEY_RIGHT, useCase, lc)
      + this.right + this.measureRight
    ]
  }

  private setValuesFromProdboardData(option: any): void {
    /**
     * As it happens, the shape of value matches our data, e.g. top, bottom, left, right
     */
    this.setFromProperties(option.value)
  }
}
