import {TLanguageCode} from '../application/i18n.provider'
import {IProduct} from '../common/interface/product-types'
import {TSettingOptionUseCase} from '../services/settings-item.service'
import {
  CabinetOption,
  TOptionSelectName,
  ViewOptionType
} from './cabinet-option'
import {ProdboardCabinetOption} from '../services/prodboard-types'
import {DOOR_NO_DOOR_SELECTED, LUCKA_DJUPADAL, LUCKA_HOGALID, LUCKA_RONNEHOLM, LUCKA_SUNNANA} from './model-types'
import {ProdboardCabinet} from './cabinet/prodboard-cabinet'
import {Door} from './door'
import {DefaultMap} from '../application/helpers'

export class DoorType extends CabinetOption {
  private priceMap = new DefaultMap<string, number, number>([
    [LUCKA_HOGALID, 420],
    [LUCKA_DJUPADAL, 210],
    // Legacy
    ['1. Överfalsad profilerad lucka', 420],
    ['2. Innanförliggande profilerad lucka', 210]
  ], 0)

  private numDoors: number
  private readonly numDrawers: number

  constructor(option: ProdboardCabinetOption, product: IProduct, cabinetId: number) {
    super(option, product, cabinetId)
    this.priority = 10
    this.viewOptions = [
      {
        type: ViewOptionType.select,
        title: 'Lucktyp',
        name: 'typeDoor',
        values: [
          LUCKA_HOGALID,
          LUCKA_DJUPADAL,
          LUCKA_RONNEHOLM,
          LUCKA_SUNNANA,
          '0. Ej specificerat',
          '1. Överfalsad profilerad lucka',
          '2. Innanförliggande profilerad lucka',
          '3. Innanförliggande shakerlucka',
          'Door style 4'
        ],
        selection: '',
        disabled: false
      }
    ]
    this.active = true
    this.description = 'Decides what kind of door style the cabinet has - there are 5 different styles'
    this.title = 'optDoorType'

    const sOption = DoorType.sanitize(option)
    this.setValuesFromProdboardData(sOption)
    this.numDoors = this.product.nuDo
    this.numDrawers = this.product.nuDr
  }

  get optionSelectName(): TOptionSelectName {
    return 'DoorType'
  }

  private static sanitize(option: ProdboardCabinetOption): ProdboardCabinetOption {
    option.collection = option.collection || {} as any
    option.collection.name = option.collection.name || ''
    return option
  }

  public update(data: any) {
    super.update(data)
    this.active = true
    this.setFromProperties(data)
    this.setPrice()
  }

  /**
   * This must be called after creation or prices will not be correct
   * @param cabinet
   */
  public setOptionValues(cabinet: ProdboardCabinet) {
    cabinet.getOption<Door>('Door').filter(d => d.typeOfDoor() === DOOR_NO_DOOR_SELECTED)
      .forEach(() => this.numDoors = 0)
    this.setPrice()
  }

  public getCustomCustomerListing(
    useCase: TSettingOptionUseCase,
    lc: TLanguageCode
  ): string[] {
    return [this.settingOption.getI18n(this.viewOptions[0].selection, useCase, lc)]
  }

  private setValuesFromProdboardData(option: any) {
    this.viewOptions[0].selection = option.collection.name
  }

  private setPrice(): void {
    if (this.numDoors + this.numDrawers < 1) {
      this.active = false
      this.viewOptions[0].selection = '0. Ej specificerat'
    }
    this.resetPrices()
    this.price = this.priceMap.get(this.viewOptions[0].selection) * (this.numDoors + this.numDrawers)
    if (this.price > 0) {
      this.shouldHavePrice = true
    }
  }
}
