import {TBallTagOwner} from '../model/tags/balls-tag'

export interface ICustomer {
  /**
   * Id from database
   */
  id: string

  /**
   * Version from database, please do not care.
   */
  version: number

  /**
   * Type is a databse thing and is always "CST"
   */
  type: 'CST'

  /**
   * Database latest save time.
   */
  timeStamp: number

  /**
   * Name is the name of the customer, like Adam Haglund NOT
   * the project name 167 - What ever
   */
  name: string

  address1: string
  address2: string
  postalCode: string
  postalTown: string
  country: string

  invoiceAddress1: string
  invoiceAddress2: string
  invoicePostalCode: string
  invoicePostalTown: string
  invoiceCountry: string

  /**
   * This is a list of customer projects that belong
   * to this customer.
   */
  projects: CustomerProjectData[]

  /**
   * State is the _best_ state of all customer projects.
   * It is just the indicator ('A', 'B' etc.), nothing else
   */
  currentStateLabel: CustomerStateLabel
}

export interface ICustomerProject {
  /**
   * The database id (uuid) for the customer project
   */
  id: string

  /**
   * Database version, must be retained between saves
   */
  version: number

  /**
   * Type as stored in the database, always CuSTomerProject
   */
  type: 'CSTP'


  /**
   * Customer id, a customer project should always have
   * a customer.
   */
  customerId: string

  /**
   * Wrap name so that we can do listings, not
   * a fan of this, but it is ok.
   */
  customerName: string

  /**
   * A MANDATORY Project ID for a 'Kitchen Project'
   * to connect this to. This must always be created
   * based on a kitchen project
   */
  projectId: string

  /**
   * All states of this customer project
   */
  states: ICustomerState[]

  /**
   * If this is archived or not, does not
   * say anything about which archive
   */
  archived: boolean
}

/**
 * One day I will understand this, but for now I want
 * to be able to iterate these, so I create the type
 * from the array
 */
const stateLabels = ['PRE_B', 'PRE_A', 'A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I'] as const
export type CustomerStateLabel = typeof stateLabels[number]

export const stateIds = [
  'SKIRTING',
  'HANDLES',
  'DOOR_ATTACHMENTS',
  'PRODBOARD_FILE',
  'CUSTOMER_ADDRESS',
  'PAINT_FINISHED',
  'SEND_INVOICE',
  'STENY',
  'LIGHTS',
  'LIGHT_DRIVERS',
  'PAINTING_EMAIL',
  'INVOICE_TWO',
  'DRAWER_INSERTS',
  'PRODBOARD_QUOTE',
  'AGACCO',
  'SPECIAL_PAINT'
] as const
export type StateId = typeof stateIds[number]

export interface CustomerStateName {
  /**
   * The state, basically an enum but a type...
   */
  state: CustomerStateLabel

  /**
   * Display name
   */
  name: string

  /**
   * These have an order, 0 is "new", 9-ish archived
   */
  position: number

  /**
   * Image an icon or a png?
   */
  image: string
}

/**
 * Part of State model that doesn't change between Customer Projects.
 * This part needs updating if states are modified in customer-state-map.ts
 */
export interface ICustomerStateStatic {
  /**
   * The state decorators and "id"
   */
  state: CustomerStateName

  /**
   * A test description of when the description ends
   */
  descriptionEnd: string
}

/**
 * Full State model, with static and dynamic fields.
 * Dynamic fields are those that can vary between customer projects, because
 * of state change, user input, etc.
 * Dynamic fields should not be updated with customer-state-map.ts changes.
 */
export interface ICustomerState extends ICustomerStateStatic {
  /**
   * A set of yes no conditions if any. Leave empty array if none.
   * All has to be checked true?
   */
  conditions: ICustomerStateCondition[]

  /**
   * Tells if this is completed
   */
  completed: boolean

  /**
   * Below follows optional parameters for the various
   * states.
   */

  /**
   * Step 1. We must select a prodboard project.
   * When that is done the step is completed.
   */
  projectId?: string
}

/**
 * Binary, true/false - Checkbox
 * Unary, yes or no, both are valid but one must be selected
 * List, must select from list of values 'a' | 'b' or 'c'
 */
export type ConditionType = 'BINARY' | 'UNARY' | 'LIST'

export interface IAdvancedOptionAction {
  completeState?: boolean
  archive?: boolean
  tagToOwner?: TBallTagOwner
  lockPrice?: boolean
}

export interface IAdvancedOption {
  name: string
  action: IAdvancedOptionAction
}

/**
 * Part of the Condition model that does not change between Customer Projects.
 * This part needs updating if states are modified in customer-state-map.ts
 */
export interface ICustomerStateConditionStatic {
  /**
   * We need to account for these so that we
   * can add new conditions.
   */
  id: string

  /**
   * The type of condition
   */
  type: ConditionType

  /**
   * What we display
   */
  label: string

  /**
   * Optional, undefined or false if not optional,
   * set to true if not needed to pass the state
   */
  optional?: boolean

  /**
   * A list of options, actually mandatory
   * if type is UNARY, will extend later?
   */
  options?: string[]

  /**
   * They can work with type BINARY or UNARY.
   * They are used to perform complex actions once an option is clicked.
   *  BINARY -> Only the first option will be used, and only the action,
   *  "name" property will be unused.
   *  UNARY -> A list of options, that if included will override "options".
   */
  advancedOptions?: IAdvancedOption[]

  /**
   * If the state is affected by some rule
   */
  stateIds?: StateId[]

  /**
   * Conditions can have a deadline depending on the approximate delivery date
   * in project settings. They can have different auto deadlines.
   * We use this to subtract from the approximate delivery date
   */
  autoDeadlineSubtraction?: number

  /**
   * The position in list
   */
  position: number
}

/**
 * Full Condition model, with static and dynamic fields.
 * Dynamic fields are those that can vary between customer projects, because
 * of state change, user input, etc.
 * Dynamic fields should not be updated with customer-state-map.ts changes.
 */
export interface ICustomerStateCondition extends ICustomerStateConditionStatic {
  /**
   * Selection, if > Binary, this is the selected value
   */
  selection?: string

  /**
   * Completed or not.
   */
  completed: boolean

  /**
   * Time of change, save the UNIX epoch in ms.
   */
  timeStamp?: number

  /**
   * If this cannot be changed by user (e.g. due to
   * warning connection)
   */
  disabled?: boolean

  /**
   * Not applicable, if not shown in this context, like if
   * the project is not Türkie (or what it is called nowadays)
   */
  notApplicable?: boolean

  /**
   * Unix Epoch in ms when this item expires
   */
  deadline?: number

  /**
   * A list of strings representing comments on this item.
   * Right now we only use this in the condition-form.component.ts.
   * There we don't need an array, so we reset the array and only
   * use the first value. This need to be revisited if we need an
   * actual array of comments
   */
  comments?: string[]
}

/**
 * This is used so that we can store enough data
 * on the customer
 */
export interface CustomerProjectData {
  /**
   * The ID of the CustomerProject
   */
  id: string

  /**
   * The short state of select project
   */
  state: CustomerStateLabel
}

