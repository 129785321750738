import {TLanguageCode} from '../application/i18n.provider'
import {TSettingOptionUseCase} from '../services/settings-item.service'
import {
  CabinetOption,
  TOptionSelectName,
  ViewOptionType
} from './cabinet-option'
import {IProduct} from '../common/interface/product-types'
import {ProdboardCabinetOption} from '../services/prodboard-types'

export class FanAdoption extends CabinetOption {
  constructor(option: ProdboardCabinetOption, product: IProduct, cabinetId: number) {
    super(option, product, cabinetId)

    this.priority = 5 // This feels important
    this.viewOptions = [
      {
        type: ViewOptionType.binary,
        title: 'Inbyggd fläkt',
        name: 'fan',
        values: [
          'Ja',
          'Nej'
        ],
        selection: '',
        disabled: false
      }
    ]
    this.description = 'A cabinet can have a built-in extractor (hood)'
    this.title = 'optFanAdoption'
    const sOption = FanAdoption.sanitize(option)
    this.setValuesFromProdboardData(sOption)
    this.setPrice()
  }

  get optionSelectName(): TOptionSelectName {
    return 'FanAdoption'
  }

  public getCustomCustomerListing(useCase: TSettingOptionUseCase, lc: TLanguageCode): string[] {
    // 'Ja' is the only value for FanAdoption
    return [this.settingOption.getI18n('Ja', useCase, lc)]
  }

  private static sanitize(option: ProdboardCabinetOption): ProdboardCabinetOption {
    option.value = option.value || {} as any
    return option
  }

  public update(data: any): void {
    super.update(data)
    this.setFromProperties(data)
    this.setYesNoActive(data)
    this.active = this.viewOptions[0].selection === 'Ja'
    this.setPrice()
  }

  private setValuesFromProdboardData(option: ProdboardCabinetOption): void {
    this.active = option.value.name === 'Ja'
    this.viewOptions[0].selection = option.value.name
  }

  private setPrice(): void {
    this.resetPrices()
    if (this.active) {
      this.price = this.product.adBuInHoPr.price
      this.labor = this.product.adBuInHoPr.labor
      this.shouldHavePrice = true
    }
  }
}
