import {TLanguageCode, TTranslationItem} from '../application/i18n.provider'
import {IProduct} from '../common/interface/product-types'
import {ProdboardCabinetOption} from '../services/prodboard-types'
import {TSettingOptionUseCase} from '../services/settings-item.service'
import {
  CabinetOption,
  TOptionSelectName,
  ViewOption,
  ViewOptionType
} from './cabinet-option'
import {
  OPTION_COMBO,
  OPTION_COMBO_OLD,
  OPTION_JAR,
  OPTION_JAR_OLD,
  OPTION_NO,
  OPTION_OFF,
  OPTION_SPICE,
  OPTION_SPICE_OLD,
  SELECTION_NONE
} from './model-types'

export class SpiceRack extends CabinetOption {
  private translation: {[key: string]: TTranslationItem} = {}

  constructor(option: ProdboardCabinetOption, product: IProduct, cabinetId: number) {
    super(option, product, cabinetId)

    // Detour to avoid linter objections
    this.translation[OPTION_COMBO] = {en: 'Combo spice/jar', sv: 'Combo'}
    this.translation[OPTION_COMBO_OLD] = {en: 'Combo spice/jar', sv: 'Combo'}
    this.translation[OPTION_JAR] = {en: 'Jar rack', sv: 'Burkhylla'}
    this.translation[OPTION_JAR_OLD] = {en: 'Jar rack', sv: 'Burkhylla'}
    this.translation[OPTION_SPICE] = {en: 'Spice rack', sv: 'Kryddhylla'}
    this.translation[OPTION_SPICE_OLD] = {en: 'Spice rack', sv: 'Kryddhylla'}
    this.translation[OPTION_NO] = {en: 'None', sv: 'Ingen'}
    this.translation[OPTION_OFF] = {en: 'None', sv: 'Ingen'}

    this.priority = 20
    this.viewOptions = []

    this.description = 'A kind of shelf that is put on the inside of the door'
    this.title = 'optSpiceRack'

    this.sanitize(option)
    this.setValuesFromProdboardData(option)
    this.active = this.viewOptions.reduce((acc: boolean, vo: ViewOption) => vo.selection !== SELECTION_NONE || acc, false)
    this.setPrice()
  }

  get optionSelectName(): TOptionSelectName {
    return 'SpiceRack'
  }

  public update(data: any) {
    super.update(data)
    this.setFromProperties(data)
    this.active = !!this.viewOptions.find((viewOption: ViewOption) => SELECTION_NONE.indexOf(viewOption.selection) === -1)
    this.setPrice()
  }

  public hasSpiceJarRack(): boolean {
    return this.active && this.viewOptions.reduce((acc: boolean, option: ViewOption) => acc || option.selection !== SELECTION_NONE, false)
  }

  public getCustomCustomerListing(useCase: TSettingOptionUseCase, lc: TLanguageCode): string[] {
    return this.viewOptions
      .filter(vo => vo.selection !== SELECTION_NONE)
      .map((viewOption: ViewOption) => {
        const option =
          this.settingOption.getI18n(viewOption.selection, useCase, lc)

        // TODO: Fix this - Daniel 2023-09-17
        // Get door option value that for use-case "c" (customer) has the same
        // value for its Swedish translation as viewOption's title.
        const doorValue = this.settingOption.values
          .find(v => v.ucs.some(uc =>
          uc.type === 'c' && uc.i18n.sv === viewOption.title))
        // Then, get the correct translation for that value
        const door = this.settingOption
          .getI18nFromValue(doorValue, useCase, lc)

        return `${door}: ${option}`
      })
  }

  private sanitize(option: ProdboardCabinetOption): void {
    /**
     * We do all this if shit to handle the old way Prodboard structured the data for spice racks.
     */
    if (option.value) {
      if (option.value.options) {
        if (option.value.options.sh_door || option.value.options.sh_door_l) {
          if (option.value.options.sh_door) {
            option.value = {
              options: option.value.options,
              doors_setting: {
                door_1: {
                  pos: 'only',
                  type: {
                    code: option.value.options.sh_door.type.name === 'Nix' ? OPTION_NO : option.value.options.sh_door.type.name
                  }
                }
              }
            } as any
          }
          if (option.value.options.sh_door_l) {
            option.value = {
              options: option.value.options,
              doors_setting: {
                door_1: {
                  pos: 'left',
                  type: {
                    code: option.value.options.sh_door_l.type.name === 'Nix' ? OPTION_NO : option.value.options.sh_door_l.type.name
                  }
                }
              }
            } as any
          }
        }
        if (option.value.options.sh_door_r) {
          option.value.doors_setting = option.value.doors_setting || {} as any
          option.value.doors_setting.door_1 = option.value.doors_setting.door_1 || {
            pos: 'left',
            type: {code: OPTION_NO, name: OPTION_NO}
          } as any
          option.value = {
            options: option.value.options,
            doors_setting: {
              door_1: option.value.doors_setting.door_1,
              door_2: {
                pos: 'right',
                type: {
                  code: option.value.options.sh_door_r.type.name === 'Nix' ? OPTION_NO : option.value.options.sh_door_r.type.name
                }
              }
            }
          } as any
        }
      }
    }
    option.value = option.value || {code: 'off'} as any
    option.value.doors_setting = option.value.doors_setting || {} as any
    const numDoors = this.product.nuDo
    for (let i = 0; i < numDoors; i++) {
      option.value.doors_setting[`door_${i + 1}`] = option.value.doors_setting[`door_${i + 1}`] || {pos: i === 0 ? 'left' : 'right'} as any // The {pos} is just to make the old work
      option.value.doors_setting[`door_${i + 1}`].type = option.value.doors_setting[`door_${i + 1}`].type || {
        code: OPTION_NO,
        name: OPTION_NO
      } as any
    }
  }

  private setValuesFromProdboardData(option: ProdboardCabinetOption): void {
    const options: { name: string; pos: string; value: string }[] = []
    if (option.value.code !== 'off') {
      Object.keys(option.value.doors_setting).forEach((key: string) => {
        options.push({
          name: key,
          pos: option.value.doors_setting[key].pos,
          value: option.value.doors_setting[key].type.code
        })

      })

      options.forEach((option: { name: string; pos: string; value: string }, index: number) => {
        const title: string = this.settingOption.getI18n(option.pos, 'c', 'sv')
        const viewOption: ViewOption = {
          type: ViewOptionType.select,
          title: title,
          name: `door${index}`,
          values: ['Ingen', 'Kryddhylla', 'Burkhylla', 'Combo'],
          selection: this.translation[option.value].sv,
          disabled: false
        }
        this.viewOptions.push(viewOption)
      })
    }
  }

  private setPrice(): void {
    this.resetPrices()
    const numRacks = this.viewOptions
      .filter((vo: ViewOption) => vo.selection !== SELECTION_NONE)
      .reduce((acc: number) => acc + 1, 0)
    this.price = this.product.shIdPr.price * numRacks
    this.labor = this.product.shIdPr.labor * numRacks

    if (numRacks > 0) {
      this.shouldHavePrice = true
    }
  }
}
