import {TLanguageCode} from '../application/i18n.provider'
import {TSettingOptionUseCase} from '../services/settings-item.service'
import {
  CabinetOption,
  TOptionSelectName,
  ViewOptionType
} from './cabinet-option'
import {IProduct} from '../common/interface/product-types'
import {ProdboardCabinetOption} from '../services/prodboard-types'

export class CarpenterJoy extends CabinetOption {
  public selectionOptions = {
    true: 'Ja',
    false: 'Nej'
  }

  constructor(option: ProdboardCabinetOption, product: IProduct, cabinetId: number) {
    super(option, product, cabinetId)
    this.priority = 10

    this.viewOptions = [
      {
        type: ViewOptionType.select,
        title: 'Vänster hörn',
        name: 'left',
        values: ['Ja', 'Nej'],
        selection: '',
        disabled: false
      },
      {
        type: ViewOptionType.select,
        title: 'Höger hörn',
        name: 'right',
        values: ['Ja', 'Nej'],
        selection: '',
        disabled: false
      }
    ]

    this.description = 'A special kind of "decoration" in the facade frame of some wall cabinets'
    this.title = 'optCarpenterJoy'
    const sOption = CarpenterJoy.sanitize(option)
    this.setValuesFromProdboardData(sOption)
    this.setPrice()
  }

  get optionSelectName(): TOptionSelectName {
    return 'CarpenterJoy'
  }

  private static sanitize(option: ProdboardCabinetOption): ProdboardCabinetOption {
    option.value = option.value || {code: 'Off', name: 'Off'} as any
    option.value.options = option.value.options || {} as any
    // eslint-disable-next-line @typescript-eslint/naming-convention
    option.value.options.frames = option.value.options.frames || {
      fr_left: false,
      fr_right: false
    } as any
    return option
  }

  public update(data: any): void {
    super.update(data)
    this.setFromProperties(data)
    this.setPrice()
  }

  /**
   * Quite special case since there are two Setting Option Values with the same
   * key 'Ja'. This should not happen, but I'm a bit afraid to change it (Darío)
   */
  public getCustomCustomerListing(
    useCase: TSettingOptionUseCase,
    lc: TLanguageCode
  ): string[] {
    // Both, left and right, are active
    if (this.viewOptions[0].selection === 'Ja' &&
      this.viewOptions[1].selection === 'Ja') {
      return [this.settingOption.getI18n('Båda', useCase, lc)]
    }

    // Just left is active, which is equivalent to first option value
    if (this.viewOptions[0].selection === 'Ja') {
      return [this.settingOption
        .getI18nFromValue(this.settingOption.values[0], useCase, lc)]
    }

    // Just right is active, which is equivalent to second option value
    return [this.settingOption
      .getI18nFromValue(this.settingOption.values[1], useCase, lc)]
  }

  private setValuesFromProdboardData(option: ProdboardCabinetOption): void {
    this.viewOptions[0].selection = 'Nej'
    this.viewOptions[1].selection = 'Nej'
    if (option.value.code !== 'Off') {
      this.viewOptions[0].selection = this.selectionOptions[option.value.options.frames.fr_left] || 'Nej'
      this.viewOptions[1].selection = this.selectionOptions[option.value.options.frames.fr_right] || 'Nej'
    }
  }

  /**
   * Called upon creation and after update
   */
  private setPrice(): void {
    this.active = false
    this.resetPrices()
    this.viewOptions.forEach((vo) => {
      if (vo.selection === 'Ja') {
        this.active = true
        this.price += this.product.cuBoCoPr.price
        this.labor += this.product.cuBoCoPr.labor
        this.shouldHavePrice = true
      }
    })
  }
}
