import {Injectable} from '@angular/core'
import {ProdboardItem} from '../services/prodboard-types'
import {ProductCategory} from '../services/product-static.service'
import {IProject} from '../services/project-types'
import {SettingsItemService} from '../services/settings-item.service'
import {ProdboardCabinet} from './cabinet/prodboard-cabinet'

@Injectable({
  providedIn: 'root'
})
export class ProdboardCabinetFactory {

  public createCabinet(
    settingsItemService: SettingsItemService,
    product: ProductCategory,
    input: ProdboardItem,
    project: IProject): ProdboardCabinet {
    return new ProdboardCabinet(settingsItemService, product, input, project)
  }
}
