import {TLanguageCode} from '../application/i18n.provider'
import {ProdboardCabinetOption} from '../services/prodboard-types'
import {IProduct} from '../common/interface/product-types'
import {TSettingOptionUseCase} from '../services/settings-item.service'
import {
  CabinetOption,
  TOptionSelectName,
  ViewOptionType
} from './cabinet-option'
import {
  EXTRACTOR_HOOD_CONCAVE_CONSOLE,
  EXTRACTOR_HOOD_CONVEX_CONSOLE,
  EXTRACTOR_HOOD_MIRROR,
  EXTRACTOR_HOOD_NO_CONSOLE,
  EXTRACTOR_HOOD_PLAIN,
  EXTRACTOR_HOOD_UNPAINTED
} from './model-types'

type TFESide = 'left' | 'right' | 'front'

const types = [EXTRACTOR_HOOD_PLAIN, EXTRACTOR_HOOD_UNPAINTED, EXTRACTOR_HOOD_MIRROR] as const

type TFEType = typeof types[number]

// Utan konsoll, med konsol konvex, med konsol konkav
type TFEConsoleCode = 'short' | 'long' | 'long_2'

const consoles = [EXTRACTOR_HOOD_CONVEX_CONSOLE, EXTRACTOR_HOOD_CONCAVE_CONSOLE, EXTRACTOR_HOOD_NO_CONSOLE] as const

type TFEConsole = typeof consoles[number]

interface IFanExtractorOption {
  type: TFEType
  side: TFESide
  console?: TFEConsole
}

const titleMap: Record<TFESide, string> = {
  left: 'optFanExtractorLeft',
  right: 'optFanExtractorRight',
  front: 'optFanExtractorFront'
}

export class FanExtractorPanel extends CabinetOption {
  private side: TFESide = 'front'

  constructor(
    option: ProdboardCabinetOption,
    product: IProduct,
    cabinetId: number
  ) {
    super(option, product, cabinetId)
    this.priority = 100
    this.active = true
    /**
     * The view options for this option. Here you must list all things that
     * can be selected/modified for an option.
     *
     * Based on this we will generate "Forms" higher up.
     */
    this.viewOptions = [
      {
        title: 'Panel type',
        type: ViewOptionType.select,
        name: 'panelType',
        values: [EXTRACTOR_HOOD_MIRROR, EXTRACTOR_HOOD_PLAIN, EXTRACTOR_HOOD_UNPAINTED],
        selection: EXTRACTOR_HOOD_UNPAINTED,
        disabled: false
      },
      {
        title: 'Console',
        type: ViewOptionType.select,
        name: 'consoleType',
        values: [EXTRACTOR_HOOD_NO_CONSOLE, EXTRACTOR_HOOD_CONCAVE_CONSOLE, EXTRACTOR_HOOD_CONVEX_CONSOLE],
        selection: EXTRACTOR_HOOD_NO_CONSOLE,
        disabled: false
      }
    ]
    this.description = 'Panel on Extractor Hood'

    const sOption = FanExtractorPanel.sanitize(option)
    this.side = sOption.side
    this.title = titleMap[this.side]
    this.setValuesFromProdboardData(sOption)
    this.setPrice()
  }

  get optionSelectName(): TOptionSelectName {
    return 'FanExtractorPanel'
  }

  /**
   * The sanitizer creates a default object.
   */
  private static sanitize(option: any): IFanExtractorOption {
    const side = FanExtractorPanel.getSide(option.name)
    let cons: TFEConsole | undefined
    if (option?.value?.options?.side_panels) {
      cons = FanExtractorPanel.getConsole(
        option?.value?.options?.side_panels[`${side}_side`].code)
    }
    return {
      side,
      type: FanExtractorPanel.getType(option?.value?.name || ''),
      console: cons ?? EXTRACTOR_HOOD_NO_CONSOLE
    }
  }

  private static getSide(name: string): TFESide {
    const sideMap = new Map<string, TFESide>([
      ['sida, vänster', 'left'],
      ['sida, höger', 'right'],
      ['sida, front', 'front']
    ])
    return FanExtractorPanel.tokenToItem<TFESide>(name, sideMap)
  }

  private static getType(type: string): TFEType {
    const typeMap = new Map<string, TFEType>([
      ['med spegel', EXTRACTOR_HOOD_MIRROR],
      ['omålad', EXTRACTOR_HOOD_UNPAINTED],
      ['slät', EXTRACTOR_HOOD_PLAIN]
    ])
    return FanExtractorPanel.tokenToItem<TFEType>(type, typeMap)
  }

  private static getConsole(type: TFEConsoleCode): TFEConsole {
    const typeMap = new Map<TFEConsoleCode, TFEConsole>([
      ['short', EXTRACTOR_HOOD_NO_CONSOLE],
      ['long', EXTRACTOR_HOOD_CONVEX_CONSOLE],
      ['long_2', EXTRACTOR_HOOD_CONCAVE_CONSOLE]
    ])
    return FanExtractorPanel.tokenToItem<TFEConsole>(type, typeMap)
  }

  private static tokenToItem<T>(token: string, map: Map<string, T>): T {
    return map.get(token.toLowerCase())
  }

  public update(data: any): void {
    super.update(data)
    this.setFromProperties(data)
    if (this.viewOptions[1]) {
      this.viewOptions[1].disabled = this.viewOptions[0].selection === EXTRACTOR_HOOD_UNPAINTED
      if (this.viewOptions[1].disabled) {
        this.viewOptions[1].selection = EXTRACTOR_HOOD_NO_CONSOLE
      }
    }
    this.setPrice()
  }

  public getCustomCustomerListing(
    useCase: TSettingOptionUseCase,
    lc: TLanguageCode
  ): string[] {
    let res = this.settingOption.getI18n(this.side, useCase, lc) + ' ' +
      this.settingOption.getI18n(this.viewOptions[0].selection, useCase, lc)
    if (this.side !== 'front') {
      res += ` ${this.settingOption.getI18n(this.viewOptions[1].selection, useCase, lc)}`
    }
    return [res]
  }

  /**
   * Each class implements its own logic for setting the price and.
   * possibly other options. All options should be available in the
   */
  private setValuesFromProdboardData(option: IFanExtractorOption): void {
    this.viewOptions[0].selection = option.type

    if (option.type === 'No coverside') {
      this.viewOptions[1].disabled = true
      this.viewOptions[1].selection = EXTRACTOR_HOOD_NO_CONSOLE
    }

    // Remove the coverside option for front.
    if (option.side !== 'front') {
      this.viewOptions[1].selection = option.console
    } else {
      this.viewOptions = [this.viewOptions[0]]
    }


  }

  /**
   * Called upon creation and after update
   */
  private setPrice(): void {
    this.resetPrices()

    if (this.viewOptions[0].selection === EXTRACTOR_HOOD_MIRROR) {
      this.price = this.product.csPr.price
      this.labor = this.product.csPr.labor
      this.shouldHavePrice = true
    }

    if (this.viewOptions[0].selection === EXTRACTOR_HOOD_PLAIN) {
      this.price = this.product.psPr.price
      this.labor = this.product.psPr.labor
      this.shouldHavePrice = true
    }

    if (this.viewOptions[1] && this.viewOptions[1].selection !== EXTRACTOR_HOOD_NO_CONSOLE) {
      this.labor += this.product.coDeEnPaPr.labor
      this.price += this.product.coDeEnPaPr.price
      // ADD to the cabinet price as this is for longer sides
      this.shouldHavePrice = true
    }
  }
}
