/**
 * Creates the Prodboard Cabinets based on the
 * provided prodboard export file and set additional
 * data on the Cabinet based on what has been saved
 * in the project.
 */
import {Injectable} from '@angular/core'
import {forkJoin, Observable, of} from 'rxjs'
import {map, switchMap, tap} from 'rxjs/operators'
import {
  ProdboardFile,
  ProdboardItem,
  ProdboardItemHolder,
  ProdboardSelectionItem
} from '../services/prodboard-types'
import {
  ProductCategory,
  ProductStaticService
} from '../services/product-static.service'
import {IProject} from '../services/project-types'
import {SettingsItemService} from '../services/settings-item.service'
import {ProdboardCabinet} from './cabinet/prodboard-cabinet'
import {ProdboardCabinetFactory} from './prodboard-cabinet-factory'

@Injectable({
  providedIn: 'root'
})

export class ProdboardFactory {

  constructor(
    private settingsItemService: SettingsItemService,
    private productStaticService: ProductStaticService,
    private cabinetFactory: ProdboardCabinetFactory
  ) {
  }

  private static getCodeFromProdboardItem(item: ProdboardItem): string {
    const i = item.items
      .find((itm: ProdboardSelectionItem) =>
        itm.code === 'cabinet' || itm.modificator?.startsWith('ONdEx'))
    if (!i) {
      return '' // Counts as falsy and will be removed
    }
    return i.modificator
  }

  /**
   * This is only called by the Project Service. Only the project service knows
   * when we have a new fancy prodboard file to process.
   *
   * @param file - A prodboard file
   * @param project
   */
  public createCabinets(file: ProdboardFile, project: IProject):
    Observable<ProdboardCabinet[]> {
    // Distill a list of unique product codes
    const codes = this.getAllProductCodes(file)
    // Fork Join 'em, the pipe is activated when all are done.
    // Finally, we will return a list of cabinets, full of fancy
    // options and such.
    // We do the first one first to make sure all products are loaded before
    // we continue with all.
    const firstCode$ = this.productStaticService
      .getPriceLockVersion(codes.shift(), project.priceLockTime)
    return firstCode$.pipe(
      switchMap((f: ProductCategory) => {
        const codes$ = codes.map((code: string) =>
          this.productStaticService
            .getPriceLockVersion(code, project.priceLockTime))
        codes$.push(of(f))
        return forkJoin(codes$)
      }),
      map((res: ProductCategory[]) => {

        // Create a map that we can use for easy retrieval
        const products =
          new Map(res.map(i => [i.pc, i]))
        return file.plan.items
          .map((cab: ProdboardItemHolder) => {
            const code = ProdboardFactory
              .getCodeFromProdboardItem(cab.item)
            // If we have received stuff from Prodboard that we cannot cover
            // for. This should be recorded in the service that have already
            // called the problem service.
            if (!products.get(code)) {
              return
            }
            // This is immediate, no calls to the internet or so,
            // all data is available.
            return this.cabinetFactory.createCabinet(
              this.settingsItemService,
              products.get(code),
              cab.item,
              project)
          })
          .filter(Boolean) // Remove the nulls/undefined that is the ones
        // from the return undefined above
      }),
      tap((cabinets: ProdboardCabinet[]) => {
        // New trickery here :) For each cabinet we iterate all layouts
        // over all layouts for som nice On^2 complexity! Introduced for
        // layout (neighbors) but can be generalized another day for
        // other cross cabinet things.
        cabinets.forEach(c =>
          cabinets.forEach(cc => c.layout.compare(cc.layout)))
      })
    )
  }

  private getAllProductCodes(f: ProdboardFile): string[] {
    const prodboardCodes = f.plan.items.map((item: ProdboardItemHolder) =>
      ProdboardFactory.getCodeFromProdboardItem(item.item))
    return [...new Set(prodboardCodes)].filter(i => i)
  }
}
