import {ProjectPricingUtils} from './project-pricing.utils'

/**
 * Ratio at which installation costs will be calculated. For example, if price
 * is 100, costs will be 55. This is an arrangement between KDL and contractors.
 */
export const INSTALLATION_COSTS_RATIO = 0.55

/**
 * Different type of project items that can exist as constant array.
 * 'c' - Cabinets
 * 'a' - Appliances
 * 'ctm' - Counter Tops (make)
 * 'ctb' - Counter Tops (buy)
 * 'fe' - Factory Extras
 */
export const ProjectPricingItemTypes = ['c', 'a', 'ctm', 'ctb', 'fe'] as const
/**
 * Different type of project items that can exist.
 * 'c' - Cabinets
 * 'a' - Appliances
 * 'ctm' - Counter Tops (make)
 * 'ctb' - Counter Tops (buy)
 * 'fe' - Factory Extras
 */
export type TProjectPricingItemType = typeof ProjectPricingItemTypes[number]

/**
 * Different type of services that can exist as constant array.
 */
export const AssemblyServices = [
  'startFee',
  'volume',
  'cabinetCount',
  'wallCabinets',
  'shelves',
  'dishWasher',
  'sink',
  'fridgeFreezer',
  'wineFridge',
  'oven',
  'micro',
  'ovenMicro',
  'tumbleDryer',
  'washingMachine',
  'fanAdoption',
  'pantry',
  'coverSide',
  'overSize',
  'spots',
  'leds',
  'counterTopArea',
  'counterTopCount',
  'carryStartFee',
  'carry',
  'wasteStartFee',
  'waste',
  'measure'
] as const
/**
 * Different type of services that can exist.
 */
export type TAssemblyService = typeof AssemblyServices[number]

/**
 * Different type of prices that can exist as constant array.
 */
export const PriceTypes = ['customer', 'factory', 'costs'] as const
/**
 * Different type of prices that can exist.
 */
export type TPriceType = typeof PriceTypes[number]
/**
 * Type to be inherited by all "Price" classes that will oblige them to
 * implement all price types. We do this, so we never forget to add them.
 */
export type TPriceTypes = { [key in TPriceType]: number }

/**
 * Creation interface for {@link BaseItemPrice}
 */
interface IBaseItemPrice {
  /**
   * ID of the associated item, it could be a cabinet ID, an appliance ID,
   * a cabinet option name (which are unique per cabinet) or comment ID.
   */
  id: string
  /**
   * Representation of item in a more friendly name, like "Cabinet 1".
   */
  name: string
  /**
   * Customer price per quantity in SEK.
   * It should always be greater than 0. Nothing is free here :)
   */
  perQuantityCustomer: number
  /**
   * Factory price per quantity in €.
   * It is 0 by default.
   */
  perQuantityFactory?: number
  /**
   * Costs per quantity associated to item in SEK.
   * Mostly these costs refer to material costs like wood, painting, etc.
   * But in some cases it could refer to contractor labor cost, or other stuff.
   * Basically, money that KDL pays to someone else.
   * They are 0 by default.
   */
  perQuantityCosts?: number
  /**
   * Amount of items of the same type, for example, two dishwashers.
   * It can also make reference to areas or volumes, like counter top square
   * meters or cabinets cubic meters.
   * It is 1 by default, so it will not modify any price: X * 1 = X.
   */
  quantity?: number
  /**
   * Percentage of discount that an item can have. It exists in items like
   * Appliances or Counter tops (other).
   * It is 0 by default, so it will not modify any price: X - 0% = X.
   */
  discount?: number
  /**
   * Manual customer price that will override its "perQuantity" price.
   */
  manualCustomer?: number
  /**
   * Manual factory price that will override its "perQuantity" price.
   */
  manualFactory?: number
  /**
   * Manual costs price that will override its "perQuantity" price.
   */
  manualCosts?: number
}

/**
 * Generic class that {@link ItemPrice}, {@link OptionPrice} and
 * {@link CommentPrice} will implement.
 */
export abstract class BaseItemPrice {
  /**
   * ID of the associated item, it could be a cabinet ID, an appliance ID,
   * a cabinet option name (which are unique per cabinet) or comment ID.
   */
  id: string
  /**
   * Representation of item in a more friendly name, like "Cabinet 1".
   */
  name: string
  /**
   * Customer price per quantity in SEK.
   * It should always be greater than 0. Nothing is free here :)
   */
  perQuantityCustomer: number
  /**
   * Factory price per quantity in €.
   * It is 0 by default.
   */
  perQuantityFactory: number = 0
  /**
   * Costs per quantity associated to item in SEK.
   * Mostly these costs refer to material costs like wood, painting, etc.
   * But in some cases it could refer to contractor labor cost, or other stuff.
   * Basically, money that KDL pays to someone else.
   * They are 0 by default.
   */
  perQuantityCosts: number = 0
  /**
   * Amount of items of the same type, for example, two dishwashers.
   * It can also make reference to areas or volumes, like counter top square
   * meters or cabinets cubic meters.
   * It is 1 by default, so it will not modify any price: X * 1 = X.
   */
  quantity: number = 1
  /**
   * Percentage of discount that an item can have. It exists in items like
   * Appliances or Counter tops (buy).
   * It is 0 by default, so it will not modify any price: X - 0% = X.
   */
  discount: number = 0
  /**
   * Manual customer price that will override its "perQuantity" price.
   * It doesn't exist by default, undefined.
   */
  manualCustomer?: number
  /**
   * Manual factory price that will override its "perQuantity" price.
   * It doesn't exist by default, undefined.
   */
  manualFactory?: number
  /**
   * Manual costs price that will override its "perQuantity" price.
   * It doesn't exist by default, undefined.
   */
  manualCosts?: number

  protected constructor(obj: IBaseItemPrice) {
    this.id = obj.id
    this.name = obj.name
    this.perQuantityCustomer = obj.perQuantityCustomer
    this.perQuantityFactory = obj.perQuantityFactory ?? 0
    this.perQuantityCosts = obj.perQuantityCosts ?? 0
    this.quantity = obj.quantity ?? 1
    this.discount = obj.discount ?? 0
    this.manualCustomer = obj.manualCustomer
    this.manualFactory = obj.manualFactory
    this.manualCosts = obj.manualCosts
  }

  /**
   * Check if Price is valid:
   *  - At least one of the prices, customer, factory or costs is !== 0
   *  - At least some array of BaseItemPrice is not empty (a subsection)
   */
  public get isValid(): boolean {
    return PriceTypes.some(type =>
        this.getPriceType(type) !== 0) ||
      Object.keys(this).some(key =>
        Array.isArray(this[key]) &&
        this[key].length > 0 &&
        this[key][0] instanceof BaseItemPrice)
  }

  public getPriceType(priceType: TPriceType): number {
    const priceTypeCapitalised = priceType[0].toUpperCase() +
      (priceType as string).slice(1).toLowerCase()
    return this[`manual${priceTypeCapitalised}`] ??
      this[`perQuantity${priceTypeCapitalised}`]
      * this.quantity *
      // Discount is only available for Customer price
      (priceType !== 'customer' ? 1 : ((100 - this.discount) / 100))
  }

  public get priceTypes(): TPriceTypes {
    return {
      customer: this.getPriceType('customer'),
      factory: this.getPriceType('factory'),
      costs: this.getPriceType('costs')
    }
  }
}

/**
 * Price object that will come from entity comments, like Cabinets or
 * Appliances.
 */
export class CommentPrice extends BaseItemPrice {
  constructor(obj: IBaseItemPrice) {
    super(obj)
  }
}

/**
 * Price object that will come from entities that are considered options, for
 * example, all cabinets have doors, lights and others.
 * Appliances can have installation as option, and Counter tops (ours) can
 * have plinth as option
 */
export class OptionPrice extends BaseItemPrice {
  /**
   * Associated option comments. For example, every CabinetOption can have
   * comments. They are not mandatory, but they exist.
   * By default, it is an empty array.
   */
  comments: CommentPrice[] = []
  /**
   * Services that an option can have. Most commonly, installation services
   * By default, it is an empty array.
   */
  services: AssemblyPrice[] = []

  constructor(obj: IBaseItemPrice) {
    super(obj)
  }
}

/**
 * Price object that will come form all project sub-entities that involve
 * some pricing. These are cabinets, appliances, counter tops (ours and others)
 * and factory extras.
 */
export class ItemPrice extends BaseItemPrice {
  /**
   * Type of item that this price object is. This is the way we have to identify
   * every "class" of price items, like cabinets, appliances, etc.
   */
  type: TProjectPricingItemType
  /**
   * Associated item comments. For example, every appliance can have
   * comments. They are not mandatory, but they exist.
   * By default, it is an empty array.
   */
  comments: CommentPrice[] = []
  /**
   * Associated options. For example, every Cabinet has CabinetOption.
   * They are not mandatory, but they exist.
   * By default, it is an empty array.
   */
  options: OptionPrice[] = []
  /**
   * Services that an option can have. Most commonly, installation services
   * By default, it is an empty array.
   */
  services: AssemblyPrice[] = []
  /**
   * Some special options for Cabinets, like "higherThanStandard" or
   * "deeperThanStandard"
   */
  specialOptions: OptionPrice[] = []

  constructor(type: TProjectPricingItemType, obj: IBaseItemPrice) {
    super(obj)
    this.type = type
  }
}

/**
 * Creation interface for {@link AssemblyPrice}
 */
export interface IAssemblyPrice {
  /**
   * Service type, which will be used to identify the service and be able
   * to count objects of same type. For example, count of counter tops.
   */
  type: TAssemblyService
  /**
   * Customer price per quantity in SEK.
   * It should always be greater than 0. Nothing is free here :)
   */
  perQuantityCustomer: number
  /**
   * Unit, area or volume that this service has.
   * It is 1 by default, so it will not modify any price: X * 1 = X.
   */
  quantity?: number
}

/**
 * Price object that will come from hired services, like carrying, de-packaging,
 * measuring or any cabinet/cabinet-option installation.
 * Check {@link TAssemblyService} to see all possible services.
 */
export class AssemblyPrice extends BaseItemPrice {
  /**
   * Service type, which will be used to identify the service and be able
   * to count objects of same type. For example, count of counter tops.
   */
  type: TAssemblyService

  constructor(obj: IAssemblyPrice) {
    super({...obj, id: obj.type, name: obj.type})
    this.type = obj.type
    // Services don't have factory prices
    this.perQuantityFactory = 0
    // Costs are auto-calculated for Services
    this.perQuantityCosts = this.perQuantityCustomer * INSTALLATION_COSTS_RATIO
  }
}

/**
 * Object that every project will have (calculated on frontend) once they
 * are opened (not in home grid view). It will hold every price option that a
 * project can have, from basic cabinet prices, to every comment in every option
 * of appliances. It should be used to display any type of pricing info.
 */
export class ProjectPricing {
  /**
   * Numeric adjustments that a Mill user (KDL employee) can make to a project.
   * They could be either positive or negative, and they are considered as SEK.
   * They're used for quick adjustments in the project's final price.
   * It's an empty array by default
   */
  adjustments: number[] = []
  /**
   * Flat that marks if assembly is active/hired in this project. If it's
   * not, the all AssemblyPrices must be ignored when doing any calculation.
   */
  isAssemblyActive: boolean = true
  /**
   * All assembly prices. These services include measuring, carrying
   * and de-packaging.
   * It is an empty array by default.
   */
  services: AssemblyPrice[] = []
  /**
   * All project item prices. These items can be cabinets, appliances,
   * counter tops (ours), counter tops (others) or factory extras.
   * It is an empty array by default.
   */
  items: ItemPrice[] = []

  /**
   * Calculated customer price with no adjustment.
   * Sum of "customer" of ALL BaseItemPrice items inside ProjectPrice.
   */
  public originalCustomerPrice: number = 0
  /**
   * Calculated total customer price, adjustment included.
   * Sum of "customer" of ALL BaseItemPrice items inside ProjectPrice
   * plus "adjustment".
   */
  public totalCustomerPrice: number = 0
  /**
   * Calculated total factory price.
   * Sum of "factory" of ALL BaseItemPrice items inside ProjectPrice.
   */
  public totalFactoryPrice: number = 0
  /**
   * Calculated total costs price.
   * Sum of "costs" of ALL BaseItemPrice items inside ProjectPrice.
   */
  public totalCostsPrice: number = 0
  /**
   * Calculated base customer price.
   * Sum of all Cabinets (ItemPrice of type 'c') and their CabinetOptions
   * (OptionPrice inside every ItemPrice of type 'c').
   */
  public baseCustomerPrice: number = 0
  /**
   * Calculated base comments customer price.
   * Sum of all Cabinet comments (CommentPrice inside every ItemPrice of
   * type 'c') and CabinetOption comments (CommentPrice inside every
   * OptionPrice inside every ItemPrice of type 'c').
   */
  public baseCommentsCustomerPrice: number = 0
  /**
   * Calculated assembly/installation customer price.
   * Sum of all AssemblyPrice inside ProjectPrice: they are present in
   * this "services", "items" -> "services", "items" -> "options" -> "services".
   */
  public assemblyCustomerPrice: number = 0
  /**
   * Calculated international fee, which is the 10% of originalCustomerPrice.
   * Sum of all adjustment prices in {@link adjustments}.
   */
  public internationalFee: number = 0
  /**
   * Calculated adjustment customer price.
   * Sum of all adjustment prices in {@link adjustments}.
   */
  public totalAdjustmentPrice: number = 0

  constructor(
    adjustments: number[],
    isAssemblyActive: boolean,
    isOutsideSweden: boolean,
    assemblyServices: AssemblyPrice[],
    items: ItemPrice[]
  ) {
    this.adjustments = adjustments
    this.isAssemblyActive = isAssemblyActive
    this.services = assemblyServices
    this.items = items

    // We calculate some values here. Since this entity is not modified once
    // it is created, all calculated values will not change.
    // So we calculate them now, only once and not more.
    this.originalCustomerPrice = this.calculateTotalCustomerPrice()
    this.internationalFee = isOutsideSweden ?
      this.originalCustomerPrice * 0.1 : 0
    this.totalAdjustmentPrice = this.calculateTotalAdjustmentPrice()
    this.totalCustomerPrice =
      this.originalCustomerPrice +
      this.totalAdjustmentPrice +
      this.internationalFee
    this.totalFactoryPrice = this.calculateTotalFactoryPrice()
    this.totalCostsPrice = this.calculateTotalCostsPrice()
    this.baseCustomerPrice = this.calculateBaseCustomerPrice()
    this.baseCommentsCustomerPrice = this.calculateBaseCommentsCustomerPrice()
    this.assemblyCustomerPrice = this.calculateAssemblyCustomerPrice()
  }

  public itemsByType(type: TProjectPricingItemType): ItemPrice[] {
    return this.items.filter(i => i.type === type)
  }

  private calculateTotalCustomerPrice(): number {
    return this.calculateTotalPriceByPriceType('customer')
  }

  private calculateTotalFactoryPrice(): number {
    return this.calculateTotalPriceByPriceType('factory')
  }

  private calculateTotalCostsPrice(): number {
    return this.calculateTotalPriceByPriceType('costs')
  }

  private calculateTotalAdjustmentPrice(): number {
    return this.adjustments.reduce((acc, a) => a + acc, 0)
  }

  private calculateTotalPriceByPriceType(priceType: TPriceType): number {
    let total = 0

    // Sum all Items
    total += ProjectPricingUtils.calculatePriceTypeOfItems(
      this.items,
      'item',
      priceType
    )
    // Sum all Options
    total += ProjectPricingUtils.calculatePriceTypeOfItems(
      this.items,
      'option',
      priceType
    )
    // Sum all Comments
    total += ProjectPricingUtils.calculatePriceTypeOfItems(
      this.items,
      'comment',
      priceType
    )
    // If assembly services are not active, then we don't add AssemblyPrices
    if (this.isAssemblyActive) {
      total += ProjectPricingUtils.calculatePriceTypeOfItems(
        [...this.services, ...this.items],
        'service',
        priceType
      )
    }
    return total
  }

  private calculateBaseCustomerPrice(): number {
    return ProjectPricingUtils.calculatePriceTypeOfItemAndSubitems(
      this.itemsByType('c'),
      'customer',
      ['services', 'comments']
    )
  }

  private calculateBaseCommentsCustomerPrice(): number {
    return ProjectPricingUtils.calculatePriceTypeOfItems(
      this.itemsByType('c'),
      'comment',
      'customer'
    )
  }

  private calculateAssemblyCustomerPrice(): number {
    return !this.isAssemblyActive ? 0 :
      ProjectPricingUtils.calculatePriceTypeOfItems(
        [...this.services, ...this.items],
        'service',
        'customer'
      )
  }
}
