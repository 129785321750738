import {HttpClient} from '@angular/common/http'
import {Injectable} from '@angular/core'
import {BehaviorSubject, Observable} from 'rxjs'
import {map, switchMap, tap} from 'rxjs/operators'
import {environment} from '../../environments/environment'
import {TLanguageCode, TTranslationItem} from '../application/i18n.provider'
import {CabinetOption, TOptionSelectName} from '../model/cabinet-option'
import {getDefaultSettingItems} from './override.defaults'

export type TConfigItemName =
  'CAD'
  | 'PAINTER'
  | 'COLOR'
  | 'CARPENTRY'
  | 'SUPPLIER'

/**
 * Different types of settings items that we have
 * n - Name/Option item - Used in Mill > Settings > Cabinet Options
 * c - Configuration item - Used in Mill > Settings > Configuration
 */
export type TSettingsItemType = 'n' | 'c'

/**
 * We are reusing the same base model for many items in Mill > Settings.
 * For example, "Cabinet Options" and "Configuration" tabs.
 * Both uses the same base item. Hierarchy looks like:
 *  Option:  Item > Option > Value > UseCase > Translation
 *  Config:   Item > Config > Value/Supplier
 * We differentiate them with "t", which is short for "type".
 */
export class SettingsBaseItem {
  /**
   * t for Type, short to save bandwidth.
   */
  t: TSettingsItemType
  /**
   * Values that will be inside the item. They will be overridden.
   */
  values: (ISettingsOptionValue | ISettingsConfigValue | ISettingsConfigSupplier)[]
  /**
   * Name of the settings item. For example, PaintProcess (option) or
   * Painters (configuration)
   */
  name: TOptionSelectName | TConfigItemName

  constructor(obj: Partial<SettingsBaseItem>) {
    Object.assign(this, obj)

    // Make sure that BE data is always overridden with obj data
    this.id = obj.id
    this.version = obj.version
    this.type = 'OPT'
  }

  //////////////////////////////////////////////////////
  // Retro-compatibility stuff. It will be removed
  //////////////////////////////////////////////////////
  /**
   * Possible options for the item.
   * @Deprecated
   */
  options: any[]

  //////////////////////////////////////////////////////
  // BE needed data that we don't use in FE
  //////////////////////////////////////////////////////
  /**
   * Kulladal DB works with one big collection and a "type" value to differ
   * the different objects inside it.
   * For this specific case, type will always be "OPT". Short for "Option".
   */
  readonly type = 'OPT' as const
  /**
   * Parameter to index in DB
   */
  id?: string
  /**
   * Database version of the item
   */
  version?: number
}


////////////////////////////////////////////////////////////////////////
// Settings Options
////////////////////////////////////////////////////////////////////////

/**
 * Options that can be found inside "Settings > Cabinet Options". Each option
 * represents an expandable menu.
 */
export class SettingsOption extends SettingsBaseItem {
  /**
   * Name or title to be displayed in the expandable menu.
   */
  name: TOptionSelectName
  /**
   * All possible values of the option. They represent each row of inside
   * Mill > Settings > Cabinet Options > name_of_option.
   */
  values: ISettingsOptionValue[]

  constructor(obj: Partial<SettingsOption>) {
    super({t: 'n'})
    Object.assign(this, obj)
  }

  public getI18n(
    valueKey: string,
    useCase: TSettingOptionUseCase,
    lc: TLanguageCode
  ): string {
    // First get Option Value, which is identified by "key"
    const optionValue: ISettingsOptionValue = this.values
      .find(v => v.key === valueKey)
    // Then get the i18n from the collected value
    return this.getI18nFromValue(optionValue, useCase, lc)
  }

  public getI18nFromValue(
    optionValue: ISettingsOptionValue,
    useCase: TSettingOptionUseCase,
    lc: TLanguageCode
  ): string {
    try {
      // Then get UseCase, which is identified by "type"
      const valueUseCase: ISettingsOptionUseCase = optionValue.ucs
        .find(uc => uc.type === useCase)
      // Lastly, get translation text (i18n) from UseCase.
      // As special case, the use case can copy the texts from other use case.
      return valueUseCase.copy ?
        optionValue.ucs
          .find(uc => uc.type === valueUseCase.copy)
          .i18n[lc] :
        valueUseCase.i18n[lc]
    } catch (e) {
      return `OBS! i18n not defined for SettingOptionValue "${this.name}" 
      - OptionValue "${optionValue}" 
      - UseCase "${useCase}" 
      - Lang "${lc}"`
    }
  }
}

/**
 * Value that a Settings Option can have. It represents a row inside
 * "Settings > Cabinet Options > name_of_option" expandable menu.
 */
export interface ISettingsOptionValue {
  /**
   * Identifier of an option "row".
   */
  key: string
  /**
   * Short for "Use Cases". All possible use cases of the value.
   */
  ucs: ISettingsOptionUseCase[]
}

export const OptionUseCases = ['c', 'f', 'i'] as const
/**
 * c - Customer - Texts to be used in Customer spec view
 *
 * f - Factory/Carpentry - Texts to be used in Factory spec view
 *
 * i - Internal - Texts to be used in the rest of Mill. Selectors, checkboxes,
 * etc.
 *
 * Do not forget to add an i18n translation as "optionUseCase_<type>"
 */
export type TSettingOptionUseCase = typeof OptionUseCases[number]

/**
 * Use cases for a Settings Option Value. For example, the option's value can
 * be used inside the Customer spec and Factory spec with different texts,
 * this means that they have different use cases.
 * Possible use cases are defined in {@link TSettingOptionUseCase}.
 */
export interface ISettingsOptionUseCase {
  /**
   * Use Case that is using the texts.
   *  'c' - Customer will have support in all languages
   *  'f' - Factory will only have support in English
   *  'i' - Internal will only have support in English
   */
  type: TSettingOptionUseCase
  /**
   * All text translations for every use case. For example, English and Swedish.
   * If copy is set, this will be undefined.
   */
  i18n?: TTranslationItem
  /**
   * Sometimes use cases are repeated. In those cases, we can set which use-case
   * it is copying from.
   */
  copy?: TSettingOptionUseCase
}


////////////////////////////////////////////////////////////////////////
// Settings Configurations
////////////////////////////////////////////////////////////////////////

/**
 * Settings configuration value. They can be found in
 * "Settings > Configuration". Every configuration refers to an expandable
 * menu inside this tab.
 */
export class SettingsConfig extends SettingsBaseItem {
  /**
   * Name of the config, which is shown as title of the expandable menu.
   */
  name: TConfigItemName
  /**
   * All possible values of the config.
   */
  values: (ISettingsConfigValue | ISettingsConfigSupplier)[] = []

  constructor(obj: Partial<SettingsConfig>) {
    super({t: 'c'})
    Object.assign(this, obj)
  }

  get configValues(): ISettingsConfigValue[] {
    // Sort values by DisplayValue (dv)
    (this.values as ISettingsConfigValue[])
      .sort((a, b) =>
        a.dv.localeCompare(b.dv))
    return this.values as ISettingsConfigValue[]
  }

  get suppliers(): ISettingsConfigSupplier[] {
    // Sort suppliers by name
    (this.values as ISettingsConfigSupplier[])
      .sort((a, b) =>
        a.name.localeCompare(b.name))
    return this.values as ISettingsConfigSupplier[]
  }
}

/**
 * Settings configuration value. They can be found in
 * "Settings > Configuration > name_of_config". Every value is a new row inside
 * the expandable item.
 */
export interface ISettingsConfigValue {
  /**
   * ID of value
   */
  id: string
  /**
   * Display value
   */
  dv: string
}

/**
 * 'other' - Default supplier type
 * 'carpentry' - Those suppliers that are a carpentry.
 * 'ct' - Counter Top suppliers.
 *
 * Do not forget to add an i18n translation as "supplierType_<type>"
 */
export const SettingConfigSupplierTypes = ['other', 'carpentry', 'ct'] as const
export type TSettingConfigSupplierType = typeof SettingConfigSupplierTypes[number]

/**
 * Slight modification of {@link ISettingsConfigValue} only used for Suppliers,
 * which need more configuration.
 */
export interface ISettingsConfigSupplier {
  /**
   * Supplier's name, the one that will be displayed in lists and selectors.
   */
  name: string
  /**
   * Supplier's email or Slack channel
   */
  email: string
  /**
   * Type of supplier.
   */
  type: TSettingConfigSupplierType
  /**
   * KDLs discount at the supplier. It is considered as %.
   */
  discount: number
}

@Injectable({
  providedIn: 'root'
})
export class SettingsItemService {
  public settingItems$: Observable<SettingsBaseItem[]>
  private pSettingItems$: BehaviorSubject<SettingsBaseItem[]> =
    new BehaviorSubject<SettingsBaseItem[]>([])

  /**
   * This is a map for easy direct access to all setting items.
   * It maps, name with item.
   */
  private settingItemsMap =
    new Map<string, SettingsBaseItem>

  constructor(private httpClient: HttpClient) {
    // Initialise our subject with default values. They will be overridden
    // later with recovered values from API. This way we prevent not showing
    // texts if slow connection or similar.
    this.pSettingItems$.next(getDefaultSettingItems())
    this.settingItems$ = this.pSettingItems$.asObservable()

    // Everytime the setting items are modified, we update a map for easy access
    this.settingItems$
      .subscribe((items) => {
        items.forEach(item =>
          this.settingItemsMap.set(item.name, item))
      })
  }

  private overrideSettingItems(serverItems: SettingsBaseItem[]): SettingsBaseItem[] {
    return this.pSettingItems$.value
      .map(defaultItem => {
        // We create a copy of defaultItem to work with, to avoid further errors.
        const mergedItem =
          new SettingsBaseItem(JSON.parse(JSON.stringify(defaultItem)))

        // Try finding an overriding item from received server items
        const serverItem =
          serverItems.find(i => i.name === defaultItem.name)
        // If none is found, it will use the default item without modifications
        if (!serverItem) {
          return mergedItem
        }

        // Always set ID and version from server item
        mergedItem.id = serverItem.id
        mergedItem.version = serverItem.version

        // If the item is an option, we replace values with server values
        if (mergedItem.t === 'n') {
          // Set all values from server that still exist
          mergedItem.values.forEach((value: ISettingsOptionValue) => {
            const serverValue = serverItem.values
              ?.find((val: ISettingsOptionValue) =>
                value.key === val.key) || {}
            Object.assign(value, serverValue)
          })
        }

        // In case of configuration items, we will replace all values.
        if (serverItem.t === 'c') {
          mergedItem.values = serverItem.values
        }

        // Return item with default and server values merged
        return mergedItem
      })
      // Make sure that all objects are transformed into classes
      .map(o => {
        if (o.t === 'n') {
          return new SettingsOption(o as SettingsOption)
        } else {
          return new SettingsConfig(o as SettingsConfig)
        }
      })
  }

  public bootstrap(): Observable<any> {
    const url = `${environment.productUrl}/options`
    return this.httpClient.get(url).pipe(
      tap((items: SettingsBaseItem[]) => {
        /**
         * We have the defaults, we iterate over those if we find the same
         * option among the server values we use the server value. However,
         * we also add missing keys from the hard coded value to the server
         * value in case we have changed the default.
         */
        const mergedItems = this.overrideSettingItems(items)

        // Update subject with the merged items. It will re-do the items map.
        this.pSettingItems$.next(mergedItems)
      })
    )
  }

  /**
   * We are applying options to a Cabinet, so we will be using
   * only Setting Items of type 'n', Setting Options.
   * @param cabinetOption
   */
  public updateCabinetOption(cabinetOption: CabinetOption): void {
    cabinetOption.setSettingOption(
      this.getSettingOption(cabinetOption.optionSelectName))
  }

  /**
   * Returns Setting Option item based on name
   */
  public getSettingOption(optionName: TOptionSelectName): SettingsOption {
    return new SettingsOption(this.settingItemsMap.get(optionName) as SettingsOption)
  }

  /**
   * Returns Setting Config item based on name
   */
  public getSettingConfig(configName: TConfigItemName): SettingsConfig {
    return new SettingsConfig(this.settingItemsMap.get(configName) as SettingsConfig)
  }

  /**
   * Returns Setting Config item based on name as a Map.
   * Map key-value pairs are ConfigValue's ID and "Display Value" (dv).
   * Not valid for "SUPPLIER" config item.
   */
  public getSettingConfigAsMap(configName: Exclude<TConfigItemName, 'SUPPLIER'>): Map<string, string> {
    return new Map(
      new SettingsConfig(this.settingItemsMap.get(configName) as SettingsConfig)
        .configValues.map(v => [v.id, v.dv])
    )
  }

  /**
   * Saves a Settings Item, which can be an Option or Configuration, into DB.
   * By doing this, default items in override.defaults.ts will be overridden
   * with the saved ones.
   * This helps the users to customise their own texts and stuff like that.
   * @param item
   */
  public saveSettingsItem(item: SettingsBaseItem): Observable<SettingsBaseItem> {
    const id = item.id ? `/${item.id}` : ''

    const url = `${environment.productUrl}/options${id}`
    return this.httpClient.put<SettingsBaseItem>(url, item).pipe(
      switchMap(() => this.bootstrap()),
      map(() => this.settingItemsMap.get(item.name))
    )
  }
}
