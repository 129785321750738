import {TLanguageCode} from '../application/i18n.provider'
import {IProduct} from '../common/interface/product-types'
import {ProdboardCabinetOption} from '../services/prodboard-types'
import {TSettingOptionUseCase} from '../services/settings-item.service'
import {
  CabinetOption,
  TOptionSelectName,
  ViewOptionType
} from './cabinet-option'
import {CoverSide} from './cover-side'

export const SCRIBING_OPTION_KEY_NONE = 'Ingen överdimensionering'

export class Scribings extends CabinetOption {
  constructor(option: ProdboardCabinetOption, product: IProduct, cabinetId: number) {
    super(option, product, cabinetId)
    this.priority = 1
    this.active = true
    this.viewOptions = [
      {
        type: ViewOptionType.select,
        title: 'Överdimensionering',
        name: 'scribings',
        // HEADS UP. If you change the values the customer print may break it depends on "Både"
        // 'cabinet-info-header.component.ts
        values: [SCRIBING_OPTION_KEY_NONE, 'Vänster', 'Höger', 'Både höger och vänster'],
        selection: SCRIBING_OPTION_KEY_NONE,
        disabled: false
      }
    ]
    this.description = 'Some cabinets are over-sized - a kind of built-in margin to make it easier to fit on location'
    this.title = 'optScribings'

    const sOption = Scribings.sanitize(option)
    this.setValuesFromProdboardData(sOption)
  }

  get optionSelectName(): TOptionSelectName {
    return 'Scribings'
  }

  private static sanitize(option: any): any {
    option.value = option.value || {}
    option.value.options = option.value.options || {}
    option.value.options.admin_frames = option.value.options.admin_frames || {}
    return option
  }

  public update(data: any): void {
    super.update(data)
    this.setFromProperties(data)
    this.active = data.scribings !== SCRIBING_OPTION_KEY_NONE
  }

  public valueMap(): Record<string, string | number | boolean> {
    return {
      scribings: ['', 'left', 'right', 'both'][this.viewOptions[0].values.indexOf(this.viewOptions[0].selection)]
    }
  }

  public checkIllegal(side: CoverSide): boolean {
    if (side.valueMap().side === this.valueMap().scribings || this.valueMap().scribings === 'both') {
      return true
    }
  }

  public getCustomCustomerListing(
    useCase: TSettingOptionUseCase,
    lc: TLanguageCode
  ): string[] {
    return [this.settingOption.getI18n(this.viewOptions[0].selection, useCase, lc)]
  }

  private setValuesFromProdboardData(option: any): void {
    const left = !!option.value.options.admin_frames.left
    const right = !!option.value.options.admin_frames.right
    if (left) {
      this.viewOptions[0].selection = 'Vänster'
    }
    if (right) {
      this.viewOptions[0].selection = 'Höger'
    }
    if (left && right) {
      this.viewOptions[0].selection = 'Både höger och vänster'
    }
    this.active = this.viewOptions[0].selection !== SCRIBING_OPTION_KEY_NONE
  }
}
