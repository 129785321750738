import {TLanguageCode} from '../application/i18n.provider'
import {IProduct} from '../common/interface/product-types'
import {TSettingOptionUseCase} from '../services/settings-item.service'
import {
  CabinetOption,
  TOptionSelectName,
  ViewOption,
  ViewOptionType
} from './cabinet-option'
import {CARDINALITY} from '../common/interface/helpers'
import {ProdboardCabinetOption} from '../services/prodboard-types'

interface IOptionData {
  property: 'cuDiPr' | 'knBlPr' | 'waBlPr'
  receiptText: string
}

export class DrawerInsert extends CabinetOption {
  constructor(option: ProdboardCabinetOption, product: IProduct, cabinetId: number, optionsList: ProdboardCabinetOption[]) {
    super(option, product, cabinetId)

    this.priority = 10
    this.viewOptions = [
      {
        type: ViewOptionType.select,
        title: 'Bestickinsats',
        name: 'cutleryDiv',
        values: ['0', '1', '2', '3', '4'],
        selection: '0',
        disabled: false,
        data: {
          property: 'cuDiPr',
          receiptText: 'Pris för ##NUM bestickinsats(er)'
        } as IOptionData
      },
      {
        type: ViewOptionType.select,
        title: 'Knivblock',
        name: 'knifeBlock',
        values: ['0', '1', '2', '3', '4'],
        selection: '0',
        disabled: false,
        data: {
          property: 'knBlPr',
          receiptText: 'Pris för ##NUM knivblock'
        } as IOptionData
      },
      {
        type: ViewOptionType.select,
        title: 'Kryddinsats',
        name: 'wavy',
        values: ['0', '1', '2', '3', '4'],
        selection: '0',
        disabled: false,
        data: {
          property: 'waBlPr',
          receiptText: 'Pris för ##NUM kryddinsats(er)'
        } as IOptionData
      }
    ]
    this.description = 'Dividers for drawers, knife blocks etc'
    this.title = 'optDrawerInsert'
    const sOption = DrawerInsert.sanitize(optionsList)
    this.setValuesFromProdboardData(sOption)
    this.setPrice()
  }

  get optionSelectName(): TOptionSelectName {
    return 'DrawerInsert'
  }

  private static sanitize(options: ProdboardCabinetOption[]): Record<string, number> {
    const drawerCount = {
      // eslint-disable-next-line @typescript-eslint/naming-convention
      div_wall: 0,
      knife: 0,
      wavy: 0
    }
    options.forEach((option: ProdboardCabinetOption) => {
      option.value = option.value || {} as any
      // eslint-disable-next-line @typescript-eslint/naming-convention
      option.value.options = option.value.options || {
        div_wall: {},
        knife: {},
        wavy: {}
      } as any
      /**
       * Since the key is not consistent we have to extract the key name to be able to check it's value
       * Daniel will probably have a better solution :-)
       *
       * The keys here are knife, div_wall, wavy
       *
       * each of which can have { ch_1: true} set ?? (Wrong! It can be {ch_1: true} or {ch_2: true}. We don't know
       * the "ch number")
       * If so we need to increment
       * this drawerInsert on the same prop. Let us assume that the property name
       * matches what we have in drawerInsert object.
       *
       * All of this is overly smart and should be simplified!
       *
       */

      const optionsKeys: string[] = Object.keys(option.value.options)
      optionsKeys.forEach((localKey: string) => {
        /**
         * The problem here is we don't know the name of the object. It could be ch1, ch2 and so on.
         * So we have to check if there is an object and then extract the object regardless of the name.
         * We assume that if there is an object it is set to true
         */
        const optKeys = Object.keys(option.value.options[localKey])
        if (optKeys.length > 0) {
          drawerCount[localKey]++
        }
      })
    })
    return drawerCount
  }

  public update(data: any): void {
    super.update(data)
    this.setFromProperties(data)
    this.setPrice()
  }

  /**
   * For CAD, we just want the legs
   */
  public valueMap(): Record<string, string | number | boolean> {
    return {
      cutlery: Number.parseInt(this.viewOptions[0].selection, 10),
      knife: Number.parseInt(this.viewOptions[1].selection, 10),
      wavy: Number.parseInt(this.viewOptions[2].selection, 10)
    }
  }

  public getCustomCustomerListing(
    useCase: TSettingOptionUseCase,
    lc: TLanguageCode
  ): string[] {
    // We assume, blindly, that every viewOption has a corresponding
    // Setting Option Value.
    // Careful, do not use "filter" before "map". Otherwise, viewOptions don't
    // match with translation values.
    return this.viewOptions
      .map((option: ViewOption, i: number) => {
        if (+option.selection > 0) {
          return this.settingOption
              .getI18nFromValue(this.settingOption.values[i], useCase, lc)
            + ': ' + option.selection + ' ' + CARDINALITY[lc]
        } else {
          return ''
        }
      })
      .filter(s => s)
  }

  private setValuesFromProdboardData(option: Record<string, number>): void {
    this.viewOptions[0].selection = option.div_wall + ''
    this.viewOptions[1].selection = option.knife + ''
    this.viewOptions[2].selection = option.wavy + ''
  }

  private setPrice(): void {
    this.resetPrices()
    this.active = false

    this.viewOptions
      .filter(vo => +vo.selection > 0)
      .forEach(vo => {
        this.addViewOptionPrice(vo)
        this.active = true
      })
  }

  private addViewOptionPrice(vo: ViewOption): void {
    const count = +vo.selection
    const property = vo.data.property

    this.price += count * this.product[property].price
    this.labor += count * this.product[property].labor
    this.material += count * this.product[property].material

    this.shouldHavePrice = true
  }
}
