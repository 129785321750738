import {Injectable} from '@angular/core'
import { HttpClient } from '@angular/common/http'
import {Observable} from 'rxjs'
import {environment} from '../../environments/environment'
import {ProdboardFile} from './prodboard-types'

/**
 * This is a condensed list format that we
 * send to the client in lists
 */
export interface ProdboardListItem {
  /**
   * The database id
   */
  id: string

  /**
   * Save date
   */
  timeStamp: number

  /**
   * Saved by admin
   */
  admin: boolean

  /**
   * Client email
   */
  clientEmail: string

  /**
   * Client name, is the name or the project name
   */
  clientName: string

  /**
   * The admin email if any
   */
  adminEmail: string

  /**
   * Prodboard id number
   */
  prodboardId: number

  /**
   * Prodboard number string like 221030-01 which is basically
   * the date (YYNNDD) + a save version (01)
   */
  prodboardNumber: string

  /**
   * The last part of the url that is another version.
   */
  prodboardVersion: string

  /**
   * URL to prodboard
   */
  url: string

}

@Injectable({
  providedIn: 'root',
})
export class ProdboardFileService {

  constructor(private http: HttpClient) {
  }

  public getFiles(): Observable<ProdboardListItem[]> {
    const url = `${environment.prodboardApiUrl}/projects`
    return this.http.get<ProdboardListItem[]>(url)
  }

  public getFile(id: string): Observable<ProdboardFile> {
    const url = `${environment.prodboardApiUrl}/projects/${id}`
    return this.http.get<ProdboardFile>(url)
  }

  public deleteFile(id: string): Observable<void> {
    const url = `${environment.prodboardApiUrl}/projects/${id}`
    return this.http.delete<void>(url)
  }

  public getOrders(): Observable<ProdboardListItem[]> {
    const url = `${environment.prodboardApiUrl}/orders`
    return this.http.get<ProdboardListItem[]>(url)
  }
}
