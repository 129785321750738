import {TLanguageCode} from '../application/i18n.provider'
import {IProduct} from '../common/interface/product-types'
import {ProdboardCabinetOption} from '../services/prodboard-types'
import {TSettingOptionUseCase} from '../services/settings-item.service'
import {
  CabinetOption,
  TOptionSelectName,
  ViewOptionType
} from './cabinet-option'
import {BACK_PANEL_MIRROR, BACK_PANEL_PLAIN, NO_BACK_COVER} from './model-types'

export class BackPanel extends CabinetOption {
  constructor(
    option: ProdboardCabinetOption,
    product: IProduct,
    cabinetId: number
  ) {
    super(option, product, cabinetId)
    this.priority = 30

    /**
     * The view options for this option. Here you must list all things that
     * can be selected/modified for an option.
     *
     * Based on this we will generate "Forms" higher up.
     */
    this.viewOptions = [
      {
        title: 'Täcksida bak', // The title will be used to name the field
        type: ViewOptionType.select, // The type tells what type of control to use
        name: 'backpanel', // This will be used as a property on the form. Important that it is unique.
        values: [NO_BACK_COVER, BACK_PANEL_PLAIN, BACK_PANEL_MIRROR], // Values is always an array.
        selection: NO_BACK_COVER, // What is currently selected, this is set below.
        disabled: false
      }
    ]
    this.description = 'When the cabinet\'s side is visible, an endpanel is added'
    this.title = 'optBackPanel'

    /**
     * All implementations should make sure it has sane defaults.
     *
     * If not set properly we will end up in an if then else hell.
     *
     * We create a "copy" so that we do not destroy the original. This
     * is how Javascript works, just live with it. Most of the time these
     * can be static implementations. That is a technicality.
     */
    const sOption = BackPanel.sanitize(option)
    this.setValuesFromProdboardData(sOption)
    this.setPrice()
  }

  get optionSelectName(): TOptionSelectName {
    return 'BackPanel'
  }

  /**
   * The sanitizer creates a default object. Then we overwrite everything
   * that we actually receive.
   */
  private static sanitize(option: ProdboardCabinetOption): ProdboardCabinetOption {
    option.value = option.value || {name: NO_BACK_COVER} as any
    return option
  }


  public update(data: any): void {
    super.update(data)
    this.setFromProperties(data)
    this.active = this.viewOptions[0].selection !== NO_BACK_COVER
    this.setPrice()
  }

  public getCustomCustomerListing(
    useCase: TSettingOptionUseCase,
    lc: TLanguageCode
  ): string[] {
    return [this.settingOption.getI18n(this.viewOptions[0].selection, useCase, lc)]
  }

  /**
   * Each class implements its own logic for setting the price and.
   * possibly other options. All options should be available in the
   */
  private setValuesFromProdboardData(option: ProdboardCabinetOption): void {
    this.active = false
    if (option.value.name !== NO_BACK_COVER) {
      this.active = true
      this.priority = 10
      this.viewOptions[0].selection = option.value.name
    }
  }

  /**
   * Called upon creation and after update
   */
  private setPrice(): void {
    this.resetPrices()

    const deduction = new Map<string, number>(
      [
        [BACK_PANEL_PLAIN, 0.5],
        [BACK_PANEL_MIRROR, 1]
      ]
    )

    if (this.viewOptions[0].selection !== NO_BACK_COVER) {
      this.price = this.product.baPaPr.price * deduction.get(this.viewOptions[0].selection)
      this.labor = this.product.baPaPr.labor * deduction.get(this.viewOptions[0].selection)
      this.shouldHavePrice = true
    }
  }
}
