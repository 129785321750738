import {HandleDoor} from '../handle-door'
import {DOOR_NO_DOOR_SELECTED, LUCKA_HOGALID} from '../model-types'
import {Door} from '../door'
import {DoorType} from '../door-type'
import {Hinges} from '../hinges'

export type TStoppersSelection = 'a' | 'b' | 'c'

const doorTypeOnes = ['1. Överfalsad profilerad lucka', LUCKA_HOGALID]
/**
 * Simple helper to make testing feasible.
 */
export class StoppersAndCatchers {
  public static select(doorType: DoorType, door: Door, hinges: Hinges, handles: HandleDoor): TStoppersSelection | undefined {
    if (doorType && hinges && (!door || door.viewOptions[0].selection !== DOOR_NO_DOOR_SELECTED)) {
      let type: TStoppersSelection
      const doorSelection = doorType.viewOptions[0].selection
      const hingeSelection = hinges.viewOptions[0].selection

      // 1. Klassiskt lyftgångärn
      if (hingeSelection === '1. Klassiskt lyftgångjärn') {
        if (hinges.active) {
          type = 'a'
        }
        // 1. Överfalsad profilerad lucka.
        if (handles?.isVred()) {
          type = 'b'
          if (doorTypeOnes.indexOf(doorSelection) !== -1) {
            type = 'c'
          }
        }
      } else {
        // For Hinge types 2 and 3 the logic is the same
        type = 'b' // Default for Must be Modern with visible top
        // 1. Överfalsad profilerad lucka.
        if (doorTypeOnes.indexOf(doorSelection) !== -1) {
          type = 'c'
        }
      }
      return type
    }
  }
}
