import {TLanguageCode} from '../application/i18n.provider'
import {TSettingOptionUseCase} from '../services/settings-item.service'
import {
  CabinetOption,
  TOptionSelectName,
  ViewOptionType
} from './cabinet-option'
import {IProduct} from '../common/interface/product-types'
import {ProdboardCabinetOption} from '../services/prodboard-types'

export const DRAWER_FRONT_OPTION_KEY_STANDARD = 'Standard'
export const DRAWER_FRONT_OPTION_KEY_PLAIN = 'Släta lådfronter'

export class DrawerFront extends CabinetOption {
  constructor(option: ProdboardCabinetOption, product: IProduct, cabinetId: number) {
    super(option, product, cabinetId)

    this.priority = 50
    this.viewOptions = [
      {
        type: ViewOptionType.select,
        title: 'Lådfronter',
        name: 'drawerFront',
        values: [DRAWER_FRONT_OPTION_KEY_STANDARD, DRAWER_FRONT_OPTION_KEY_PLAIN],
        selection: '',
        disabled: false
      }
    ]
    this.active = true
    this.description = 'Sometimes the drawer fronts are "plain", other times they are "regular"'
    this.title = 'optDrawerFront'

    const sOption = DrawerFront.sanitize(option)
    this.setValuesFromProdboardData(sOption)
  }

  get optionSelectName(): TOptionSelectName {
    return 'DrawerFront'
  }

  private static sanitize(option: ProdboardCabinetOption): ProdboardCabinetOption {
    option.value = option.value || {} as any
    option.value.options = option.value.options || {} as any
    option.value.options.door = option.value.options.door || {type: ''}

    return option
  }

  public update(data: any) {
    super.update(data)
    this.resetPrices()
    this.setFromProperties(data)
  }

  public getCustomCustomerListing(
    useCase: TSettingOptionUseCase,
    lc: TLanguageCode
  ): string[] {
    // It will only get text if it is "plain".
    if (this.viewOptions[0].selection === DRAWER_FRONT_OPTION_KEY_PLAIN) {
      return [this.settingOption.getI18n(DRAWER_FRONT_OPTION_KEY_PLAIN, useCase, lc)]
    }
    return []
  }

  private setValuesFromProdboardData(option: ProdboardCabinetOption): void {
    this.viewOptions[0].selection = DRAWER_FRONT_OPTION_KEY_STANDARD
    if (option.value.name === DRAWER_FRONT_OPTION_KEY_PLAIN) {
      this.viewOptions[0].selection = DRAWER_FRONT_OPTION_KEY_PLAIN
    }
  }
}
