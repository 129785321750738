import {ProdboardCabinet} from '../../../model/cabinet/prodboard-cabinet'
import {DefaultMap} from '../../../application/helpers'

export type TLayoutNeighborSide = 'left' | 'right' | 'top' | 'bottom'

export class LayoutCalculator {

  public left = 0
  public right = 0
  public top = 0
  public bottom = 0
  public width = 0
  public height = 0

  public vLeft = 0
  public vTop = 0
  public vWidth = 0
  public vHeight = 0

  public cat: string

  public index: number

  public neighbors: DefaultMap<TLayoutNeighborSide, LayoutCalculator[], []> =
    new DefaultMap<TLayoutNeighborSide, LayoutCalculator[], []>([], [])

  private uid: string

  constructor(private cabinet: ProdboardCabinet) {
    // If rotation z = -1 The doors are facing north
    // If rotation z = 1 The doors are facing south
    // If rotation x = -1 The doors are facing west
    // If rotation z = 1 The doors are facing east
    // If rotation x <> 0 then swap with and heights
    let width = this.cabinet.dimensions.x
    let hojd = this.cabinet.dimensions.z
    const vanster = this.cabinet.position.center.x
    const topp = this.cabinet.position.center.z
    if (Math.round(this.cabinet.position.direction.x) !== 0) {
      width = this.cabinet.dimensions.z
      hojd = this.cabinet.dimensions.x
    }
    this.vLeft = LayoutCalculator.scale(vanster - (width / 2))
    this.vTop = LayoutCalculator.scale(topp - (hojd / 2))
    this.vWidth = LayoutCalculator.scale(width)
    this.vHeight = LayoutCalculator.scale(hojd)
    this.cat = cabinet.cat
    this.index = cabinet.index
    this.uid = cabinet.uid
    this.setDimensions(hojd, width)
  }

  public static scale(input: number): number {
    return Math.floor(input / 5)
  }

  public compare(c: LayoutCalculator): void {
    const opposites: [TLayoutNeighborSide, TLayoutNeighborSide][] = [
      ['left', 'right'],
      ['right', 'left'],
      ['top', 'bottom'],
      ['bottom', 'top']
    ]
    opposites.forEach((opp) => {
      if (this[opp[0]] === c[opp[1]]) {
        this.neighbors.set(opp[0], [...this.neighbors.get(opp[0]), c])
      }
    })
  }

  public isNeighbor(id: string): boolean {
    return [...this.neighbors.values()].flat().filter(lh => lh.uid === id).length > 0
  }

  private setDimensions(hojd: number, width: number): void {
    const vanster = this.cabinet.position.center.x
    const topp = this.cabinet.position.center.z
    this.left = vanster - width / 2
    this.right = this.left + width
    this.top = topp - hojd / 2
    this.bottom = this.top + hojd

    this.height = hojd
    this.width = width
  }
}
