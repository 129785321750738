import {TLanguageCode} from '../application/i18n.provider'
import {IProduct} from '../common/interface/product-types'
import {ProdboardCabinetOption} from '../services/prodboard-types'
import {TSettingOptionUseCase} from '../services/settings-item.service'
import {
  CabinetOption,
  TOptionSelectName,
  ViewOptionType
} from './cabinet-option'
import {
  COVER_CONSOLE_POS,
  COVER_EXTEND_POS,
  COVER_SIDE_CONSOLE_CONCAVE,
  COVER_SIDE_CONSOLE_CONVEX,
  COVER_SIDE_CONSOLE_NONE,
  COVER_SIDE_EXTENDED,
  COVER_SIDE_MIRROR,
  COVER_SIDE_NONE,
  COVER_SIDE_PLAIN,
  COVER_TYPE_POS
} from './model-types'


export class CoverSide extends CabinetOption {
  /**
   * If the cover side has console or not, used in warning service
   * to check against scribings (oversize)
   */
  public hasConsole = false

  private sideSelection = {
    left: {sv: 'på vänster sida', en: 'left'},
    right: {sv: 'på höger sida', en: 'right'}
  }

  private readonly side: 'left' | 'right' = 'left'

  // No, Slät or mirror (coverType), option.value.name
  private type: string

  // extendCovers, option.value.options.[right]_panel.depth
  private extend = 0

  // console option.value.options.side_panels.[right_side].name
  private console: string

  constructor(option: ProdboardCabinetOption, product: IProduct, cabinetId: number, side: 'left' | 'right') {
    super(option, product, cabinetId)

    this.type = COVER_SIDE_NONE
    // this.filler = ''
    this.side = side
    this.priority = 8
    this.viewOptions = [
      {
        type: ViewOptionType.select,
        title: 'Typ av täcksida',
        name: 'coverType',
        values: [COVER_SIDE_NONE, COVER_SIDE_PLAIN, COVER_SIDE_MIRROR],
        selection: COVER_SIDE_NONE,
        disabled: false
      },
      {
        type: ViewOptionType.text,
        title: 'Förläng täcksidor (mm)',
        name: 'extendCovers',
        values: [],
        selection: '',
        disabled: false
      },
      {
        type: ViewOptionType.select,
        title: 'Konsol',
        name: 'console',
        values: [COVER_SIDE_CONSOLE_CONVEX, COVER_SIDE_CONSOLE_CONCAVE, COVER_SIDE_CONSOLE_NONE],
        selection: COVER_SIDE_CONSOLE_NONE,
        disabled: false
      }
    ]
    this.active = false
    this.description = 'When the cabinet\'s side is visible, an endpanel is added'
    this.title = 'optCoverSide' + this.sideSelection[side].en.charAt(0).toUpperCase()
    this.sanitize(option)
    this.setPrice()
  }

  get optionSelectName(): TOptionSelectName {
    return 'CoverSide'
  }

  public update(data: any): void {
    super.update(data)
    this.setFromProperties(data)
    this.active = data.coverType !== COVER_SIDE_NONE
    this.setPrice()
  }

  public valueMap(): Record<string, string | number | boolean> {
    return {
      side: this.side
    }
  }

  public getCustomCustomerListing(
    useCase: TSettingOptionUseCase,
    lc: TLanguageCode
  ): string[] {
    const answer: string[] = []

    if (this.viewOptions[COVER_TYPE_POS].selection === COVER_SIDE_PLAIN) {
      answer.push(this.settingOption.getI18n(COVER_SIDE_PLAIN, useCase, lc))
    }

    if (this.viewOptions[COVER_TYPE_POS].selection === COVER_SIDE_MIRROR) {
      answer.push(this.settingOption.getI18n(COVER_SIDE_MIRROR, useCase, lc))
    }

    if (+this.viewOptions[COVER_EXTEND_POS].selection > 0 && lc !== 'sv') {
      answer.push(`${this.settingOption.getI18n(COVER_SIDE_EXTENDED, useCase, lc)}: ${this.viewOptions[1].selection} mm`)
    }

    this.settingOption.values
      .filter(val =>
        this.viewOptions[COVER_CONSOLE_POS].selection === val.key)
      .filter(() =>
        this.viewOptions[COVER_CONSOLE_POS].selection !== COVER_SIDE_CONSOLE_NONE)
      .forEach(val => {
        answer.push(this.settingOption.getI18nFromValue(val, useCase, lc))
      })

    answer.push(this.sideSelection[this.side][lc])

    return [answer.join(', ')]
  }

  private sanitize(option: ProdboardCabinetOption): ProdboardCabinetOption {

    if (!option.value) {
      option.value = {} as any
    }

    // Type - We 'assume" this is always present
    this.type = option.value.name
    if (this.type && this.type !== COVER_SIDE_NONE) {
      this.viewOptions[COVER_TYPE_POS].selection = this.type
      this.active = true
    }

    if (!option.value.options) {
      option.value.options = {} as any
    }

    // Extend covers value
    if (!option.value.options[`${this.side}_panel`]) {
      option.value.options[`${this.side}_panel`] = {} as any
    }

    /**
     * Old JSON had "depth" new JSON has offset.
     */
    let marker = 'depth'
    if (option.value.options[`${this.side}_panel`].offset) {
      marker = 'offset'
    }

    if (option.value.options[`${this.side}_panel`][marker]) {
      this.extend = option.value.options[`${this.side}_panel`][marker]
      this.viewOptions[COVER_EXTEND_POS].disabled = false
    }

    // Fillers
    if (!option.value.options[`rec_side_${this.side}`]) {
      option.value.options[`rec_side_${this.side}`] = {} as any
    }

    // Console
    if (!option.value.options.side_panels) {
      option.value.options.side_panels = {} as any
    }

    if (!option.value.options.side_panels[`${this.side}_side`]) {
      option.value.options.side_panels[`${this.side}_side`] = {} as any
    }

    if (option.value.options.side_panels[`${this.side}_side`].name) {
      this.console = option.value.options.side_panels[`${this.side}_side`].name
      this.active = true
    }

    this.viewOptions[1].selection = this.extend + ''

    this.viewOptions[COVER_CONSOLE_POS].selection = this.console || COVER_SIDE_CONSOLE_NONE

    return option
  }

  private setPrice(): void {
    this.hasConsole = this.viewOptions[2].selection !== COVER_SIDE_CONSOLE_NONE
    this.resetPrices()
    // If we come here we have cover side. If Plain price is half
    if (this.viewOptions[COVER_TYPE_POS].selection === COVER_SIDE_PLAIN) {
      this.price = this.product.enPaPr.price / 2
      this.labor = this.product.enPaPr.labor / 2
      this.shouldHavePrice = true
    }

    // Or we have with mirror then full price
    if (this.viewOptions[COVER_TYPE_POS].selection === COVER_SIDE_MIRROR) {
      this.price = this.product.enPaPr.price
      this.labor = this.product.enPaPr.labor
      this.shouldHavePrice = true
    }

    if (this.viewOptions[COVER_CONSOLE_POS].selection !== COVER_SIDE_CONSOLE_NONE) {
      // ADD to the cabinet price as this is for longer sides
      this.price += this.product.coDeEnPaPr.price
      this.labor += this.product.coDeEnPaPr.labor
    }
  }
}
