import {TLanguageCode} from '../application/i18n.provider'
import {TSettingOptionUseCase} from '../services/settings-item.service'
import {
  CabinetOption,
  TOptionSelectName,
  ViewOptionType
} from './cabinet-option'
import {IProduct} from '../common/interface/product-types'
import {ProdboardCabinetOption} from '../services/prodboard-types'

export class BrassPlate extends CabinetOption {
  private selectOptions = [
    {
      key: 'None',
      value: 'Vänster'
    },
    {
      key: 'Left',
      value: 'Vänster'
    },
    {
      key: 'Right',
      value: 'Höger'
    },
    {
      key: 'Both',
      value: 'Båda'
    }
  ]

  constructor(option: ProdboardCabinetOption, product: IProduct, cabinetId: number) {
    super(option, product, cabinetId)

    this.priority = 20
    this.viewOptions = [
      {
        type: ViewOptionType.select,
        title: 'Mässingsskylt med Kulladalslogo',
        name: 'brassPlate',
        values: ['Vänster', 'Höger', 'Båda'],
        selection: 'Ingen',
        disabled: false
      }
    ]
    this.active = false
    this.description = ''
    this.title = 'optBrassPlate'
    BrassPlate.sanitize(option)
    this.setValuesFromProdboardData(option)
  }

  get optionSelectName(): TOptionSelectName {
    return 'BrassPlate'
  }

  private static sanitize(option: ProdboardCabinetOption): void {
    option.value = option.value || {name: 'None'} as any
  }

  public update(data: any) {
    super.update(data)
    this.setFromProperties(data)
  }

  setValuesFromProdboardData(option: ProdboardCabinetOption) {
    this.viewOptions[0].selection = this.selectOptions.find((opt) => opt.key === option.value.name).value
    this.active = this.selectOptions.find((opt) => opt.key === option.value.name).key !== 'None'
  }

  public getCustomCustomerListing(
    useCase: TSettingOptionUseCase,
    lc: TLanguageCode
  ): string[] {
    // We don't show it for customer use-case
    if (useCase === 'c') {
      return []
    }

    return [this.settingOption.getI18n(this.viewOptions[0].selection, useCase, lc)]
  }
}
