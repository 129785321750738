import {TLanguageCode} from '../application/i18n.provider'
import {IProduct} from '../common/interface/product-types'
import {TSettingOptionUseCase} from '../services/settings-item.service'
import {OUTSIDE_SKIRTING, STANDARD_SKIRTING} from './model-types'
import {
  CabinetOption,
  TOptionSelectName,
  ViewOptionType
} from './cabinet-option'
import {ProdboardCabinetOption} from '../services/prodboard-types'
import {FactoryData} from './factory/factory-data'
import {DefaultMap} from '../application/helpers'

export class Skirting extends CabinetOption {
  private selectionOptionsMap = new DefaultMap<string, string, string>([
    ['reg', STANDARD_SKIRTING],
    ['sk', OUTSIDE_SKIRTING]
  ], STANDARD_SKIRTING)

  constructor(option: ProdboardCabinetOption, product: IProduct, cabinetId: number) {
    super(option, product, cabinetId)

    this.priority = 20
    this.viewOptions = [
      {
        type: ViewOptionType.select,
        title: 'Typ av sockel',
        name: 'skirting',
        values: [STANDARD_SKIRTING, OUTSIDE_SKIRTING],
        selection: STANDARD_SKIRTING,
        disabled: false
      }
    ]
    this.description = 'Decides what kind of socle style the cabinet has - there are 2 different styles'
    this.title = 'optSkirting'
    // This is default active if it exists. You can manually deactivate it.
    this.active = true
    const sOption = Skirting.sanitize(option)
    this.setValuesFromProdboardData(sOption)
  }

  get optionSelectName(): TOptionSelectName {
    return 'Skirting'
  }

  private static sanitize(option: ProdboardCabinetOption): ProdboardCabinetOption {
    option.value = option.value || {} as any
    option.value.options = option.value.options || {} as any
    option.value.options.plinth = option.value.options.plinth || {} as any
    option.value.options.plinth.type = option.value.options.plinth.type || ''
    return option
  }

  /**
   * For CAD, we just want the legs
   */
  public valueMap(): Record<string, string | number | boolean> {
    return {
      skirt: ['standard plinth board', 'outside skirting board'][this.viewOptions[0].values.indexOf(this.viewOptions[0].selection)]
    }
  }

  public update(data: any): void {
    super.update(data)
    this.resetPrices()
    this.setFromProperties(data)
  }

  public getFactoryData(): FactoryData {
    return {
      outsideSkirting: this.viewOptions[0].selection === OUTSIDE_SKIRTING
    }
  }

  public getCustomCustomerListing(useCase: TSettingOptionUseCase, lc: TLanguageCode): string[] {
    return [this.settingOption.getI18n(this.viewOptions[0].selection, useCase, lc)]
  }

  private setValuesFromProdboardData(option: ProdboardCabinetOption): void {
    this.viewOptions[0].selection = this.selectionOptionsMap.get(option.value.options.plinth.type)
  }
}
