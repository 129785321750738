import {TLanguageCode} from '../application/i18n.provider'
import {IProduct} from '../common/interface/product-types'
import {TSettingOptionUseCase} from '../services/settings-item.service'
import {
  CabinetOption,
  TOptionSelectName,
  ViewOptionType
} from './cabinet-option'
import {ProdboardCabinetOption} from '../services/prodboard-types'

export class Hanging extends CabinetOption {
  private readonly selectionOptions = {
    doors0: {
      oddSide: {},
      sameSide: {}
    },
    doors1: {
      oddSide: {
        undef: 'FEL',
        top: 'Upptill',
        left: 'Vänster',
        right: 'Höger'
      }
    },
    doors2: {
      sameSide: {
        left: 'Vänster-Vänster',
        right: 'Höger-Höger'
      },
      oddSide: {
        undef: 'Vänster-Höger',
        top: 'Upptill',
        left: 'Vänster-Höger',
        right: 'Vänster-Höger'
      }
    },
    doors3: {
      oddSide: {
        undef: 'FEL',
        top: 'Upptill',
        left: 'Vänster-Vänster-Höger',
        right: 'Vänster-Höger-Höger'
      }
    },
    doors6: {
      sameSide: {
        undef: 'FEL',
        left: 'Vänster-Vänster-Höger x 2',
        right: 'Vänster-Höger-Höger x 2'
      }
    }
  }

  constructor(option: ProdboardCabinetOption, product: IProduct, cabinetId: number) {
    super(option, product, cabinetId)

    this.priority = 10
    this.viewOptions = [
      {
        type: ViewOptionType.select,
        title: 'Hängning',
        name: 'hanging',
        values: ['Vänster', 'Höger', 'Vänster-Vänster', 'Höger-Höger', 'Vänster-Vänster-Höger',
          'Vänster-Höger-Höger', 'Upptill', 'Vänster-Vänster-Höger x 2', 'Vänster-Höger-Höger x 2', 'FEL'],
        selection: '',
        disabled: false
      }
    ]
    this.active = true

    this.description = 'Describes if the door is left, right or top hinged'
    this.title = 'optHanging'

    const sOption = Hanging.sanitize(option)
    this.setValuesFromProdboardData(sOption)
    this.updateSettings()
  }

  get optionSelectName(): TOptionSelectName {
    return 'Hanging'
  }

  private static sanitize(option: ProdboardCabinetOption): ProdboardCabinetOption {
    option.value = option.value || {} as any
    option.value.options = option.value.options || {} as any
    option.value.options.open = option.value.options.open || {type: {code: 'left'}} as any
    return option
  }

  public update(data: any) {
    super.update(data)
    this.resetPrices()
    this.setFromProperties(data)
    this.updateSettings()
  }

  public getCustomCustomerListing(
    useCase: TSettingOptionUseCase,
    lc: TLanguageCode
  ): string[] {
    const selectionAsKey = this.viewOptions[0].selection
      .replace(/[åäö\s]/g, '_')
      .replace(/_x_2/g, '_x2')
      .toLowerCase()
    return [this.settingOption.getI18n(selectionAsKey, useCase, lc)]
  }

  private setValuesFromProdboardData(option: ProdboardCabinetOption): void {
    const numDoors = 'doors' + this.product.nuDo
    let hangingSide = 'oddSide'
    const sameSideHangingCheck = this.product.pc.indexOf('x2')

    if (sameSideHangingCheck !== -1) {
      hangingSide = 'sameSide'
    }

    if (option.value.options.open.type?.code) {
      this.viewOptions[0].selection = this.selectionOptions[numDoors][hangingSide][option.value.options.open.type.code]
    }
  }

  private updateSettings(): void {
    this.active = this.viewOptions[0].selection !== ''
  }
}
