import {TLanguageCode} from '../application/i18n.provider'
import {TSettingOptionUseCase} from '../services/settings-item.service'
import {CabinetOption, TOptionSelectName} from './cabinet-option'
import {IProduct} from '../common/interface/product-types'
import {ProdboardCabinetOption} from '../services/prodboard-types'

export class UnknownOption extends CabinetOption {
  constructor(option: ProdboardCabinetOption, product: IProduct, cabinetId, key: string) {
    super(option, product, cabinetId)
    this.name = 'UnknownOption' + '_' + option.count
    this.title = `Okänt alternativ (${key})`
    this.priority = 101
  }

  get optionSelectName(): TOptionSelectName {
    return 'UnknownOption'
  }

  public getCustomCustomerListing(
    useCase: TSettingOptionUseCase,
    lc: TLanguageCode
  ): string[] {
    return [`${this.title} - Use-case ${useCase}, Lang. ${lc}`]
  }
}
