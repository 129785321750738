import {TLanguageCode} from '../application/i18n.provider'
import {IProduct} from '../common/interface/product-types'
import {TSettingOptionUseCase} from '../services/settings-item.service'
import {
  CabinetOption,
  TOptionSelectName,
  ViewOptionType
} from './cabinet-option'
import {ProdboardCabinetOption} from '../services/prodboard-types'
import {HIDDEN_DRAWER_SINK_NONE, HIDDEN_DRAWER_SINK_ONE, HIDDEN_DRAWER_SINK_TWO} from './model-types'

export class HiddenDrawerSink extends CabinetOption {
  constructor(option: ProdboardCabinetOption, product: IProduct, cabinetId: number) {
    super(option, product, cabinetId)

    this.priority = 100
    this.viewOptions = [
      {
        type: ViewOptionType.select,
        title: 'Utdragslådor vaskskåp',
        name: 'hiddenDrawerSink',
        values: [HIDDEN_DRAWER_SINK_ONE, HIDDEN_DRAWER_SINK_TWO, HIDDEN_DRAWER_SINK_NONE],
        selection: HIDDEN_DRAWER_SINK_NONE,
        disabled: false
      }
    ]
    this.active = true

    this.description = 'MISSING'
    this.title = 'optHiddenDrawerSink'

    HiddenDrawerSink.sanitize(option)
    this.viewOptions[0].selection = option.value.name
    this.setPrice()
  }

  get optionSelectName(): TOptionSelectName {
    return 'HiddenDrawerSink'
  }

  private static sanitize(option: ProdboardCabinetOption): ProdboardCabinetOption {
    option.value = option.value || {name: HIDDEN_DRAWER_SINK_NONE} as any
    // This is because the stupidness of hidden drawers have different names depending on cabinet
    if (option.value.name === 'En utdragslåda' || option.value.name === 'En utdragslådor') {
      option.value.name = HIDDEN_DRAWER_SINK_ONE
    }
    if (option.value.name === 'Två utdragslådor') {
      option.value.name = HIDDEN_DRAWER_SINK_TWO
    }
    return option
  }

  public update(data: any) {
    super.update(data)
    this.setFromProperties(data)
    this.setPrice()
  }

  public getCustomCustomerListing(
    useCase: TSettingOptionUseCase,
    lc: TLanguageCode
  ): string[] {
    return [this.settingOption.getI18n(this.viewOptions[0].selection, useCase, lc)]
  }

  private setPrice(): void {
    this.resetPrices()
    this.active = this.viewOptions[0].selection !== HIDDEN_DRAWER_SINK_NONE
    // 0, 1 or 2 order is not necessarily the same as the display order
    const amount = [HIDDEN_DRAWER_SINK_NONE, HIDDEN_DRAWER_SINK_ONE, HIDDEN_DRAWER_SINK_TWO]
      .indexOf(this.viewOptions[0].selection)
    this.price = this.product.hiDrPr.price * amount
    this.labor = this.product.hiDrPr.labor * amount
    if (this.active) {
      this.shouldHavePrice = true
    }
  }
}
