import {TOptionSelectName} from '../model/cabinet-option'
import {
  DRAWER_FRONT_OPTION_KEY_PLAIN,
  DRAWER_FRONT_OPTION_KEY_STANDARD
} from '../model/drawer-front'
import {
  FRAME_WIDTH_OPTION_KEY_LEFT,
  FRAME_WIDTH_OPTION_KEY_RIGHT
} from '../model/frame-width/frame-width'
import {
  BACK_PANEL_MIRROR,
  BACK_PANEL_PLAIN,
  COVER_SIDE_CONSOLE_CONCAVE,
  COVER_SIDE_CONSOLE_CONVEX,
  COVER_SIDE_CONSOLE_NONE,
  COVER_SIDE_EXTENDED,
  COVER_SIDE_MIRROR,
  COVER_SIDE_PLAIN,
  DOOR_GLASS_DOOR,
  DOOR_NO_DOOR_SELECTED,
  DOOR_NO_PAINTED_INSIDE,
  DOOR_ON_DOOR,
  DOOR_PAINTED_INSIDE,
  DOOR_POS_BOT,
  DOOR_POS_BOT_LEFT,
  DOOR_POS_BOT_MID,
  DOOR_POS_BOT_RIGHT,
  DOOR_POS_LEFT,
  DOOR_POS_MID,
  DOOR_POS_ONLY,
  DOOR_POS_RIGHT,
  DOOR_POS_TOP,
  DOOR_POS_TOP_LEFT,
  DOOR_POS_TOP_MID,
  DOOR_POS_TOP_RIGHT,
  DOOR_STANDARD_DOOR,
  DRAWER_HIGH,
  DRAWER_LOW,
  EXTRACTOR_HOOD_CONCAVE_CONSOLE,
  EXTRACTOR_HOOD_CONVEX_CONSOLE,
  EXTRACTOR_HOOD_MIRROR,
  EXTRACTOR_HOOD_NO_CONSOLE,
  EXTRACTOR_HOOD_PLAIN,
  EXTRACTOR_HOOD_SIDE_FRONT,
  EXTRACTOR_HOOD_SIDE_LEFT,
  EXTRACTOR_HOOD_SIDE_RIGHT,
  EXTRACTOR_HOOD_UNPAINTED,
  HIDDEN_DRAWER_SINK_ONE,
  HIDDEN_DRAWER_SINK_TWO,
  LINSEED_BASE,
  LINSEED_COMPLETE,
  LINSEED_FACTORY_COMPLETE,
  LUCKA_DJUPADAL,
  LUCKA_HOGALID,
  LUCKA_MALMGARDEN,
  LUCKA_MELLANHEDEN,
  LUCKA_RONNEHOLM,
  LUCKA_SORGENFRI,
  LUCKA_SUNNANA,
  NO_BACK_COVER,
  NO_PAINT,
  NO_PAINT_WAXED_INSIDE,
  ORDINARY_FACTORY_COMPLETE,
  ORDINARY_PAINT_BASE,
  ORDINARY_PAINT_COMPLETE,
  OUTSIDE_SKIRTING,
  SHELF,
  SLIDES,
  STANDARD_SKIRTING,
  TBD
} from '../model/model-types'
import {PLINTH_OPTION_KEY} from '../model/plinth'
import {SCRIBING_OPTION_KEY_NONE} from '../model/scribings'
import {SHELVES_OPTION_KEY} from '../model/shelves'
import {
  ISettingsConfigSupplier,
  ISettingsConfigValue,
  SettingsBaseItem,
  SettingsConfig,
  SettingsOption,
  TConfigItemName
} from './settings-item.service'

export const getDefaultSettingOption = (optionName: TOptionSelectName): SettingsOption => {
  return new SettingsOption(JSON.parse(JSON.stringify(defaultOptions[optionName])))
}

export const getDefaultSettingConfig = (configName: TConfigItemName): SettingsConfig => {
  return new SettingsConfig(JSON.parse(JSON.stringify(defaultConfigs[configName])))
}

/**
 * We define the default options like this so that we can see if there is
 * any option missing when adding new types in {@link TOptionSelectName}.
 * Please, try keeping them ordered alphabetically.
 */
const defaultOptions: { [key in TOptionSelectName]: Partial<SettingsOption> } = {
  /**
   * UnknownOption - It's the default option that Cabinets will have if there
   * is no matching option from Prodboard JSONs.
   * It will be ignored later on. Do not bother configuring it.
   */
  UnknownOption: {
    name: 'UnknownOption'
  },

  BackPanel: {
    name: 'BackPanel',
    values: [{
      key: NO_BACK_COVER,
      ucs: [
        {type: 'c', i18n: {sv: `Ingen täcksida bak`, en: ``}},
        {type: 'f', i18n: {sv: ``, en: `No back panel`}},
        {type: 'i', copy: 'f'}
      ]
    }, {
      key: BACK_PANEL_PLAIN,
      ucs: [
        {type: 'c', i18n: {sv: `Slät täcksida bak`, en: ``}},
        {type: 'f', i18n: {sv: ``, en: `BACK_PANEL: YUES!`}},
        {type: 'i', copy: 'f'}
      ]
    }, {
      key: BACK_PANEL_MIRROR,
      ucs: [
        {type: 'c', i18n: {sv: `Täcksida med spegel bak`, en: ``}},
        {type: 'f', i18n: {sv: ``, en: `BACK_PANEL: YUES!`}},
        {type: 'i', copy: 'f'}
      ]
    }]
  },
  BrassPlate: {
    name: 'BrassPlate',
    values: [{
      key: 'Vänster',
      ucs: [
        {
          type: 'c',
          i18n: {
            sv: `Mässingsplakett med Kulladalslogo, på vänster sida av låda`,
            en: ``
          }
        },
        {type: 'f', i18n: {sv: ``, en: `Brass tag: Left`}},
        {type: 'i', copy: 'f'}
      ]
    }, {
      key: 'Höger',
      ucs: [
        {
          type: 'c',
          i18n: {
            sv: `Mässingsplakett med Kulladalslogo, på höger sida av låda`,
            en: ``
          }
        },
        {type: 'f', i18n: {sv: ``, en: `Brass tag: Right`}},
        {type: 'i', copy: 'f'}
      ]
    }, {
      key: 'Båda',
      ucs: [
        {
          type: 'c',
          i18n: {
            sv: `Mässingsplakett med Kulladalslogo, på båda sidorna`,
            en: ``
          }
        },
        {type: 'f', i18n: {sv: ``, en: `Brass tag: Both`}},
        {type: 'i', copy: 'f'}
      ]
    }]
  },
  CarpenterJoy: {
    name: 'CarpenterJoy',
    values: [{
      key: 'Ja',
      ucs: [
        {
          type: 'c',
          i18n: {
            sv: `"Snickarglädje"-dekoration i nedre vänstra hörnet`,
            en: ``
          }
        },
        {
          type: 'f',
          i18n: {sv: ``, en: `Curve decoration in bottom left corner`}
        },
        {type: 'i', copy: 'f'}
      ]
    }, {
      key: 'Ja',
      ucs: [
        {
          type: 'c',
          i18n: {
            sv: `Snickarglädje"-dekoration i nedre högra hörnet`,
            en: ``
          }
        },
        {
          type: 'f',
          i18n: {sv: ``, en: `Curve decoration in bottom right corner`}
        },
        {type: 'i', copy: 'f'}
      ]
    }, {
      key: 'Båda',
      ucs: [
        {
          type: 'c',
          i18n: {
            sv: `"Snickarglädje"-dekorationer i frontramens båda nedre hörn`,
            en: ``
          }
        },
        {type: 'f', i18n: {sv: ``, en: `Curve decoration in bottom corners`}},
        {type: 'i', copy: 'f'}
      ]
    }]
  },
  CenterPost: {
    name: 'CenterPost',
    values: [{
      key: 'Fast mittstolpe mellan luckorna',
      ucs: [
        {type: 'c', i18n: {sv: `Fast mittstolpe mellan luckorna`, en: ``}},
        {type: 'f', i18n: {sv: ``, en: `Fixed mid-post between the doors`}},
        {type: 'i', copy: 'f'}
      ]
    }, {
      key: 'Mittstolpen är fixerad vid en av luckorna',
      ucs: [
        {
          type: 'c',
          i18n: {sv: `Mittstolpen är fixerad vid en av luckorna`, en: ``}
        },
        {type: 'f', i18n: {sv: ``, en: `Fixed mid-post at one of the doors`}},
        {type: 'i', copy: 'f'}
      ]
    }, {
      key: 'Ingen mittstolpe mellan luckorna',
      ucs: [
        {type: 'c', i18n: {sv: `Ingen mittstolpe mellan luckorna`, en: ``}},
        {type: 'f', i18n: {sv: ``, en: `No mid-post`}},
        {type: 'i', copy: 'f'}
      ]
    }]
  },
  CombinedUnit: {
    name: 'CombinedUnit',
    values: [{
      key: 'left',
      ucs: [
        {
          type: 'c',
          i18n: {
            sv: `Skåpet byggs som en enhet tillsammans med skåpet till vänster`,
            en: ``
          }
        },
        {
          type: 'f',
          i18n: {sv: ``, en: `Combined unit with the cabinet on the left`}
        },
        {type: 'i', copy: 'f'}
      ]
    }, {
      key: 'right',
      ucs: [
        {
          type: 'c',
          i18n: {
            sv: `Skåpet byggs som en enhet tillsammans med skåpet till höger`,
            en: ``
          }
        },
        {
          type: 'f',
          i18n: {sv: ``, en: `Combined unit with the cabinet on the right`}
        },
        {type: 'i', copy: 'f'}
      ]
    }, {
      key: 'top',
      ucs: [
        {
          type: 'c',
          i18n: {
            sv: `Skåpet byggs som en enhet tillsammans med skåpet ovanför`,
            en: ``
          }
        },
        {
          type: 'f',
          i18n: {sv: ``, en: `Combined unit with the cabinet on top`}
        },
        {type: 'i', copy: 'f'}
      ]
    }, {
      key: 'bot',
      ucs: [
        {
          type: 'c',
          i18n: {
            sv: `Skåpet byggs som en enhet tillsammans med skåpet nedan`,
            en: ``
          }
        },
        {type: 'f', i18n: {sv: ``, en: `Combined unit with the cabinet below`}},
        {type: 'i', copy: 'f'}
      ]
    }]
  },
  Cornice: {
    name: 'Cornice',
    values: [{
      key: 'Ja',
      ucs: [
        {
          type: 'c',
          i18n: {sv: `Krönlist (ej monterad på skåpet vid leverans)`, en: ``}
        },
        {type: 'f', i18n: {sv: ``, en: `Cornice: Yes`}},
        {type: 'i', copy: 'f'}
      ]
    }]
  },
  CoverSide: {
    name: 'CoverSide',
    values: [{
      key: COVER_SIDE_PLAIN,
      ucs: [
        {type: 'c', i18n: {sv: `Slät täcksida`, en: ``}},
        {type: 'f', i18n: {sv: ``, en: `Plain endpanels`}},
        {type: 'i', copy: 'f'}
      ]
    }, {
      key: COVER_SIDE_MIRROR,
      ucs: [
        {type: 'c', i18n: {sv: `Täcksida med spegel`, en: ``}},
        {type: 'f', i18n: {sv: ``, en: `Cover side with spegel`}},
        {type: 'i', copy: 'f'}
      ]
    }, {
      key: COVER_SIDE_EXTENDED,
      ucs: [
        {type: 'c', i18n: {sv: `förlängd med`, en: ``}},
        {type: 'f', i18n: {sv: ``, en: `Extended cover sides`}},
        {type: 'i', copy: 'f'}
      ]
    }, {
      key: COVER_SIDE_CONSOLE_CONCAVE,
      ucs: [
        {type: 'c', i18n: {sv: `Med konkav konsol`, en: ``}},
        {type: 'f', i18n: {sv: ``, en: `With concave console`}},
        {type: 'i', copy: 'f'}
      ]
    }, {
      key: COVER_SIDE_CONSOLE_CONVEX,
      ucs: [
        {type: 'c', i18n: {sv: `Med konvex konsol`, en: ``}},
        {type: 'f', i18n: {sv: ``, en: `With convex console`}},
        {type: 'i', copy: 'f'}
      ]
    }, {
      key: COVER_SIDE_CONSOLE_NONE,
      ucs: [
        {type: 'c', i18n: {sv: `Utan konsol`, en: ``}},
        {type: 'f', i18n: {sv: ``, en: `Without console`}},
        {type: 'i', copy: 'f'}
      ]
    }]
  },
  CuttingBoard: {
    name: 'CuttingBoard',
    values: [{
      key: 'Ja',
      ucs: [
        {type: 'c', i18n: {sv: `Utdragbar skärbräda`, en: ``}},
        {type: 'f', i18n: {sv: ``, en: `Chopping board`}},
        {type: 'i', copy: 'f'}
      ]
    }]
  },
  Door: {
    name: 'Door',
    values: [{
      key: DOOR_GLASS_DOOR,
      ucs: [
        {type: 'c', i18n: {sv: `Vitrinlucka`, en: ``}},
        {type: 'f', i18n: {sv: ``, en: `Glass door`}},
        {type: 'i', copy: 'f'}
      ]
    }, {
      key: DOOR_NO_DOOR_SELECTED,
      ucs: [
        {type: 'c', i18n: {sv: `Ingen lucka`, en: ``}},
        {type: 'f', i18n: {sv: ``, en: `No door`}},
        {type: 'i', copy: 'f'}
      ]
    }, {
      key: DOOR_STANDARD_DOOR,
      ucs: [
        {type: 'c', i18n: {sv: ``, en: ``}},
        {type: 'f', i18n: {sv: ``, en: ``}},
        {type: 'i', copy: 'f'}
      ]
    }, {
      key: DOOR_PAINTED_INSIDE,
      ucs: [
        {type: 'c', i18n: {sv: `Målad insida`, en: ``}},
        {type: 'f', i18n: {sv: ``, en: `Painted inside`}},
        {type: 'i', copy: 'f'}
      ]
    }, {
      key: DOOR_NO_PAINTED_INSIDE,
      ucs: [
        {type: 'c', i18n: {sv: `Ingen målad insida`, en: ``}},
        {type: 'f', i18n: {sv: ``, en: `No painted inside`}},
        {type: 'i', copy: 'f'}
      ]
    }]
  },
  DoorAttachment: {
    name: 'DoorAttachment',
    values: [{
      key: DOOR_ON_DOOR,
      ucs: [
        {
          type: 'c',
          i18n: {
            sv: `Luckan monteras "door-on-door" och har därför inga egna gångjärn`,
            en: ``
          }
        },
        {type: 'f', i18n: {sv: ``, en: `Door-on-door - no hinges!`}},
        {type: 'i', copy: 'f'}
      ]
    }, {
      key: SLIDES,
      ucs: [
        {
          type: 'c',
          i18n: {
            sv: `Anpassas för kyl/frys som har glidskenor, OBS! Inte "door-on-door")`,
            en: ``
          }
        },
        {type: 'f', i18n: {sv: ``, en: `Slides - include hinges!`}},
        {type: 'i', copy: 'f'}
      ]
    }, {
      key: TBD,
      ucs: [
        {
          type: 'c',
          i18n: {
            sv: `OBS! Kulladal behöver veta om kylen är "door-on-door" eller om den har glidskenor`,
            en: ``
          }
        },
        {type: 'f', i18n: {sv: ``, en: `TBD? - door-on-door - or slides?`}},
        {type: 'i', copy: 'f'}
      ]
    }]
  },
  DoorType: {
    name: 'DoorType',
    values: [{
      key: '0. Ej specificerat',
      ucs: [
        {type: 'c', i18n: {sv: ``, en: ``}},
        {type: 'f', i18n: {sv: ``, en: ``}},
        {type: 'i', copy: 'f'}
      ]
    }, {
      key: '1. Överfalsad profilerad lucka',
      ucs: [
        {type: 'c', i18n: {sv: `Överfalsad profilerad lucka`, en: ``}},
        {
          type: 'f',
          i18n: {
            sv: ``,
            en: `Type 1 (lipped door with rounded profile on all sides)`
          }
        },
        {type: 'i', copy: 'f'}
      ]
    }, {
      key: '2. Innanförliggande profilerad lucka',
      ucs: [
        {type: 'c', i18n: {sv: `Innanförliggande profilerad lucka`, en: ``}},
        {type: 'f', i18n: {sv: ``, en: `Innanförliggande profilerad lucka`}},
        {type: 'i', copy: 'f'}
      ]
    }, {
      key: '3. Innanförliggande shakerlucka',
      ucs: [
        {type: 'c', i18n: {sv: `Innanförliggande shakerlucka`, en: ``}},
        {type: 'f', i18n: {sv: ``, en: `Innanförliggande shakerlucka`}},
        {type: 'i', copy: 'f'}
      ]
    }, {
      key: 'Door style 4',
      ucs: [
        {type: 'c', i18n: {sv: `Type 4`, en: ``}},
        {type: 'f', i18n: {sv: ``, en: `Type 4`}},
        {type: 'i', copy: 'f'}
      ]
    }, {
      key: LUCKA_HOGALID,
      ucs: [
        {type: 'c', i18n: {sv: `Lucka Högalid`, en: ``}},
        {type: 'f', i18n: {sv: ``, en: `Lucka Högalid`}},
        {type: 'i', copy: 'f'}
      ]
    }, {
      key: LUCKA_DJUPADAL,
      ucs: [
        {type: 'c', i18n: {sv: `Lucka Djupadal`, en: ``}},
        {type: 'f', i18n: {sv: ``, en: `Lucka Djupadal`}},
        {type: 'i', copy: 'f'}
      ]
    }, {
      key: LUCKA_RONNEHOLM,
      ucs: [
        {type: 'c', i18n: {sv: `Lucka Rönneholm`, en: ``}},
        {type: 'f', i18n: {sv: ``, en: `Lucka Rönneholm`}},
        {type: 'i', copy: 'f'}
      ]
    }, {
      key: LUCKA_SUNNANA,
      ucs: [
        {type: 'c', i18n: {sv: `Lucka Sunnanå`, en: ``}},
        {type: 'f', i18n: {sv: ``, en: `Lucka Sunnanå`}},
        {type: 'i', copy: 'f'}
      ]
    }, {
      key: LUCKA_MALMGARDEN,
      ucs: [
        {type: 'c', i18n: {sv: `Lucka Malmgården`, en: ``}},
        {type: 'f', i18n: {sv: ``, en: `Lucka Malmgården`}},
        {type: 'i', copy: 'f'}
      ]
    }, {
      key: LUCKA_SORGENFRI,
      ucs: [
        {type: 'c', i18n: {sv: `Lucka Sorgenfri`, en: ``}},
        {type: 'f', i18n: {sv: ``, en: `Lucka Sorgenfri`}},
        {type: 'i', copy: 'f'}
      ]
    }, {
      key: LUCKA_MELLANHEDEN,
      ucs: [
        {type: 'c', i18n: {sv: `Lucka Mellanheden`, en: ``}},
        {type: 'f', i18n: {sv: ``, en: `Lucka Mellanheden`}},
        {type: 'i', copy: 'f'}
      ]
    }]
  },
  DrawerDoor: {
    name: 'DrawerDoor',
    values: [{
      key: 'Låda',
      ucs: [
        {type: 'c', i18n: {sv: `Låda under ugn`, en: ``}},
        {type: 'f', i18n: {sv: ``, en: `Drawer beneath oven`}},
        {type: 'i', copy: 'f'}
      ]
    }, {
      key: 'Lucka',
      ucs: [
        {type: 'c', i18n: {sv: `Lucka under ugn`, en: ``}},
        {type: 'f', i18n: {sv: ``, en: `Door beneath oven`}},
        {type: 'i', copy: 'f'}
      ]
    }]
  },
  DrawerFront: {
    name: 'DrawerFront',
    values: [{
      key: DRAWER_FRONT_OPTION_KEY_STANDARD,
      ucs: [
        {type: 'c', i18n: {sv: `Standardlådfront`, en: ``}},
        {type: 'f', i18n: {sv: ``, en: `Standard drawer fronts`}},
        {type: 'i', copy: 'f'}
      ]
    }, {
      key: DRAWER_FRONT_OPTION_KEY_PLAIN,
      ucs: [
        {type: 'c', i18n: {sv: `Släta lådfronter`, en: ``}},
        {type: 'f', i18n: {sv: ``, en: `Plain`}},
        {type: 'i', copy: 'f'}
      ]
    }]
  },
  DrawerInsert: {
    name: 'DrawerInsert',
    values: [{
      key: 'Bestickinsats',
      ucs: [
        {type: 'c', i18n: {sv: `Bestickinsats`, en: ``}},
        {type: 'f', i18n: {sv: ``, en: `cutlery dividers`}},
        {type: 'i', copy: 'f'}
      ]
    }, {
      key: 'Knivblock',
      ucs: [
        {type: 'c', i18n: {sv: `Knivblock`, en: ``}},
        {type: 'f', i18n: {sv: ``, en: `knife block`}},
        {type: 'i', copy: 'f'}
      ]
    }, {
      key: 'Kryddinsatser',
      ucs: [
        {type: 'c', i18n: {sv: `Kryddinsatser`, en: ``}},
        {type: 'f', i18n: {sv: ``, en: `wavy`}},
        {type: 'i', copy: 'f'}
      ]
    }]
  },
  FanAdoption: {
    name: 'FanAdoption',
    values: [{
      key: 'Ja',
      ucs: [
        {type: 'c', i18n: {sv: `Anpassad för fläkt`, en: ``}},
        {
          type: 'f',
          i18n: {sv: ``, en: `N.B. Adopted for extractor, check the specs`}
        },
        {type: 'i', copy: 'f'}
      ]
    }]
  },
  FanExtractorPanel: {
    name: 'FanExtractorPanel',
    values: [{
      key: EXTRACTOR_HOOD_SIDE_FRONT,
      ucs: [
        {type: 'c', i18n: {sv: `Framsida,`, en: ``}},
        {type: 'f', i18n: {sv: ``, en: `Front,`}},
        {type: 'i', copy: 'f'}
      ]
    }, {
      key: EXTRACTOR_HOOD_SIDE_LEFT,
      ucs: [
        {type: 'c', i18n: {sv: `Vänster,`, en: ``}},
        {type: 'f', i18n: {sv: ``, en: `Left,`}},
        {type: 'i', copy: 'f'}
      ]
    }, {
      key: EXTRACTOR_HOOD_SIDE_RIGHT,
      ucs: [
        {type: 'c', i18n: {sv: `Höger,`, en: ``}},
        {type: 'f', i18n: {sv: ``, en: `Right,`}},
        {type: 'i', copy: 'f'}
      ]
    }, {
      key: EXTRACTOR_HOOD_MIRROR,
      ucs: [
        {type: 'c', i18n: {sv: `stomsida med spegel`, en: ``}},
        {type: 'f', i18n: {sv: ``, en: `carcass side with mirror`}},
        {type: 'i', copy: 'f'}
      ]
    }, {
      key: EXTRACTOR_HOOD_PLAIN,
      ucs: [
        {type: 'c', i18n: {sv: `slät stomsida`, en: ``}},
        {type: 'f', i18n: {sv: ``, en: `plain carcass side`}},
        {type: 'i', copy: 'f'}
      ]
    }, {
      key: EXTRACTOR_HOOD_UNPAINTED,
      ucs: [
        {type: 'c', i18n: {sv: `omålad`, en: ``}},
        {type: 'f', i18n: {sv: ``, en: `plain carcass side`}},
        {type: 'i', copy: 'f'}
      ]
    }, {
      key: EXTRACTOR_HOOD_CONCAVE_CONSOLE,
      ucs: [
        {type: 'c', i18n: {sv: `med konkav kosnol`, en: ``}},
        {type: 'f', i18n: {sv: ``, en: `with convex console`}},
        {type: 'i', copy: 'f'}
      ]
    }, {
      key: EXTRACTOR_HOOD_CONVEX_CONSOLE,
      ucs: [
        {type: 'c', i18n: {sv: `med konvex konsol`, en: ``}},
        {type: 'f', i18n: {sv: ``, en: `with konvex console`}},
        {type: 'i', copy: 'f'}
      ]
    }, {
      key: EXTRACTOR_HOOD_NO_CONSOLE,
      ucs: [
        {type: 'c', i18n: {sv: `utan konsol`, en: ``}},
        {type: 'f', i18n: {sv: ``, en: `no console`}},
        {type: 'i', copy: 'f'}
      ]
    }]
  },
  Filler: {
    name: 'Filler',
    values: [{
      key: 'fillerLeft',
      ucs: [
        {type: 'c', i18n: {sv: `Indrag på vänster sida:`, en: ``}},
        {type: 'f', i18n: {sv: ``, en: `Recess on left side:`}},
        {type: 'i', copy: 'f'}
      ]
    }, {
      key: 'fillerRight',
      ucs: [
        {type: 'c', i18n: {sv: `Indrag på höger sida:`, en: ``}},
        {type: 'f', i18n: {sv: ``, en: `Recess on right side:`}},
        {type: 'i', copy: 'f'}
      ]
    }, {
      key: 'fillerTop',
      ucs: [
        {type: 'c', i18n: {sv: `Indrag övre:`, en: ``}},
        {type: 'f', i18n: {sv: ``, en: `Top Recess:`}},
        {type: 'i', copy: 'f'}
      ]
    }, {
      key: 'fillerBottom',
      ucs: [
        {type: 'c', i18n: {sv: `Indrag undre:`, en: ``}},
        {type: 'f', i18n: {sv: ``, en: `Bottom Recess:`}},
        {type: 'i', copy: 'f'}
      ]
    }]
  },
  FrameWidth: {
    name: 'FrameWidth',
    values: [{
      key: FRAME_WIDTH_OPTION_KEY_LEFT,
      ucs: [
        {type: 'c', i18n: {sv: `Ramens bredd, vänster sida: `, en: ``}},
        {type: 'f', i18n: {sv: ``, en: `Frame width left: `}},
        {type: 'i', copy: 'f'}
      ]
    }, {
      key: FRAME_WIDTH_OPTION_KEY_RIGHT,
      ucs: [
        {type: 'c', i18n: {sv: `Ramens bredd, höger sida: `, en: ``}},
        {type: 'f', i18n: {sv: ``, en: `Frame width right: `}},
        {type: 'i', copy: 'f'}
      ]
    }]
  },
  HandleDoor: {
    name: 'HandleDoor',
    values: [{
      key: 'Ja',
      ucs: [
        {type: 'c', i18n: {sv: `Vred`, en: ``}},
        {type: 'f', i18n: {sv: ``, en: `Turning knobs`}},
        {type: 'i', copy: 'f'}
      ]
    }, {
      key: 'Nej',
      ucs: [
        {type: 'c', i18n: {sv: `Inte vred`, en: ``}},
        {type: 'f', i18n: {sv: ``, en: `No turning knobs`}},
        {type: 'i', copy: 'f'}
      ]
    }]
  },
  HandleDrawer: {
    name: 'HandleDrawer',
    values: [{
      key: 'handtag_fornicklad_massing',
      ucs: [{
        type: 'c',
        i18n: {sv: `Handtag Förnicklad Mässing`, en: ``}
      }, {
        type: 'f',
        i18n: {sv: ``, en: `Handle Nickle Platted Brass`}
      }, {type: 'i', copy: 'f'}]
    }, {
      key: '1843_gold',
      ucs: [{type: 'c', i18n: {sv: `Knoppar i guld`, en: ``}}, {
        type: 'f',
        i18n: {sv: ``, en: `Gold knobs`}
      }, {type: 'i', copy: 'f'}]
    }, {
      key: '1843_silver',
      ucs: [{type: 'c', i18n: {sv: `Knoppar i silver`, en: ``}}, {
        type: 'f',
        i18n: {sv: ``, en: `Silver knobs`}
      }, {type: 'i', copy: 'f'}]
    }, {
      key: 'ldhandtag_silver',
      ucs: [{type: 'c', i18n: {sv: `Handtag i silver`, en: ``}}, {
        type: 'f',
        i18n: {sv: ``, en: `Silver handles`}
      }, {type: 'i', copy: 'f'}]
    }, {
      key: 'handtag_massing',
      ucs: [{type: 'c', i18n: {sv: `Handtag i mässing`, en: ``}}, {
        type: 'f',
        i18n: {sv: ``, en: `Brass handles`}
      }, {type: 'i', copy: 'f'}]
    }, {
      key: 'skalhandtag_massing',
      ucs: [{
        type: 'c',
        i18n: {sv: `Skalhandtag i mässing`, en: ``}
      }, {type: 'f', i18n: {sv: ``, en: `Skalhandtag i mässing`}}, {
        type: 'i',
        copy: 'f'
      }]
    }]
  },
  Hanging: {
    name: 'Hanging',
    values: [{
      key: 'v_nster',
      ucs: [
        {type: 'c', i18n: {sv: `En vänsterhängd lucka`, en: ``}},
        {type: 'f', i18n: {sv: ``, en: `Hinges: Left`}},
        {type: 'i', copy: 'f'}
      ]
    }, {
      key: 'h_ger',
      ucs: [
        {type: 'c', i18n: {sv: `En högerhängd lucka`, en: ``}},
        {type: 'f', i18n: {sv: ``, en: `Hinges: Right`}},
        {type: 'i', copy: 'f'}
      ]
    }, {
      key: 'v_nster-v_nster',
      ucs: [
        {type: 'c', i18n: {sv: `Två vänsterhängda luckor`, en: ``}},
        {type: 'f', i18n: {sv: ``, en: `Hinges: Left-Left`}},
        {type: 'i', copy: 'f'}
      ]
    }, {
      key: 'h_ger-h_ger',
      ucs: [
        {type: 'c', i18n: {sv: `Två högerhängda luckor`, en: ``}},
        {type: 'f', i18n: {sv: ``, en: `Hinges: Right-Right`}},
        {type: 'i', copy: 'f'}
      ]
    }, {
      key: 'v_nster-h_ger',
      ucs: [
        {
          type: 'c',
          i18n: {sv: `En vänsterhängd och en högerhängd lucka`, en: ``}
        },
        {type: 'f', i18n: {sv: ``, en: `Hinges: Left-Right`}},
        {type: 'i', copy: 'f'}
      ]
    }, {
      key: 'v_nster-v_nster-h_ger',
      ucs: [
        {
          type: 'c',
          i18n: {sv: `Två vänsterhängda och en högerhängd lucka`, en: ``}
        },
        {type: 'f', i18n: {sv: ``, en: `Hinges: Left-Left-Right`}},
        {type: 'i', copy: 'f'}
      ]
    }, {
      key: 'v_nster-h_ger-h_ger',
      ucs: [
        {
          type: 'c',
          i18n: {sv: `En vänsterhängd och två högerhängda luckor`, en: ``}
        },
        {type: 'f', i18n: {sv: ``, en: `Hinges: Left-Right-Right`}},
        {type: 'i', copy: 'f'}
      ]
    }, {
      key: 'upptill',
      ucs: [
        {type: 'c', i18n: {sv: `Luckan hängd i ovankant`, en: ``}},
        {type: 'f', i18n: {sv: ``, en: `Hinges: Top + lifting arm`}},
        {type: 'i', copy: 'f'}
      ]
    }, {
      key: 'nertill',
      ucs: [
        {type: 'c', i18n: {sv: `Luckan hängd i underkant`, en: ``}},
        {type: 'f', i18n: {sv: ``, en: `Hinges: Bottom`}},
        {type: 'i', copy: 'f'}
      ]
    }, {
      key: 'v_nster-h_ger_x2',
      ucs: [
        {
          type: 'c',
          i18n: {sv: `Två vänsterhängda och två högerhängda luckor`, en: ``}
        },
        {type: 'f', i18n: {sv: ``, en: `Hinges: Left-Right-x2`}},
        {type: 'i', copy: 'f'}
      ]
    }, {
      key: 'v_nster-v_nster-h_ger_x2',
      ucs: [
        {
          type: 'c',
          i18n: {sv: `Fyra vänsterhängda och två högerhängda luckor`, en: ``}
        },
        {type: 'f', i18n: {sv: ``, en: `Hinges: Left-Left-Right-x2`}},
        {type: 'i', copy: 'f'}
      ]
    }, {
      key: 'v_nster-h_ger-h_ger_x2',
      ucs: [
        {
          type: 'c',
          i18n: {sv: `Fyra högerhängda och två vänsterhängda luckor`, en: ``}
        },
        {type: 'f', i18n: {sv: ``, en: `Hinges: Left-Right-Right-x2`}},
        {type: 'i', copy: 'f'}
      ]
    }]
  },
  HiddenDrawer: {
    name: 'HiddenDrawer',
    values: [{
      key: DRAWER_HIGH,
      ucs: [
        {type: 'c', i18n: {sv: `Utdragslåda, hög: `, en: ``}},
        {type: 'f', i18n: {sv: ``, en: `Hidden drawer, tall: `}},
        {type: 'i', copy: 'f'}
      ]
    }, {
      key: DRAWER_LOW,
      ucs: [
        {type: 'c', i18n: {sv: `Utdragslåda, låg: `, en: ``}},
        {type: 'f', i18n: {sv: ``, en: `Hidden drawer, low: `}},
        {type: 'i', copy: 'f'}
      ]
    }, {
      key: SHELF,
      ucs: [
        {type: 'c', i18n: {sv: `Hyllplan: `, en: ``}},
        {type: 'f', i18n: {sv: ``, en: `Shelves: `}},
        {type: 'i', copy: 'f'}
      ]
    }]
  },
  HiddenDrawerSink: {
    name: 'HiddenDrawerSink',
    values: [{
      key: HIDDEN_DRAWER_SINK_ONE,
      ucs: [
        {
          type: 'c',
          i18n: {
            sv: `En extra utdragslåda, att montera ovanför lådan med metallkärlen. Denna låda levereras separat och monteras på plats (för att underlätta för VVS-montage)`,
            en: ``
          }
        },
        {
          type: 'f',
          i18n: {
            sv: ``,
            en: `Note! 1 hidden drawer - don't fix it in the cabinet, it should be separate`
          }
        },
        {type: 'i', copy: 'f'}
      ]
    }, {
      key: HIDDEN_DRAWER_SINK_TWO,
      ucs: [
        {
          type: 'c',
          i18n: {
            sv: `Två extra utdragslådor, att montera ovanför lådorna med metallkärlen. Dessa lådor levereras separat och monteras på plats (för att underlätta för VVS-montage)`,
            en: ``
          }
        },
        {
          type: 'f',
          i18n: {
            sv: ``,
            en: `Note! 2 hidden drawers - don't fix them in the cabinet, they should be separate`
          }
        },
        {type: 'i', copy: 'f'}
      ]
    }]
  },
  HiddenVisibleWidth: {
    name: 'HiddenVisibleWidth',
    values: [{
      key: 'not_in_use',
      ucs: [
        {type: 'c', i18n: {sv: `not_in_use`, en: ``}},
        {type: 'f', i18n: {sv: ``, en: `not_in_use`}},
        {type: 'i', copy: 'f'}
      ]
    }]
  },
  Hinges: {
    name: 'Hinges',
    values: [{
      key: '1. Klassiskt lyftgångjärn',
      ucs: [
        {type: 'c', i18n: {sv: `Klassiskt lyftgångjärn`, en: ``}},
        {type: 'f', i18n: {sv: ``, en: `Standard old fashioned hinges`}},
        {type: 'i', copy: 'f'}
      ]
    }, {
      key: '2. Moderna mjukstängande gångjärn',
      ucs: [
        {type: 'c', i18n: {sv: `Moderna mjukstängande gångjärn`, en: ``}},
        {type: 'f', i18n: {sv: ``, en: `Modern`}},
        {type: 'i', copy: 'f'}
      ]
    }, {
      key: '3. Modernt gångjärn med synlig tapp',
      ucs: [
        {type: 'c', i18n: {sv: `Modernt gångjärn med synlig tapp`, en: ``}},
        {type: 'f', i18n: {sv: ``, en: `Modern with visible pin`}},
        {type: 'i', copy: 'f'}
      ]
    }]
  },
  Legs: {
    name: 'Legs',
    values: [{
      key: 'Vänster',
      ucs: [
        {type: 'c', i18n: {sv: `Ben på vänster sida`, en: ``}},
        {type: 'f', i18n: {sv: ``, en: `Legs on left side`}},
        {type: 'i', copy: 'f'}
      ]
    }, {
      key: 'Höger',
      ucs: [
        {type: 'c', i18n: {sv: `Ben på höger sida`, en: ``}},
        {type: 'f', i18n: {sv: ``, en: `Legs on right side`}},
        {type: 'i', copy: 'f'}
      ]
    }, {
      key: 'Båda',
      ucs: [
        {type: 'c', i18n: {sv: `Ben på båda sidor`, en: ``}},
        {type: 'f', i18n: {sv: ``, en: `Legs on both sides`}},
        {type: 'i', copy: 'f'}
      ]
    }]
  },
  Lighting: {
    name: 'Lighting',
    values: [{
      key: 'Spotlights undertill',
      ucs: [
        {type: 'c', i18n: {sv: `Spotlights undertill`, en: ``}},
        {type: 'f', i18n: {sv: ``, en: `Spotlights underneath`}},
        {type: 'i', copy: 'f'}
      ]
    }, {
      key: 'LED-strips undertill',
      ucs: [
        {type: 'c', i18n: {sv: `LED-strips undertill`, en: ``}},
        {type: 'f', i18n: {sv: ``, en: `LED strips underneath`}},
        {type: 'i', copy: 'f'}
      ]
    }, {
      key: 'Spotlights undertill, och LED-strips inuti skåpet',
      ucs: [
        {
          type: 'c',
          i18n: {
            sv: `Spotlights undertill, och LED-strips inuti skåpet`,
            en: ``
          }
        },
        {
          type: 'f',
          i18n: {sv: ``, en: `Spotlights underneath and LED strips inside`}
        },
        {type: 'i', copy: 'f'}
      ]
    }, {
      key: 'LED-strips undertill och inuti skåpet',
      ucs: [
        {
          type: 'c',
          i18n: {sv: `LED-strips undertill och inuti skåpet`, en: ``}
        },
        {type: 'f', i18n: {sv: ``, en: `LED strips underneath and inside`}},
        {type: 'i', copy: 'f'}
      ]
    }, {
      key: 'LED-strips inuti skåpet',
      ucs: [
        {type: 'c', i18n: {sv: `LED-strips inuti skåpet`, en: ``}},
        {type: 'f', i18n: {sv: ``, en: `LED strips inside`}},
        {type: 'i', copy: 'f'}
      ]
    }]
  },
  PaintProcess: {
    name: 'PaintProcess',
    values: [{
      key: LINSEED_COMPLETE,
      ucs: [
        {
          type: 'c',
          i18n: {
            sv: `Linoljefärg, komplett (två strykningar i snickeriet, en strykning efter montering)`,
            en: ``
          }
        },
        {
          type: 'f',
          i18n: {
            sv: ``,
            en: `Standard linseed paint process (waxed insides as usual)`
          }
        },
        {type: 'i', copy: 'f'}
      ]
    }, {
      key: LINSEED_BASE,
      ucs: [
        {
          type: 'c',
          i18n: {
            sv: `Linoljefärg, grundmålning (kunden slutmålar själv)`,
            en: ``
          }
        },
        {
          type: 'f',
          i18n: {
            sv: ``,
            en: `Standard linseed paint process (waxed insides as usual)`
          }
        },
        {type: 'i', copy: 'f'}
      ]
    }, {
      key: ORDINARY_PAINT_COMPLETE,
      ucs: [
        {
          type: 'c',
          i18n: {
            sv: `Vanlig färg (ej linoljefärg). Grundmålning i snickeriet och slutstrykning efter montering.`,
            en: ``
          }
        },
        {
          type: 'f',
          i18n: {
            sv: ``,
            en: `Regular paint - NOT LINSEED PAINT (waxed insides as usual)`
          }
        },
        {type: 'i', copy: 'f'}
      ]
    }, {
      key: NO_PAINT_WAXED_INSIDE,
      ucs: [
        {
          type: 'c',
          i18n: {sv: `Omålat på utsidorna, vaxat på insidorna`, en: ``}
        },
        {type: 'f', i18n: {sv: ``, en: `Unpainted but waxed insides as usual`}},
        {type: 'i', copy: 'f'}
      ]
    }, {
      key: NO_PAINT,
      ucs: [
        {type: 'c', i18n: {sv: `Helt omålat/obehandlat`, en: ``}},
        {type: 'f', i18n: {sv: ``, en: `Untreated (no paint, oil, wax)`}},
        {type: 'i', copy: 'f'}
      ]
    }, {
      key: ORDINARY_PAINT_BASE,
      ucs: [
        {
          type: 'c',
          i18n: {
            sv: `Vanlig färg (ej linoljefärg). Grundmålning i snickeriet, kunden slutmålar själv.`,
            en: ``
          }
        },
        {
          type: 'f',
          i18n: {
            sv: ``,
            en: `Regular paint - NOT LINSEED PAINT (waxed insides as usual)`
          }
        },
        {type: 'i', copy: 'f'}
      ]
    }, {
      key: LINSEED_FACTORY_COMPLETE,
      ucs: [
        {type: 'c', i18n: {sv: `Linoljefärg, målas i snickeriet`, en: ``}},
        {
          type: 'f',
          i18n: {
            sv: ``,
            en: `Standard linseed paint process (waxed insides as usual) finalized in carpentry`
          }
        },
        {type: 'i', copy: 'f'}
      ]
    }, {
      key: ORDINARY_FACTORY_COMPLETE,
      ucs: [
        {
          type: 'c',
          i18n: {
            sv: `Vanlig färg (ej linoljefärg). Slutmålas i snickeriet.`,
            en: ``
          }
        },
        {
          type: 'f',
          i18n: {
            sv: ``,
            en: `Regular paint - NOT LINSEED PAINT (waxed insides as usual) finished in carpentry`
          }
        },
        {type: 'i', copy: 'f'}
      ]
    }]
  },
  PaintSide: {
    name: 'PaintSide',
    values: [{
      key: 'Vänster',
      ucs: [
        {type: 'c', i18n: {sv: `Målad sida vänster`, en: ``}},
        {type: 'f', i18n: {sv: ``, en: `Paint side because of DW: Left`}},
        {type: 'i', copy: 'f'}
      ]
    }, {
      key: 'Höger',
      ucs: [
        {type: 'c', i18n: {sv: `Målad sida höger`, en: ``}},
        {type: 'f', i18n: {sv: ``, en: `Paint side because of DW: Right`}},
        {type: 'i', copy: 'f'}
      ]
    }, {
      key: 'Båda',
      ucs: [
        {type: 'c', i18n: {sv: `Målad sida båda`, en: ``}},
        {
          type: 'f',
          i18n: {sv: ``, en: `Paint side because of DW: Left and right`}
        },
        {type: 'i', copy: 'f'}
      ]
    }, {
      key: 'Ingen',
      ucs: [
        {type: 'c', i18n: {sv: `Inget kladd på sidorna`, en: ``}},
        {type: 'f', i18n: {sv: ``, en: `Nones`}},
        {type: 'i', copy: 'f'}
      ]
    }]
  },
  Plinth: {
    name: 'Plinth',
    values: [{
      key: PLINTH_OPTION_KEY,
      ucs: [
        {type: 'c', i18n: {sv: `Sockelhöjd: `, en: ``}},
        {type: 'f', i18n: {sv: ``, en: `Socle/Plinth height: `}},
        {type: 'i', copy: 'f'}
      ]
    }]
  },
  Scribings: {
    name: 'Scribings',
    values: [{
      key: SCRIBING_OPTION_KEY_NONE,
      ucs: [
        {type: 'c', i18n: {sv: `Ingen överdimensionering`, en: ``}},
        {type: 'f', i18n: {sv: ``, en: `No scribing`}},
        {type: 'i', copy: 'f'}
      ]
    }, {
      key: 'Höger',
      ucs: [
        {
          type: 'c',
          i18n: {
            sv: `40 mm extra på höger sida (överdimensionerat, bör sågas av vid montering)`,
            en: ``
          }
        },
        {type: 'f', i18n: {sv: ``, en: `Scribing 40 mm right`}},
        {type: 'i', copy: 'f'}
      ]
    }, {
      key: 'Vänster',
      ucs: [
        {
          type: 'c',
          i18n: {
            sv: `40 mm extra på vänster sida (överdimensionerat, bör sågas av vid montering)`,
            en: ``
          }
        },
        {type: 'f', i18n: {sv: ``, en: `Scribing 40 mm left`}},
        {type: 'i', copy: 'f'}
      ]
    }, {
      key: 'Både höger och vänster',
      ucs: [
        {
          type: 'c',
          i18n: {
            sv: `40 mm extra på höger och vänster sida (överdimensionerat, bör sågas av vid montering)`,
            en: ``
          }
        },
        {type: 'f', i18n: {sv: ``, en: `Scribing 40 mm both left and right`}},
        {type: 'i', copy: 'f'}
      ]
    }]
  },
  Shelves: {
    name: 'Shelves',
    values: [{
      key: SHELVES_OPTION_KEY,
      ucs: [
        {type: 'c', i18n: {sv: `Antal hyllplan: `, en: ``}},
        {type: 'f', i18n: {sv: ``, en: `Quantity of shelves: `}},
        {type: 'i', copy: 'f'}
      ]
    }]
  },
  ShelvesAdjustable: {
    name: 'ShelvesAdjustable',
    values: [{
      key: 'Ja',
      ucs: [
        {
          type: 'c',
          i18n: {sv: `Ställbara hyllplan (flyttbara bärlister):`, en: ``}
        },
        {
          type: 'f',
          i18n: {sv: ``, en: `Adjustable shelves ("Rails and pegs")`}
        },
        {type: 'i', copy: 'f'}
      ]
    }]
  },
  Skirting: {
    name: 'Skirting',
    values: [{
      key: STANDARD_SKIRTING,
      ucs: [
        {
          type: 'c',
          i18n: {
            sv: `Standardsockel (inskjuten) - ca SOCKEL_HEIGHT mm hög (Skåpen står på en justerbar sockelbox, som är SOCKEL_BOX_HEIGHT mm hög, och denna har ställfötter som går att justera. Sockelns totalhöjd är därför variabel från SOCKEL_BOX_HEIGHT mm till ca SOCKEL_HEIGHT_ADJUST mm).`,
            en: ``
          }
        },
        {type: 'f', i18n: {sv: ``, en: `Standard plinth/socle`}},
        {type: 'i', copy: 'f'}
      ]
    }, {
      key: OUTSIDE_SKIRTING,
      ucs: [
        {
          type: 'c',
          i18n: {
            sv: `Utanpåliggande sockellist  - SOCKEL_HEIGHT mm hög. (Skåpen står på en justerbar sockelbox, som är SOCKEL_HEIGHT mm hög, och denna har ställfötter som går att justera. Sockelboxens totalhöjd är därför variabel från SOCKEL_BOX_HEIGHT mm till ca SOCKEL_HEIGHT_ADJUST mm).`,
            en: ``
          }
        },
        {
          type: 'f',
          i18n: {sv: ``, en: `Outside skirting board (plywood boxes behind it)`}
        },
        {type: 'i', copy: 'f'}
      ]
    }]
  },
  SpiceRack: {
    name: 'SpiceRack',
    values: [{
      key: 'Kryddhylla',
      ucs: [
        {
          type: 'c',
          i18n: {sv: `Hylla för kryddburkar på luckans insida`, en: ``}
        }, {
          type: 'f',
          i18n: {sv: ``, en: `Spice rack (on the inside of the door)`}
        },
        {type: 'i', copy: 'f'}
      ]
    }, {
      key: 'Burkhylla',
      ucs: [
        {
          type: 'c',
          i18n: {sv: `Hylla för flaskor på luckans insida`, en: ``}
        },
        {
          type: 'f',
          i18n: {sv: ``, en: `Jar rack (on the inside of the door)`}
        },
        {type: 'i', copy: 'f'}
      ]
    }, {
      key: 'Combo',
      ucs: [
        {
          type: 'c',
          i18n: {sv: `Hylla för flaskor/kryddburkar på luckans insida`, en: ``}
        },
        {
          type: 'f',
          i18n: {
            sv: ``,
            en: `Combined spice / jar rack (on the inside of the door)`
          }
        },
        {type: 'i', copy: 'f'}
      ]
    }, {
      key: DOOR_POS_ONLY,
      ucs: [
        {type: 'c', i18n: {sv: `Luckan`, en: ``}},
        {type: 'f', i18n: {sv: ``, en: `Door`}},
        {type: 'i', copy: 'f'}
      ]
    }, {
      key: DOOR_POS_LEFT,
      ucs: [
        {type: 'c', i18n: {sv: `Vänstra luckan`, en: ``}},
        {type: 'f', i18n: {sv: ``, en: `Left door`}},
        {type: 'i', copy: 'f'}
      ]
    }, {
      key: DOOR_POS_MID,
      ucs: [
        {type: 'c', i18n: {sv: `Mellersta luckan`, en: ``}},
        {type: 'f', i18n: {sv: ``, en: `Middle door`}},
        {type: 'i', copy: 'f'}
      ]
    }, {
      key: DOOR_POS_RIGHT,
      ucs: [
        {type: 'c', i18n: {sv: `Högra luckan`, en: ``}},
        {type: 'f', i18n: {sv: ``, en: `Right door`}},
        {type: 'i', copy: 'f'}
      ]
    }, {
      key: DOOR_POS_TOP,
      ucs: [
        {type: 'c', i18n: {sv: `Övre luckan`, en: ``}},
        {type: 'f', i18n: {sv: ``, en: `Top door`}},
        {type: 'i', copy: 'f'}
      ]
    }, {
      key: DOOR_POS_BOT,
      ucs: [
        {type: 'c', i18n: {sv: `Nedre luckan`, en: ``}},
        {type: 'f', i18n: {sv: ``, en: `Bottom door`}},
        {type: 'i', copy: 'f'}
      ]
    }, {
      key: DOOR_POS_TOP_LEFT,
      ucs: [
        {type: 'c', i18n: {sv: `Övre vänstra luckan`, en: ``}},
        {type: 'f', i18n: {sv: ``, en: `Top left door`}},
        {type: 'i', copy: 'f'}
      ]
    }, {
      key: DOOR_POS_TOP_MID,
      ucs: [
        {type: 'c', i18n: {sv: `Övre mellersta luckan`, en: ``}},
        {type: 'f', i18n: {sv: ``, en: `Top middle door`}},
        {type: 'i', copy: 'f'}
      ]
    }, {
      key: DOOR_POS_TOP_RIGHT,
      ucs: [
        {type: 'c', i18n: {sv: `Övre högra luckan`, en: ``}},
        {type: 'f', i18n: {sv: ``, en: `Top right door`}},
        {type: 'i', copy: 'f'}
      ]
    }, {
      key: DOOR_POS_BOT_LEFT,
      ucs: [
        {type: 'c', i18n: {sv: `Nedre vänstra luckan`, en: ``}},
        {type: 'f', i18n: {sv: ``, en: `Bottom left door`}},
        {type: 'i', copy: 'f'}
      ]
    }, {
      key: DOOR_POS_BOT_MID,
      ucs: [
        {type: 'c', i18n: {sv: `Nedre mellersta luckan`, en: ``}},
        {type: 'f', i18n: {sv: ``, en: `Bottom middle door`}},
        {type: 'i', copy: 'f'}
      ]
    }, {
      key: DOOR_POS_BOT_RIGHT,
      ucs: [
        {type: 'c', i18n: {sv: `Nedre högra luckan`, en: ``}},
        {type: 'f', i18n: {sv: ``, en: `Bottom right door`}},
        {type: 'i', copy: 'f'}]
    }]
  }
}

const defaultConfigs: { [key in TConfigItemName]: Partial<SettingsConfig> } = {
  /**
   * This is the config item for cad peoples
   */
  CAD: {
    name: 'CAD',
    values: [
      {id: 'Alex', dv: 'Alex'},
      {id: 'Sema', dv: 'Sema'}
    ] as ISettingsConfigValue[],
    t: 'c'
  },
  /**
   * This is the config item for painter peoples
   */
  PAINTER: {
    name: 'PAINTER',
    values: [
      {id: 'Linoljemålarna', dv: 'Linoljemålarna'},
      {id: 'APatrik', dv: 'Patrik'},
      {id: 'Linda', dv: 'Linda'},
      {id: 'Pontus', dv: 'Pontus'},
      {id: 'Kritan', dv: 'Kritan'}
    ] as ISettingsConfigValue[],
    t: 'c'
  },
  /**
   * This is the config item for colors
   */
  COLOR: {
    name: 'COLOR',
    values: [
      {id: 'Nr 1', dv: 'Nr 1'},
      {id: 'Nr 12', dv: 'Nr 12'},
      {id: 'Nr 19', dv: 'Nr 19'},
      {id: 'Nr 25', dv: 'Nr 25'},
      {id: 'Nr 31', dv: 'Nr 31'},
      {id: 'Nr 34', dv: 'Nr 34'},
      {id: 'Nr 38', dv: 'Nr 38'},
      {id: 'Nr 48', dv: 'Nr 48'},
      {id: 'Nr 58', dv: 'Nr 58'},
      {id: 'Nr 67', dv: 'Nr 67'},
      {id: 'Nr 69', dv: 'Nr 69'},
      {id: 'Nr 86', dv: 'Nr 86'},
      {id: 'Nr 87', dv: 'Nr 87'},
      {id: 'Nr 97', dv: 'Nr 97'},
      {id: 'Nr 100', dv: 'Nr 100'},
      {id: 'Nr 101', dv: 'Nr 101'},
      {id: 'Nr 107', dv: 'Nr 107'},
      {id: 'Nr 111', dv: 'Nr 111'},
      {id: 'Nr 116', dv: 'Nr 116'},
      {id: 'Nr 148', dv: 'Nr 148'},
      {id: 'Nr 151', dv: 'Nr 151'},
      {id: 'Nr XX', dv: 'Nr XX'},
      {
        id: 'Vit Titan-Zink (NCS 0402-Y07R)',
        dv: 'Vit Titan-Zink (NCS 0402-Y07R)'
      }
    ] as ISettingsConfigValue[],
    t: 'c'
  },
  /**
   * This is the config item carpenters
   */
  CARPENTRY: {
    name: 'CARPENTRY',
    values: [
      {id: 'Raimondas!', dv: 'Raimondas'},
      {id: 'Justas', dv: 'Justas'},
      {id: 'Odas', dv: 'Odas'},
      {id: 'Retasta', dv: 'Retasta'},
      {id: 'Agacco', dv: 'Agacco'}
    ] as ISettingsConfigValue[],
    t: 'c'
  },
  /**
   * This is the config item for suppliers, just an initial value until one is saved in production
   */
  SUPPLIER: {
    name: 'SUPPLIER',
    values: [
      {name: 'Decosteel', email: 'johan.hallgren@decosteel.se', type: 'other'},
      {name: 'Smeg', email: 'order@smeg.se', type: 'other'},
      {name: 'Witt', email: 'twe@wittsverige.se', type: 'other'},
      {name: 'Liebherr', email: 'twe@wittsverige.se', type: 'other'},
      {name: 'Siemens', email: 'orderkok@bshg.com', type: 'other'},
      {name: 'Steny', email: 'info@steny.se', type: 'other'},
      {name: 'Nerostein', email: 'info@nerostein.se', type: 'other'},
      {name: 'Fjäråskupan', email: 'par.mansson@fjaraskupan.se', type: 'other'},
      {name: 'Sekelskifte', email: 'order@sekelskifte.se', type: 'other'},
      {
        name: 'Byggfabriken',
        email: 'webborder@byggfabriken.com',
        type: 'other'
      },
      {name: 'BeslagDesign', email: 'order@beslagdesign.se', type: 'other'},
      {name: 'Badex', email: 'info@badex.se', type: 'other'},
      {name: 'Ilve', email: 'susanne.bogren@italianbrands.se', type: 'other'},
      {name: 'Volito', email: 'sales@nivito.se', type: 'other'},
      {name: 'Asko', email: 'kundservice@gorenjegroup.com', type: 'other'},
      {name: 'Brandt', email: 'twe@wittsverige.se', type: 'other'},
      {name: 'De Dietrich', email: 'twe@wittsverige.se', type: 'other'},
      {name: 'Shaws of Darwen', email: 'info@badex.se', type: 'other'},
      {name: 'Kulladal Kök AB', email: 'info@kulladal.com', type: 'other'},
      {name: 'Cylinda', email: 'daniel.olsson@kokscentrum.com', type: 'other'},
      {name: 'Tapwell', email: 'ck@tapwell.se', type: 'other'},
      {name: 'Thermex', email: 'daniel.olsson@kokscentrum.com', type: 'other'},
      {name: 'Bosch', email: 'daniel.olsson@kokscentrum.com', type: 'other'},
      {
        name: 'Whirlpool',
        email: 'daniel.olsson@kokscentrum.com',
        type: 'other'
      },
      {
        name: 'Justas',
        email: 'https://linaf.slack.com/archives/C03NJUWCSRJ',
        type: 'carpentry'
      },
      {
        name: 'Raimondas',
        email: 'https://linaf.slack.com/archives/C03NJUWCSRJ',
        type: 'carpentry'
      },
      {
        name: 'Odas',
        email: 'https://linaf.slack.com/archives/C03NJUWCSRJ',
        type: 'carpentry'
      },
      {
        name: 'Retasta',
        email: 'https://linaf.slack.com/archives/C03NJUWCSRJ',
        type: 'carpentry'
      },
      {
        name: 'Agacco',
        email: 'https://linaf.slack.com/archives/C03NJUWCSRJ',
        type: 'carpentry'
      }
    ] as ISettingsConfigSupplier[],
    t: 'c'
  }
}

/**
 * Get all default Setting Items, merging all options
 * (Settings > Cabinet Options) and configuration (Settings > Configuration).
 */
export const getDefaultSettingItems = (): SettingsBaseItem[] => {
  const options = Object.keys(defaultOptions)
    .filter(name => name !== 'UnknownOption')
    .map(itemName =>
      new SettingsOption({...defaultOptions[itemName]}))
  const configs = Object.keys(defaultConfigs).map(itemName =>
    new SettingsConfig({...defaultConfigs[itemName]}))

  return [...options, ...configs]
}
