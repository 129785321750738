import {
  IFDCombined,
  IFDFillers,
  IFDFrame
} from '../../model/factory/factory-data'
import {ProdboardCabinet} from '../../model/cabinet/prodboard-cabinet'
import {
  ProjectOption,
  SummaryProperties
} from '../../model/project-option/project-option'
import {IComment} from '../../comments/model/comment'
import {IProjectImage} from '../../images/model/project-image'
import {LightsHelper} from '../../model/cabinet/lights-helper'
import {FrameWidth} from '../../model/frame-width/frame-width'
import {Shelves} from '../../model/shelves'
import {HandleDoor} from '../../model/handle-door'
import {Skirting} from '../../model/skirting'
import {CombinedUnit} from '../../model/combined-unit'
import {TOptionSelectName} from '../../model/cabinet-option'

export class FactoryCabinet {

  public cat: string

  public description: string

  public index: number

  public uid: string

  public option: ProjectOption = {} as any

  public hasFrame = false

  public productComment: string | undefined

  public counterTopUid = ''

  public images: IProjectImage[] = []

  public lights: LightsHelper

  public warnings: string[] = []

  /**
   * Set by getFactoryDate on Door
   */
  public paintedInside: boolean = false

  /**
   *
   */
  public combined: IFDCombined = {
    top: false,
    left: false,
    right: false,
    bottom: false
  }

  private pHeight: number

  private pWidth: number

  /**
   * Hidden width is only available on corner cabinets.
   */
  private pHiddenWidth = 0

  private pVisibleWidth = 0

  private pDepth: number

  /**
   * Please see value P, this is added when
   * the cabinet is B or T type and socel is "utanpåliggande"
   */
  private facadeFrameAddition = 0

  /**
   * Fillers and pFrame are set from data we get from
   * the "Fillers" option and the 'FrameWidth' option
   */
  private fillers: IFDFillers = {bottom: 0, left: 0, right: 0, top: 0}

  private frame: IFDFrame = {bottom: 0, left: 0, right: 0, top: 0}

  private pScribings = ''

  private comments: IComment[] = []


  constructor(public cabinet: ProdboardCabinet) {
    this.setValuesFromCabinet(cabinet)
    this.option = new ProjectOption([cabinet], 'f', 'en')
    this.productComment = cabinet.productComments.en
  }

  /**
   * A is equal to width
   */
  get a(): number {
    return this.width
  }

  get width(): number {
    return this.pWidth
  }

  /**
   * Frame width left
   */
  get b(): number {
    return this.frame.left
  }

  /**
   * Filler left side
   */
  get c(): number {
    return this.fillers.left
  }

  /**
   * Right side frame
   */
  get d(): number {
    return this.frame.right
  }

  /**
   * Filler right side
   */
  get e(): number {
    return this.fillers.right
  }

  /**
   * Hole width, calculated
   */
  get f(): number {
    return this.pVisibleWidth - this.frame.left - this.frame.right
  }

  /**
   * The cabinet full height, aka carcass height.
   * The (adjusted for skriting) height - recess, recess a.k.a. fillers.
   */
  get g(): number {
    return this.p - this.fillers.top - this.fillers.bottom
  }

  get height(): number {
    return this.pHeight
  }

  /**
   * Frame top
   */
  get h(): number {
    return this.frame.top
  }

  /**
   * Frame bottom
   */
  get i(): number {
    return this.frame.bottom
  }

  /**
   * Door/drawer hole height
   */
  get j(): number {
    // Dishwashers should always be presented as 870 mm
    if (this.cabinet.isDishwasherCabinet) {
      return 870
    }
    return this.p - this.frame.top - this.frame.bottom
  }

  /**
   * Cabinet depth
   */
  get k() {
    return this.pDepth
  }

  /**
   * L and M are top and bottom recess values.
   */
  get l(): number {
    return this.fillers.top || 0
  }

  /**
   * Fillers bottom.
   */
  get m(): number {
    return this.fillers.bottom || 0
  }

  /**
   * N - Visible width, either the real width or the visible width.
   */
  get n(): number {
    return this.pVisibleWidth
  }

  /**
   * O (oh) - Hidden width, only applicable for corner cabinets. If > 0 it must be
   * a corner cabinet.
   */
  get o(): number {
    return this.pHiddenWidth
  }

  /**
   * Facade height, new value
   * [Facade frame height] = The height that comes from JSON
   * exception: If it is an "utanpåliggande sockel" and sockel height is
   * more than 0mm, then, add 20 mm
   *
   * This is same as J but with the socel type adjustmnet.
   */
  get p(): number {
    return this.height + this.facadeFrameAddition
  }

  get importantNotes(): string[] {
    return this.comments.map((comment: IComment) => comment.translation)
      .concat(this.cabinet.checkIfPaintedInsideNote())
  }

  /**
   * Here come the option getters, they have to come here
   * b/c of es-lint.
   */
  public getDoors(): SummaryProperties[] {
    return this.option.getDoors()
  }

  public getHinges(): SummaryProperties[] {
    return this.option.getHinges()
  }

  public getCoverSides(): SummaryProperties[] {
    return this.option.getCoverSides()
  }

  public getSkirting(): SummaryProperties[] {
    return this.option.getSkirting()
  }

  public getPaints(): SummaryProperties[] {
    return this.option.getPaints()
  }

  public getShelves(): SummaryProperties[] {
    return this.option.getShelves()
  }

  public getCenterPost(): SummaryProperties[] {
    return this.option.getCenterPost()
  }

  public scribings(): string {
    return this.pScribings
  }

  private setValuesFromCabinet(cabinet: ProdboardCabinet): void {
    this.index = cabinet.index
    this.uid = cabinet.uid
    this.cat = cabinet.cat
    this.description = cabinet.description
    this.pWidth = cabinet.dimensions.x
    this.pHeight = cabinet.actualHeight
    this.pDepth = cabinet.dimensions.z

    this.pVisibleWidth = cabinet.visibleWidth || this.pWidth
    this.pHiddenWidth = cabinet.hiddenWidth

    cabinet.getActiveOptions<CombinedUnit>('CombinedUnit')
      .forEach(o => this.combined = o.getFactoryData().combined)

    cabinet.options.forEach((option) => {
      const data = option.getFactoryData()
      // Will assign frames and fillers. Really, really dangerous,
      // we need to rework these later,
      const bannedOptions: TOptionSelectName[] = ['CombinedUnit', 'Skirting']
      if (bannedOptions.indexOf(option.optionSelectName) === -1) {
        Object.assign(this, data)
      }
    })

    // If it has "outsideSkirting", and Product has more than 0mm of
    // sockel height. Then the facade frame height should have 20mm more.
    const skirting = cabinet.getActiveOption<Skirting>('Skirting')
    if (skirting?.getFactoryData().outsideSkirting && cabinet.sockelHeight > 0) {
      this.facadeFrameAddition = 20
    }

    this.hasFrame = Object.values(this.fillers).concat(Object.values(this.frame)).some(v => v > 0)

    this.pScribings = this.cabinet.getScribings()

    this.counterTopUid = this.cabinet.counterTopId

    this.setImportantNotes()

    this.generateWarnings(cabinet)
  }

  private setImportantNotes(): void {
    this.comments = this.cabinet.comments.filter((comment) => comment.translation)
    this.comments.unshift({translation: this.productComment} as any)
    this.comments = this.comments.filter((c: IComment) => c.translation)
  }

  private generateWarnings(cabinet: ProdboardCabinet): void {
    const frameWidth = cabinet.getOption<FrameWidth>('FrameWidth')[0]
    const shelves = cabinet.getOption<Shelves>('Shelves')[0]

    if (frameWidth && shelves && cabinet.numberOfDoors > 1 && shelves.numberOfShelves()) {
      const openingWidth = cabinet.visibleWidth - frameWidth.right - frameWidth.left

      if ((openingWidth > 949 && cabinet.numberOfDoors === 2)
        ||
        (openingWidth > 1399 && cabinet.numberOfDoors === 3)) {
        // 'Factory comment'
        this.warnings.push('N.B. Must be a mid-wall')
      }
    }

    if (cabinet.numberOfDoors % 3 === 0) {
      cabinet.getOption<HandleDoor>('HandleDoor')
        .filter(o => o.isVred())
        .forEach(() => this.warnings.push('N.B. Mid-posts must be 50 mm wide!'))
    }
  }
}
